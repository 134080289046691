import { StageTab } from "../../pages/app/practice-area/pa"

export let setActive = (states : Array<StageTab> , stageNo: number) => {
    states = states.map((sta) => {
        if (sta.stageNo === stageNo) {
            sta.showTitle = true
        } else {
            sta.showTitle = false
        }

        if (sta.stageNo <= stageNo) {
            sta.active = true
        } else {
            sta.active = false
        }
        return sta
    })

    return states;
}