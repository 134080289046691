import * as React from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { SignUpAtom } from "./singup_atom";

const SignUpSuccess: React.FC = () => {
  let history = useHistory();

  let signupInfo = useRecoilValue(SignUpAtom);

  let goBackToHome = () => {
    history.push("/");
  };

  return (
    <div
      style={{
        marginTop: "165px",
      }}
      className={"p-6 w-full flex flex-col h-screen"}
    >
      <div
        style={{
          marginBottom: "50px",
        }}
        className="mx-6 px-6 text-3xl font-semibold"
      >
        Thank You For Signing Up!
      </div>
      <div
        style={{
          fontSize: "20px",
          marginBottom: "146px",
        }}
        className="mx-6 px-6 font-thin"
      >
        <div className="mb-6">
          Thank you {signupInfo.firstName} {signupInfo.lastName} for signing up
          to DataNeuron ALP.
        </div>{" "}
        <div>
          You will receive an email shortly at {signupInfo.email} stating
          whether you have been given access to the platform and you will
          receive the login credentials along with it.
        </div>
      </div>
      <div className="w-full flex justify-center items-center">
        <img src="/img/tick.png" width={200} alt="success icon" />
      </div>
    </div>
  );
};

export default SignUpSuccess;
