import React, { ReactNode, useEffect, useState } from "react";
import { NerEntity } from "../../store/zustand/interface";
import useNerStore from "../../store/zustand/useNerStore";

interface NerParaProps {
  paraData: string;
  expanded: boolean;
  paraId: string;
}

let NerPara: React.SFC<NerParaProps> = ({ paraData, paraId, expanded }) => {
  //
  let [letters, setLetters] = useState<Array<ReactNode>>([]);
  let nerStore = useNerStore();

  useEffect(() => {
    let text = expanded ? paraData : paraData.slice(0, 250);
    let words = nerStore.words.filter((wrd) => wrd.paraId === paraId);

    let foundEntity: NerEntity | null | undefined = null;

    let tempLetters = text.split("").map((letter, i) => {
      //
      //i know this is a bad logic .. but let's use this until we find something better
      if (foundEntity === null || foundEntity === undefined) {
        foundEntity = words.find((wrd) => wrd.startIndex === i)?.entity;
      } else {
        if (words.find((wrd) => wrd.endIndex === i)?.entity) {
          foundEntity = undefined;
        }
      }
      let comp = (
        <span
          style={{
            color: foundEntity ? foundEntity.color : "black",
            textDecoration: foundEntity ? "underline" : "none",
          }}
        >
          {letter}
        </span>
      );
      return comp;
    });

    setLetters(tempLetters);
  }, [expanded, nerStore.words]);

  return <div>{letters}</div>;
};

export default NerPara;
