import React from "react";
import { ResponsiveSunburst } from "@nivo/sunburst";
import ReactApexChart from "react-apexcharts";

interface UsageProps {}
const data = {
  name: "nivo",
  color: "hsl(57, 70%, 50%)",
  children: [
    {
      name: "viz",
      color: "hsl(294, 70%, 50%)",
      children: [
        {
          name: "stack",
          color: "hsl(241, 70%, 50%)",
          children: [
            {
              name: "cchart",
              color: "hsl(188, 70%, 50%)",
              loc: 61592,
            },
            {
              name: "xAxis",
              color: "hsl(215, 70%, 50%)",
              loc: 137929,
            },
            {
              name: "yAxis",
              color: "hsl(262, 70%, 50%)",
              loc: 120083,
            },
            {
              name: "layers",
              color: "hsl(284, 70%, 50%)",
              loc: 197403,
            },
          ],
        },
        {
          name: "ppie",
          color: "hsl(182, 70%, 50%)",
          children: [
            {
              name: "chart",
              color: "hsl(311, 70%, 50%)",
              children: [
                {
                  name: "pie",
                  color: "hsl(80, 70%, 50%)",
                  children: [
                    {
                      name: "outline",
                      color: "hsl(264, 70%, 50%)",
                      loc: 81213,
                    },
                    {
                      name: "slices",
                      color: "hsl(0, 70%, 50%)",
                      loc: 186108,
                    },
                    {
                      name: "bbox",
                      color: "hsl(335, 70%, 50%)",
                      loc: 172377,
                    },
                  ],
                },
                {
                  name: "donut",
                  color: "hsl(66, 70%, 50%)",
                  loc: 50826,
                },
                {
                  name: "gauge",
                  color: "hsl(140, 70%, 50%)",
                  loc: 77497,
                },
              ],
            },
            {
              name: "legends",
              color: "hsl(347, 70%, 50%)",
              loc: 62688,
            },
          ],
        },
      ],
    },
    {
      name: "colors",
      color: "hsl(241, 70%, 50%)",
      children: [
        {
          name: "rgb",
          color: "hsl(175, 70%, 50%)",
          loc: 135916,
        },
        {
          name: "hsl",
          color: "hsl(221, 70%, 50%)",
          loc: 90136,
        },
      ],
    },
    {
      name: "utils",
      color: "hsl(30, 70%, 50%)",
      children: [
        {
          name: "randomize",
          color: "hsl(349, 70%, 50%)",
          loc: 9192,
        },
        {
          name: "resetClock",
          color: "hsl(341, 70%, 50%)",
          loc: 20094,
        },
        {
          name: "noop",
          color: "hsl(158, 70%, 50%)",
          loc: 69685,
        },
      ],
    },
    {
      name: "set",
      color: "hsl(265, 70%, 50%)",
      children: [
        {
          name: "clone",
          color: "hsl(260, 70%, 50%)",
          loc: 121544,
        },
        {
          name: "intersect",
          color: "hsl(7, 70%, 50%)",
          loc: 150825,
        },
        {
          name: "merge",
          color: "hsl(289, 70%, 50%)",
          loc: 28395,
        },
      ],
    },
    {
      name: "text",
      color: "hsl(28, 70%, 50%)",
      children: [
        {
          name: "trim",
          color: "hsl(198, 70%, 50%)",
          loc: 109667,
        },
        {
          name: "slugify",
          color: "hsl(166, 70%, 50%)",
          loc: 38993,
        },
      ],
    },
    {
      name: "misc",
      color: "hsl(83, 70%, 50%)",
      children: [
        {
          name: "greetings",
          color: "hsl(339, 70%, 50%)",
          children: [
            {
              name: "hey",
              color: "hsl(13, 70%, 50%)",
              loc: 181091,
            },
            {
              name: "HOWDY",
              color: "hsl(1, 70%, 50%)",
              loc: 38170,
            },
            {
              name: "aloha",
              color: "hsl(170, 70%, 50%)",
              loc: 4275,
            },
          ],
        },
        {
          name: "other",
          color: "hsl(233, 70%, 50%)",
          loc: 98839,
        },
        {
          name: "path",
          color: "hsl(169, 70%, 50%)",
          children: [
            {
              name: "pathA",
              color: "hsl(281, 70%, 50%)",
              loc: 31814,
            },
            {
              name: "pathB",
              color: "hsl(58, 70%, 50%)",
              children: [
                {
                  name: "pathB1",
                  color: "hsl(218, 70%, 50%)",
                  loc: 132941,
                },
                {
                  name: "pathB2",
                  color: "hsl(337, 70%, 50%)",
                  loc: 122503,
                },
                {
                  name: "pathB3",
                  color: "hsl(84, 70%, 50%)",
                  loc: 151799,
                },
                {
                  name: "pathB4",
                  color: "hsl(256, 70%, 50%)",
                  loc: 1765,
                },
              ],
            },
            {
              name: "pathC",
              color: "hsl(18, 70%, 50%)",
              children: [
                {
                  name: "pathC1",
                  color: "hsl(100, 70%, 50%)",
                  loc: 154402,
                },
                {
                  name: "pathC2",
                  color: "hsl(111, 70%, 50%)",
                  loc: 7532,
                },
                {
                  name: "pathC3",
                  color: "hsl(317, 70%, 50%)",
                  loc: 172141,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
const Usage: React.FC<UsageProps> = () => {
  let usageInMb = [998, 2000];
  const options1 = {
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          labels: {
            show: true,
            name: {
              show: false,
              fontSize: "20px",
              color: "#11256D",
              backrgoundColor: "#fff",
              formatter: function () {
                return ``;
              },
            },
            value: {
              show: true,
              fontSize: "16px",
              color: "#11256D",
              formatter: function () {
                return `${usageInMb[0]}MB`;
              },
            },
            total: {
              showAlways: true,
              show: true,
              fontSize: "16px",
              color: "#11256D",
              formatter: function () {
                return `${usageInMb[0]}MB`;
              },
            },
          },
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    toolbar: {
      enabled: false,
    },
    stroke: {
      colors: ["#0000000"],
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
      active: {
        filter: {
          type: "none" /* none, lighten, darken */,
          value: 0,
        },
      },
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: false,
            position: "bottom",
          },
        },
      },
    ],
    colors: ["#F59F00", "#E5E5E5"],
  };

  return (
    <div className="flex justify-between">
      <div
        className="w-4/6 bg-white p-5"
        style={{
          height: "541px",
          boxShadow: "0px 0px 8px rgba(122, 122, 122, 0.15)",
        }}
      >
        <p
          style={{
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "18px",
            color: "#11256D",
          }}
        >
          Masterlist Annotations
        </p>
        <div className="m-5" style={{ width: "450px", height: "500px" }}>
          <ResponsiveSunburst
            data={data}
            id="name"
            value="loc"
            cornerRadius={2}
            borderWidth={1}
            borderColor="white"
            colors={{ scheme: "nivo" }}
            childColor={{ from: "color" }}
            animate={false}
            motionConfig="gentle"
            isInteractive={true}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-3">
        <div
          className="bg-white p-5"
          style={{
            width: "400px",
            height: "270px",
            boxShadow: "0px 0px 8px rgba(122, 122, 122, 0.15)",
          }}
        >
          <p style={{ fontSize: "15px", fontWeight: 500, color: "#11256D" }}>
            Data Usage
          </p>
          <div>
            <ReactApexChart
              type="donut"
              height={210}
              series={usageInMb}
              options={options1}
            />
          </div>
          <div className="w-full flex justify-end m-0">
            <div
              className="px-5 py-1 text-white rounded-md cursor-pointer"
              style={{ background: "#11256D" }}
            >
              View All
            </div>
          </div>
        </div>
        <div
          className="bg-white p-5"
          style={{
            width: "400px",
            height: "270px",
            boxShadow: "0px 0px 8px rgba(122, 122, 122, 0.15)",
          }}
        >
          <p style={{ fontSize: "15px", fontWeight: 500, color: "#11256D" }}>
            API Calls
          </p>
          <div>
            <ReactApexChart
              type="pie"
              height={210}
              series={usageInMb}
              options={options1}
            />
          </div>
          <div className="w-full flex justify-end m-0">
            <div
              className="px-5 py-1 text-white rounded-md cursor-pointer"
              style={{ background: "#11256D" }}
            >
              View All
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Usage;
