import * as React from "react";
import Masterlist from "../../../../components/masterlist";
import usePracticeArea from "../../../../store/zustand/usePracticeArea";

const MasterlistPage: React.FC = () => {
  let { currentPracticeArea } = usePracticeArea();

  return (
    <div>
      {/* @ts-ignore */}
      <Masterlist practiceAreaId={currentPracticeArea.practiceAreaId || ""} />
    </div>
  );
};

export default MasterlistPage;
