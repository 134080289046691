import * as React from "react";
import Navbar from "../sidebar";
import TopBar from "../topbar";

let MainLayout: React.FC = ({ children }) => {
  return (
    <div className={"pt-12 pl-20 pr-6"}>
      <TopBar />
      <Navbar />
      <div
        style={{
          backgroundColor: "#fafafa",
          boxShadow: "0px 0px 4px rgba(122, 122, 122, 0.15)",
          minHeight: "90vh",
        }}
        className="bg-green rounded-lg overflow-hidden mt-10"
      >
        {children}
      </div>
    </div>
  );
};

export default MainLayout;
