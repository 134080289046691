import moment from "moment";
export const formatDate = (dateField: any) => {
  const monthMap = {
    1: "JAN",
    2: "FEB",
    3: "MARCH",
    4: "APRIL",
    5: "MAY",
    6: "JUNE",
    7: "JULY",
    8: "AUG",
    9: "SEPT",
    10: "OCT",
    11: "NOV",
    12: "DEC",
  };
  // if (!dateField) dateField = Date.now()

  const date = new Date(dateField);
  const year = date.getFullYear();
  const month = 1 + date.getMonth();
  const day = date.getDate().toString().padStart(2, "0");
  const time = moment.utc(dateField).format("HH:mm A");
  return {
    //@ts-ignore
    date: day + " " + monthMap[month] + " " + year,
    time: time,
  };
};

export let getWithoutNan = (val: any) => {
  if (isNaN(val)) {
    return 0;
  } else {
    return val;
  }
};

export let createdNestedObject = (key: string, val: any) => {
  return key
    .split(".")
    .reduceRight((obj: any, key: any) => ({ [key]: obj }), val);
};

export function extend(o1: any, o2: any) {
  var result = {},
    obj;

  for (var i = 0; i < arguments.length; i++) {
    obj = arguments[i];
    for (var key in obj) {
      if (Object.prototype.toString.call(obj[key]) === "[object Object]") {
        //@ts-ignore
        if (typeof result[key] === "undefined") {
          //@ts-ignore
          result[key] = {};
        }
        //@ts-ignore
        result[key] = extend(result[key], obj[key]);
      } else {
        //@ts-ignore
        result[key] = obj[key];
      }
    }
  }
  return result;
}

export function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function getRandomeColor(): string {
  //return bright color hls
  const hue = randomIntFromInterval(0, 255);
  const saturation = 100;
  const lightness = 50;
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}

export function indexes(source: string, find: string) {
  if (!source) {
    return [];
  }
  if (!find) {
    return source.split("").map(function (_, i) {
      return i;
    });
  }
  var result = [];
  var i = 0;
  while (i < source.length) {
    if (source.substring(i, i + find.length) == find) {
      result.push([i, i + find.length]);
      i += find.length;
    } else {
      i++;
    }
  }
  return result;
}
