import React, { useState, useEffect } from "react";
import ApexChart from "./ApexChart";
import styles from "./modelTraining1.module.css";
import { useContext } from "react";
import { StageContext } from "..";
import { setActive } from "../../../../../components/workspace-nav/stage-helper";
import { useHistory } from "react-router-dom";
import usePracticeArea from "../../../../../store/zustand/usePracticeArea";
import { sendPracticeAreaInfo } from "../../../../../realtime";
import useCurrentUser from "../../../../../store/zustand/useCurrentUser";
import ReactGA from "react-ga";

export interface ModelTraining1Props {}

interface detailsState {
  trainingProgress: number;
  dataPoint: string;
  totalUpload: number;
  estimatedTime: string;
  stage1: number;
  stage2: number;
  stage3: number;
}

const ModelTraining1: React.SFC<ModelTraining1Props> = () => {
  let pracStore = usePracticeArea();

  let initial =
    pracStore.currentPracticeArea?.alpdsInfo?.percentageStatus?.automlProgress;

  const [details, setDetails] = useState({
    trainingProgress: 0,
    dataPoint: "bussiness",
    totalUpload: 0,
    estimatedTime: "12 minutes 30 seconds",
    stage1: initial?.dataLoadingProgress || 0,
    stage2: initial?.automlClassifier1Progress || 0,
    stage3: initial?.automlClassifier2Progress || 0,
  });

  useEffect(() => {
    //setting the state

    let data =
      pracStore.currentPracticeArea?.alpdsInfo?.percentageStatus
        ?.automlProgress;

    if (data) {
      let s1 = data?.dataLoadingProgress || 0;
      let s2 = data.automlClassifier1Progress || 0;
      let s3 = data.automlClassifier2Progress || 0;

      setDetails({
        ...details,
        trainingProgress: Math.round((s1 + s2 + s3) / 3),
        stage1: s1,
        stage2: s2,
        stage3: s3,
      });
    }
  }, [
    pracStore.currentPracticeArea?.alpdsInfo?.automlProgress?.automlClassifier1
      ?.dataLoadingProgress,
    pracStore.currentPracticeArea?.alpdsInfo?.percentageStatus?.automlProgress
      ?.automlClassifier1Progress,
    pracStore.currentPracticeArea?.alpdsInfo?.percentageStatus?.automlProgress
      ?.automlClassifier2Progress,
  ]);

  let { stages, setStages } = useContext(StageContext);
  let history = useHistory();

  let [counter, setCounter] = useState<number>(0);

  let { user } = useCurrentUser();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    sendPracticeAreaInfo(
      user?.userId || "",
      //@ts-ignore
      pracStore.currentPracticeArea?._id || ""
    );
  }, []);

  //ML Progessive update
  useEffect(() => {
    let interval = setInterval(() => {
      if (details.stage1 > 0 && details.stage1 < 100 && counter <= 15) {
        setDetails({
          ...details,
          stage1: details.stage1 + 0.7,
        });
        setCounter(counter + 1);
      }
      if (details.stage2 > 0 && details.stage2 < 100 && counter <= 15) {
        setDetails({
          ...details,
          stage2: details.stage2 + 0.7,
        });
        setCounter(counter + 1);
      }
      if (details.stage3 > 0 && details.stage3 < 100 && counter <= 15) {
        setDetails({
          ...details,
          stage3: details.stage3 + 0.7,
        });
        setCounter(counter + 1);
      }
    }, 6000);
    return () => {
      clearInterval(interval);
    };
  }, [details.stage1, details.stage2, details.stage3, counter]);

  useEffect(() => {
    setStages(setActive(stages, 4));
  }, []);

  useEffect(() => {
    if (details.trainingProgress === 100) {
      history.push("/app/pa/ws/model-training2");
    }
  }, [details]);

  return (
    <div className="w-full">
      <section className={styles.training_section}>
        <img src="/img/training_icon.svg" width={40} alt="Training Icon" />
        <div>Training in progress ...</div>
        <div className={styles.training_percentage}>
          {details.trainingProgress}%
        </div>
      </section>
      {/* Training Section */}
      {/* Data Section
      <section className={styles.data_section}>
        <div className={styles.data_container}>
          <div className={styles.data_heading}>DATA POINT</div>
          <div className={styles.data_value}>{details.dataPoint}</div>
        </div>
        <div className={styles.data_container}>
          <div className={styles.data_heading}>DATA POINT</div>
          <div className={styles.data_value}>{details.dataPoint}</div>
        </div>
        <div className={styles.data_container}>
          <div className={styles.data_heading}>TOTAL UPLOADS</div>
          <div className={styles.data_value}>{details.totalUpload}</div>
        </div>
        <div className={styles.data_container}>
          <div className={styles.data_heading}>ESTIMATED TIME</div>
          <div className={styles.data_value}>{details.estimatedTime}</div>
        </div>
      </section> */}
      {/* Data Section */}
      {/* Graph Section */}
      <section className={styles.graph_section}>
        <div className={styles.graph_section_1}>
          <div className={styles.graph_section_1_heading}>Legend</div>
          <div className={styles.stage_section}>
            <div className={styles.stage_section_circle_1}></div>
            <div className={styles.stage_name}>Data Processing</div>
            <div className={styles.stage_percentage}>
              {details.stage1.toFixed(2)}%
            </div>
          </div>
          <div className={styles.stage_section}>
            <div className={styles.stage_section_circle_2}></div>
            <div className={styles.stage_name}>Classifier 1</div>
            <div className={styles.stage_percentage}>
              {details.stage2.toFixed(2)}%
            </div>
          </div>
          <div className={styles.stage_section}>
            <div className={styles.stage_section_circle_3}></div>
            <div className={styles.stage_name}>Classifier 2</div>
            <div className={styles.stage_percentage}>
              {details.stage3.toFixed(2)}%
            </div>
          </div>
        </div>
        <div className={styles.graph_section_2}>
          <ApexChart
            stage1={details.stage1}
            stage2={details.stage2}
            stage3={details.stage3}
            height={360}
          />
        </div>

        <div className={styles.graph_section_3}>
          <div className={styles.tip_container}>
            <img src="/img/tip_green.svg" alt="Tip Icon" />
            <div>Tip</div>
          </div>
          <div className={styles.tip_description}>
            You can choose to minimize this process and come back to it later.
            You can access it by peeking into the dashboard.
          </div>
        </div>
      </section>
    </div>
  );
};

export default ModelTraining1;
