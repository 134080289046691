import * as React from "react";
import { Route, Switch } from "react-router-dom";
import usePracticeArea from "../../../../../store/zustand/usePracticeArea";
import Page404 from "../../../../page-404";
import Deployment from "./deployment";
import modelTrainig2 from "./model_training2";
import ReactGA from "react-ga";

export interface ModelTrainingProps {}

const ModelTraining: React.SFC<ModelTrainingProps> = () => {
  let pracStore = usePracticeArea();

  //if the ml training is not finished.. take the user to the ml 1 screen
  // React.useEffect(() => {
  //   if(pracStore.currentPracticeArea?.stages[3].active === true){

  //   }
  // } , [])

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div>
      <Switch>
        <Route
          path={"/app/pa/ws/model-training2"}
          exact
          component={modelTrainig2}
        />
        <Route path={"/app/pa/ws/model-training2/dep"} component={Deployment} />
        <Route component={Page404} />
      </Switch>
    </div>
  );
};

export default ModelTraining;
