import React, { useEffect, useState } from "react";
import UploadCard from "../../../components/workflow-cards/uploadCard";
import StageCard from "../../../components/workflow-cards/stageCard";
import TrainCard from "../../../components/workflow-cards/trainCard";
import MasterListCard from "../../../components/workflow-cards/masterListCard";
import DeployCard from "../../../components/workflow-cards/deployCard";
import IterateCard from "../../../components/workflow-cards/iterateCard";
import usePracticeArea from "../../../store/zustand/usePracticeArea";
import useMasterlist from "../../../components/masterlist/hooks/useMasterlist";
import { nodesAtom } from "../../../components/masterlist/atoms";
import { useSetRecoilState } from "recoil";
import { injestProperties } from "../../../components/masterlist/util/helper";

interface WorkFlowProps {}

const WorkFlow: React.FC<WorkFlowProps> = () => {
  let { currentPracticeArea } = usePracticeArea();
  let currentPracticeAreaId = currentPracticeArea?.practiceAreaId;
  let [active, setActive] = useState(-1);
  let { loading, data, error } = useMasterlist();
  let setNodes = useSetRecoilState(nodesAtom);

  React.useEffect(() => {
    if (loading === false && error === undefined && data !== undefined) {
      let injestedNodes = injestProperties(data.nodes);
      setNodes(injestedNodes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data, error]);

  useEffect(() => {
    let temp = -1;
    for (let i = 0; i < 5; i++) {
      if (
        currentPracticeArea?.stages[i] &&
        currentPracticeArea?.stages[i].active === true
      ) {
        temp = i;
        setActive(temp);
      }
    }
    if (
      temp === 3 &&
      currentPracticeArea?.alpdsInfo.currentPracticeArea?.alpdsInfo
        ?.percentageStatus?.stage2Progress?.batch1Progress !== 100
    ) {
      temp = 2;
      setActive(temp);
    }
  }, []);
  return (
    <div
      className={
        "w-full flex  2xl:justify-center items-center overflow-scroll custom_scroll"
      }
      style={{
        height: "100vh",
        marginTop: window.innerWidth < 1025 ? -100 : -150,
      }}
    >
      <div className={"h-full flex flex-col justify-center space-y-4"}>
        <UploadCard practiceArea={currentPracticeAreaId} />
        <MasterListCard />
      </div>
      <div className={"h-full flex flex-col justify-center"}>
        <svg height={100} width={25}>
          <path
            d={"M 0 10 C 12.5 10 12.5 100 25 100"}
            fill={"transparent"}
            strokeWidth={2}
            stroke={
              currentPracticeArea?.stages[0].active === true
                ? "#1BA94C"
                : "#C4C4C4"
            }
          />
        </svg>
        <svg height={100} width={25}>
          <path
            d={"M 0 100 C 12.5 100 12.5 10 25 10"}
            fill={"transparent"}
            strokeWidth={2}
            stroke={
              currentPracticeArea?.stages[2].active === true
                ? "#1BA94C"
                : "#C4C4C4"
            }
          />
        </svg>
      </div>
      <div className={"h-full flex flex-col justify-center"}>
        <StageCard
          practiceAreaId={currentPracticeAreaId}
          activeStage={active}
        />
      </div>
      <div className={"justify-center flex flex-col"}>
        <svg width={25} height={50}>
          <path
            d={"M 0 10 H 50"}
            stroke={
              currentPracticeArea?.stages[4].active === true
                ? "#1BA94C"
                : "#C4C4C4"
            }
            fill={"transparent"}
            strokeWidth={2}
          />
        </svg>
      </div>
      <div
        style={{
          marginTop: "204px",
        }}
        className={"flex flex-col justify-center items-center"}
      >
        <TrainCard
          practiceAreaId={currentPracticeAreaId}
          activeStage={active}
        />
        <svg width={50} height={25}>
          <path
            d={"M 10 0 V 50"}
            strokeWidth={2}
            fill={"transparent"}
            stroke={
              currentPracticeArea?.stages[5].active === true
                ? "#1BA94C"
                : "#C4C4C4"
            }
          />
        </svg>
        <IterateCard />
      </div>
      <div className={"flex flex-col justify-center"}>
        <svg width={25} height={50}>
          <path
            d={"M 0 10 H 25"}
            stroke={
              currentPracticeArea?.stages[5].active === true
                ? "#1BA94C"
                : "#C4C4C4"
            }
            strokeWidth={2}
            fill={"transparent"}
          />
        </svg>
      </div>
      <div className={"flex flex-col justify-center"}>
        <DeployCard
          practiceAreaId={currentPracticeAreaId}
          activeStage={active}
        />
      </div>
    </div>
    // <div className="flex w-full mx-10" style={{ height: "70vh" }}>
    //   <div className="flex flex-col">
    //     <UploadCard practiceArea={currentPracticeAreaId} />
    //     <MasterListCard />
    //   </div>
    //   {/* Wires */}
    //   <div className={"h-full flex justify-center items-center"}>
    //     <svg
    //       width={50}
    //       height={100}
    //       style={{
    //         display: "absolute",
    //       }}
    //     >
    //       <path
    //         stroke={"black"}
    //         fill={"transparent"}
    //         strokeWidth={2}
    //         d={"M 10 10 C 30 10 30 100 50 100"}
    //       />
    //     </svg>
    //   </div>
    //   <div>
    //     <StageCard
    //       practiceAreaId={currentPracticeAreaId}
    //       activeStage={active}
    //     />
    //   </div>
    //   <div className="flex flex-col justify-center">
    //     <TrainCard
    //       practiceAreaId={currentPracticeAreaId}
    //       activeStage={active}
    //     />
    //     <IterateCard />
    //   </div>
    //   <div
    //     className="flex items-center w-1/5"
    //     style={{ height: "392px", margin: "0 25px 0 33px" }}
    //   >
    //     <DeployCard
    //       practiceAreaId={currentPracticeAreaId}
    //       activeStage={active}
    //     />
    //   </div>
    //</div>
  );
};

export default WorkFlow;
