import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import usePracticeArea from "../../store/zustand/usePracticeArea";

interface DeployCardProps {
  activeStage: number;
  practiceAreaId: string | undefined;
}

const DeployCard: React.FC<DeployCardProps> = ({
  activeStage,
  practiceAreaId,
}) => {
  let { currentPracticeArea } = usePracticeArea();
  let history = useHistory();
  let [trained, setTrained] = useState(false);
  useEffect(() => {
    setTrained(currentPracticeArea?.stages[5].active ? true : false);
    return () => {};
  }, [currentPracticeArea?.stages]);
  return (
    <div
      className="p-3 bg-white w-full"
      style={{ height: "180px", width: "300px" }}
      onClick={() => {
        if (activeStage >= 5) history.push("/app/pa/ws/model-training2/dep");
      }}
    >
      <div className="flex space-x-2">
        <img
          width={17}
          height={22}
          src="/img/deployBlue.svg"
          alt="validate"
        ></img>
        <p style={{ fontWeight: 500, fontSize: "15px" }}>Deploy</p>
      </div>
      <div className="flex w-full justify-center my-3">
        <div className="w-11/12 flex flex-col gap-2 justify-center items-center">
          <div
            className={`flex justify-center items-center text-center cursor-${
              trained ? "pointer" : "not-allowed"
            } ${
              trained
                ? "hover:bg-primarycolor hover:text-white text-primarycolor"
                : "bg-gray-300 text-white"
            }`}
            onClick={() =>
              trained
                ? history.push("/app/pa/ws/model-training2/dep")
                : toast("Complete training the model first!", {
                    type: "warning",
                  })
            }
            style={{
              border: `1px solid ${trained ? "#2C2C2C" : "white"}`,
              fontSize: "16px",
              width: "250px",
              height: "30px",
            }}
          >
            Prediction on Ingested Data
          </div>
          <div
            className={`flex justify-center items-center text-center cursor-${
              trained ? "pointer" : "not-allowed"
            } ${
              trained
                ? "hover:bg-primarycolor hover:text-white text-primarycolor"
                : "bg-gray-300 text-white"
            }`}
            onClick={() =>
              trained
                ? history.push("/app/pa/ws/model-training2/dep/export")
                : toast("Complete training the model first!", {
                    type: "warning",
                  })
            }
            style={{
              border: `1px solid ${trained ? "#2C2C2C" : "white"}`,
              fontSize: "16px",
              width: "250px",
              height: "30px",
            }}
          >
            Export Dataset
          </div>
          <div
            className={`flex justify-center items-center text-center cursor-${
              trained ? "pointer" : "not-allowed"
            } ${
              trained
                ? "hover:bg-primarycolor hover:text-white text-primarycolor"
                : "bg-gray-300 text-white"
            }`}
            onClick={() =>
              trained
                ? history.push("/app/pa/ws/model-training2/dep/prediction")
                : toast("Complete training the model first!", {
                    type: "warning",
                  })
            }
            style={{
              border: `1px solid ${trained ? "#2C2C2C" : "white"}`,
              fontSize: "16px",
              width: "250px",
              height: "30px",
            }}
          >
            Model API
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeployCard;
