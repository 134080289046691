import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useHistory } from "react-router";
import { StageContext } from "../../pages/app/practice-area/pa";
import ApexChart from "../../pages/app/practice-area/pa/modal-training-1/ApexChart";
import Chart from "../../pages/app/workflow/chart";
import { sendPracticeAreaInfo } from "../../realtime";
import useCurrentUser from "../../store/zustand/useCurrentUser";
import usePracticeArea from "../../store/zustand/usePracticeArea";
import { setActive } from "../workspace-nav/stage-helper";

interface TrainCardProps {
  practiceAreaId: string | undefined;
  activeStage: number;
}
export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}
const TrainCard: React.FC<TrainCardProps> = () => {
  let history = useHistory();
  let pracStore = usePracticeArea();

  let handleClick = () => {
    //condition for model-training-1 and model-training 2
    if (pracStore.currentPracticeArea?.stages[4].active) {
      history.push("/app/pa/ws/model-training1");
    } else {
      history.push("/app/pa/ws/model-training2");
    }
  };

  let initial =
    pracStore.currentPracticeArea?.alpdsInfo?.percentageStatus?.automlProgress;

  const [details, setDetails] = useState({
    trainingProgress: 0,
    dataPoint: "bussiness",
    totalUpload: 0,
    estimatedTime: "12 minutes 30 seconds",
    stage1: initial?.dataLoadingProgress || 0,
    stage2: initial?.automlClassifier1Progress || 0,
    stage3: initial?.automlClassifier2Progress || 0,
  });

  useEffect(() => {
    //setting the state

    let data =
      pracStore.currentPracticeArea?.alpdsInfo?.percentageStatus
        ?.automlProgress;

    if (data) {
      let s1 = data?.dataLoadingProgress || 0;
      let s2 = data.automlClassifier1Progress || 0;
      let s3 = data.automlClassifier2Progress || 0;

      setDetails({
        ...details,
        trainingProgress: Math.round((s1 + s2 + s3) / 3),
        stage1: s1,
        stage2: s2,
        stage3: s3,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pracStore.currentPracticeArea?.alpdsInfo?.automlProgress?.automlClassifier1
      ?.dataLoadingProgress,
    pracStore.currentPracticeArea?.alpdsInfo?.percentageStatus?.automlProgress
      ?.automlClassifier1Progress,
    pracStore.currentPracticeArea?.alpdsInfo?.percentageStatus?.automlProgress
      ?.automlClassifier2Progress,
  ]);

  let { stages, setStages } = useContext(StageContext);

  let [counter, setCounter] = useState<number>(0);

  let { user } = useCurrentUser();

  // let [trained, setTrained] = useState(false);

  // useEffect(() => {
  //   setTrained(stages[3].active);
  //   return () => {};
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [stages]);

  useEffect(() => {
    sendPracticeAreaInfo(
      user?.userId || "",
      //@ts-ignore
      pracStore.currentPracticeArea?._id || ""
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //ML Progessive update
  useEffect(() => {
    let interval = setInterval(() => {
      if (details.stage1 > 0 && details.stage1 < 100 && counter <= 15) {
        setDetails({
          ...details,
          stage1: details.stage1 + 0.7,
        });
        setCounter(counter + 1);
      }
      if (details.stage2 > 0 && details.stage2 < 100 && counter <= 15) {
        setDetails({
          ...details,
          stage2: details.stage2 + 0.7,
        });
        setCounter(counter + 1);
      }
      if (details.stage3 > 0 && details.stage3 < 100 && counter <= 15) {
        setDetails({
          ...details,
          stage3: details.stage3 + 0.7,
        });
        setCounter(counter + 1);
      }
    }, 6000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details.stage1, details.stage2, details.stage3, counter]);

  useEffect(() => {
    setStages(setActive(stages, 4));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details.stage1, details.stage2, details.stage3]);

  // useEffect(() => {
  //   if (details.trainingProgress === 100) {
  //   }
  // }, [details]);

  return (
    <div
      className="p-3 bg-white w-full"
      style={{ height: "180px", width: "300px" }}
      onClick={() => handleClick()}
    >
      <div className="flex space-x-2">
        <img
          width={17}
          height={22}
          src="/img/trainCardIcon.svg"
          alt="validate"
        ></img>
        <p style={{ fontWeight: 500, fontSize: "15px" }}>Train</p>
      </div>
      {details.stage1 === 0 ? (
        <div className="flex h-full justify-center items-center">
          <Chart stage1={0} stage2={0} stage3={0} />
          <div className="flex items-center" style={{ height: "120px" }}>
            <p
              style={{
                fontWeight: 300,
                color: "#9A9A9A",
                lineHeight: "20px",
                fontSize: "14px",
              }}
            >
              Model Training has not yet been initiated.
            </p>
          </div>
        </div>
      ) : details.trainingProgress === 100 ? (
        <div className="my-5 mx-2">
          <p
            style={{
              color: "#2C2C2C",
              fontWeight: 300,
              lineHeight: "15px",
              marginBottom: "5px",
            }}
          >
            Accuracy
          </p>
          <p style={{ fontWeight: 400, fontSize: "18px", lineHeight: "21px" }}>
            {pracStore.currentPracticeArea?.alpdsInfo.trainInfo.MicroF1 * 100}%
          </p>
          <div className="flex w-full bg-white h-12 border-2 mt-2">
            <div
              style={{
                backgroundColor:
                  pracStore.currentPracticeArea?.alpdsInfo.trainInfo.MicroF1 <
                  0.75
                    ? "#F59F00"
                    : "#1BA94C",
                width: `${
                  pracStore.currentPracticeArea?.alpdsInfo.trainInfo.MicroF1 *
                  100
                }%`,
              }}
            ></div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center">
          <ApexChart
            stage1={details.stage1}
            stage2={details.stage2}
            stage3={details.stage3}
            height={180}
          />
          <div className="flex flex-col text-left gap-3">
            <div
              style={{
                width: "120px",
              }}
            >
              <text className="text-gray-400">Stage 1</text>&nbsp;&nbsp;&nbsp;
              <text>{details.stage1.toFixed(2)}%</text>
            </div>
            <div
              style={{
                width: "120px",
              }}
            >
              <text className="text-gray-400">Stage 2</text>&nbsp;&nbsp;&nbsp;
              <text>{details.stage2.toFixed(2)}%</text>
            </div>
            <div
              style={{
                width: "120px",
              }}
            >
              <text className="text-gray-400">Stage 3</text>&nbsp;&nbsp;&nbsp;
              <text>{details.stage3.toFixed(2)}%</text>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TrainCard;
