import { Action } from "../../utils/global-interfaces";
import {
  ADD_PRACTICE_AREA,
  CURRENT_PRACTICE_AREA,
  REMOVE_PRACTICE_AREA,
  SET_PRACTICE_AREA,
  SET_PREV_PRAC,
} from "../types/practice-area-types";

export interface PracticeArea {
  _id?: { $oid: string };
  acronym: string;
  stages: Array<{
    name: string;
    active: boolean;
  }>;
  status: string;
  name: string;
  practiceAreaId: string;
  createdBy: string;
  completedPercentage: Array<{
    _id?: { $oid: string };
    value: Number;
    updatedAt: { $date: string };
  }>;
  createdAt: { $date: string };
  updatedAt: { $date: string };
  __v?: Number;
  useNoTag: boolean;
  alpdsInfo: any;
  masterlistLocked: boolean;
}

export interface PracticeAreaState {
  practiceAreas: Array<PracticeArea>;
  currentPracticeArea: PracticeArea | null;
  prev_practiceAreaId: string | null;
}

let defaultState: PracticeAreaState = {
  practiceAreas: [],
  currentPracticeArea: null,
  prev_practiceAreaId: "",
};

let practiceAreaReducer = (
  state: PracticeAreaState = defaultState,
  action: Action
) => {
  switch (action.type) {
    case ADD_PRACTICE_AREA:
      return {
        ...state,
        practiceAreas: [...state.practiceAreas, action.payload],
      };
    case REMOVE_PRACTICE_AREA:
      state.practiceAreas.filter(
        (pa: PracticeArea) => pa._id?.$oid !== action.payload
      );
      break;
    case SET_PRACTICE_AREA:
      state.practiceAreas = action.payload;
      break;
    case CURRENT_PRACTICE_AREA:
      state.currentPracticeArea = action.payload;
      break;
    case SET_PREV_PRAC:
      state.prev_practiceAreaId = action.payload.id;
      break;
  }

  return state;
};

export default practiceAreaReducer;
