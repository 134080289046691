import * as React from "react";
import WorkspaceNav from "../workspace-nav";

const ChildPracticeAreaLayout: React.FC = ({ children }) => {
  return (
    <div>
      <WorkspaceNav />
      <div className="p-3">{children}</div>
    </div>
  );
};

export default ChildPracticeAreaLayout;
