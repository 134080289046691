import { Progress } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRecoilState } from "recoil";
import { NotificationAtom } from "../../../components/topbar/notificationAtom";
import {
  TRIGGER_FILE_SPLIT,
  UPLOAD_FILE_URL,
} from "../../../utils/server-urls";
import { toast } from "react-toastify";
import { FEEDBACK_REJECT_PARA_INIT } from "../../../store/types/feedback.types";
import usePracticeArea from "../../../store/zustand/usePracticeArea";
import { GlobalState } from "../../../store/reducers";
import { apiNetworkCall } from "../../../utils/api";

export interface UploadPageProps {}

const UploadPage: React.SFC<UploadPageProps> = () => {
  let [percentUploaded, setPercenUploaded] = useState(0);
  let [selectedFile, setSelectedFile] = useState<any>();
  let { currentPracticeArea } = usePracticeArea();

  let [notifications, setNotifications] = useRecoilState(NotificationAtom);

  let dispatch = useDispatch();
  React.useEffect(() => {
    dispatch({
      type: FEEDBACK_REJECT_PARA_INIT,
    });
  }, []);

  let issueId = useSelector((state: GlobalState) => state.feedback.node_id);

  const uploadFile = async () => {
    if (selectedFile) {
      let formData = new FormData();
      formData.append("uploaded_file", selectedFile);
      formData.append(
        "practiceAreaId",
        currentPracticeArea?.practiceAreaId === undefined
          ? ""
          : currentPracticeArea?.practiceAreaId
      );
      // @ts-ignore
      try {
        let res = await axios({
          method: "post",
          url: UPLOAD_FILE_URL,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("userInfo") as string).token,
          },
          onUploadProgress: (e) => {
            setPercenUploaded(Math.round((100 * e.loaded) / e.total));
          },
        });

        if (res.status === 200) {
          apiNetworkCall(TRIGGER_FILE_SPLIT, "POST", {
            practiceAreaId: currentPracticeArea?.practiceAreaId,
            issueId: issueId,
            type: "seed",
          }).then((res) => {});

          toast("File uploaded successfully", { type: "success" });
          setNotifications([...notifications, "File uploaded successfully"]);
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      toast("Please select a file to upload", { type: "success" });
      setNotifications([...notifications, "Please select a file to upload"]);
    }
  };

  useEffect(() => {
    if (selectedFile) {
      uploadFile();
      setSelectedFile(undefined);
    }
  }, [selectedFile]);

  return (
    <div
      className="w-full"
      style={{
        background: "#fff",
        width: "100%",
        minWidth: "250px",
        color: "#11256D",
        height: "45vh",
      }}
    >
      <div className="flex justify-center items-center mx-1 h-4/5 flex-col">
        <div>
          <img
            style={{
              position: "absolute",
              transform: "translateX(60px)",
              pointerEvents: "none",
            }}
            src="/img/upload.svg"
            alt="upload"
          />

          <input
            style={{ width: 300, height: 140, opacity: 0 }}
            className={"border-dashed border-2 border-green-500"}
            accept={".csv, .zip, .txt"}
            type="file"
            placeholder={"Select file"}
            onChange={(e) => {
              if (e.target.files?.length !== 0) {
                //@ts-ignore
                setSelectedFile(e.target.files[0]);
              }
            }}
          />
          <Progress percent={percentUploaded} />
        </div>
        {selectedFile !== undefined && <div>{selectedFile.name}</div>}
      </div>
    </div>
  );
};

export default UploadPage;
