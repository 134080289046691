import * as React from 'react';

const Page404: React.SFC = () => {
    return ( 
        <div>
            <h1>Our 404 page</h1>
        </div>
     );
}
 
export default Page404;