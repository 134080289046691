import { Divider, Dropdown } from "antd";
import * as React from "react";
import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { DownOutlined } from "@ant-design/icons";
import { apiNetworkCall } from "../../utils/api";
import { UPDATE_NODE } from "../../utils/server-urls";
import { NotificationAtom } from "../topbar/notificationAtom";
import {
  activeNodeAtom,
  layersAtom,
  masterlistIdAtom,
  nodesAtom,
} from "./atoms";
import { deleteNodes, getNodeInfo, markAsModalClass } from "./util/helper";
import { toast } from "react-toastify";
import usePracticeArea from "../../store/zustand/usePracticeArea";

// const menu = (
//   <Menu>
//     <Menu.Item key="1">Version 1</Menu.Item>
//     <Menu.Item key="2">Version 2</Menu.Item>
//     <Menu.Item key="3">Version 3</Menu.Item>
//     <Menu.Item key="3">Version 4</Menu.Item>
//   </Menu>
// );

const Toolbar: React.FC = () => {
  let activeNode = useRecoilValue(activeNodeAtom);
  let [nodes, setNodes] = useRecoilState(nodesAtom);
  let [layers, setLayers] = useRecoilState(layersAtom);

  // let [useNoTag, setUseNoTag] = useState(false);
  let masterlist = useRecoilValue(masterlistIdAtom);

  let { currentPracticeArea } = usePracticeArea();

  let [notifications, setNotifications] = useRecoilState(NotificationAtom);

  // let updateNoTag = (val: boolean) => {
  //   apiNetworkCall(USE_NO_TAG, "POST", {
  //     practiceAreaId: currentPracticeArea?.practiceAreaId,
  //     useNoTag: val,
  //   })
  //     .then((res) => {
  //       //no tag updated
  //     })
  //     .catch((err) => {
  //       toast("Internal server error : No tag", {
  //         type: "error",
  //       });
  //       setNotifications([...notifications, "Internal server error : No tag"]);
  //     });
  // };

  let deleteNode = () => {
    if (activeNode !== null) {
      if (activeNode.parent_id !== null) {
        apiNetworkCall(UPDATE_NODE, "POST", {
          nodeId: activeNode.parent_id,
          updated_node: {
            child_node_ids: getNodeInfo(
              activeNode.parent_id,
              nodes
            )?.child_node_ids.filter((id) => id !== activeNode?._id),
          },
        })
          .then((res: any) => {
            //node deleted
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        //this is a root node
      }

      if (masterlist !== null) {
        let new_state = deleteNodes(activeNode, nodes, layers, masterlist._id);
        setNodes(new_state.nodes);
        setLayers(new_state.layers.splice(0, new_state.layers.length - 1));
      }
    } else {
      toast("Please select a node to delete it", { type: "info" });
      setNotifications([...notifications, "Please select a node to delete it"]);
    }
  };

  useEffect(() => {
    // if (currentPracticeArea?.useNoTag !== undefined) {
    //   setUseNoTag(currentPracticeArea.useNoTag);
    // }

    window.onscroll = (e) => {
      let toolbarEle = document.getElementById("toolbar_masterlist");
      let topbar = document.getElementById("topbar");
      let ph = document.getElementById("placeholder");
      if (toolbarEle !== null && topbar !== null) {
        let stopAt = topbar.getBoundingClientRect().bottom;
        if (window.pageYOffset - 10 > stopAt) {
          toolbarEle.style.position = "fixed";
          toolbarEle.style.top = `${stopAt}px`;
          toolbarEle.style.width = "89%";
          toolbarEle.style.backgroundColor = "white";
          toolbarEle.style.padding = "10px";
          toolbarEle.style.zIndex = "100";
          if (ph) {
            ph.style.height = "50px";
          }
        } else {
          toolbarEle.style.position = "relative";
          toolbarEle.style.top = `initial`;
          toolbarEle.style.width = "98%";
          toolbarEle.style.backgroundColor = "#fafafa";
          if (ph) {
            ph.style.height = "0px";
          }
        }
      }
    };

    return () => {
      window.onscroll = null;
    };
  }, []);

  let selectAsModelClass = () => {
    if (activeNode !== null) {
      if (activeNode.is_model_class !== true) {
        let new_state = markAsModalClass(activeNode, nodes, layers);
        setNodes(new_state.nodes);
        setLayers(new_state.layers);
      } else {
        toast("Already a modal node.", { type: "success" });
      }
    } else {
      toast("Select a node to mark it as a model node", { type: "success" });
    }
  };

  return (
    <>
      <div id={"placeholder"}></div>
      <div
        id={"toolbar_masterlist"}
        className={"flex justify-between items-center"}
        style={{
          width: "98%",
        }}
      >
        <div className="flex space-x-3">
          <button
            style={{
              backgroundColor:
                currentPracticeArea &&
                currentPracticeArea.masterlistLocked === false
                  ? "#1BA94C"
                  : "#acacac",
              borderRadius: 4,
              padding: "6px 18px",
              color: "white",
            }}
            onClick={() => {
              if (
                currentPracticeArea &&
                currentPracticeArea.masterlistLocked === false
              ) {
                selectAsModelClass();
              } else {
                toast(
                  "Please create a new practice area to define a new masterlist",
                  {
                    type: "info",
                  }
                );
              }
            }}
          >
            Select as Model Class
          </button>

          <button
            onClick={() => {
              if (
                currentPracticeArea &&
                currentPracticeArea.masterlistLocked === false
              ) {
                deleteNode();
              } else {
                toast(
                  "Please create a new practice area to define a new masterlist",
                  {
                    type: "info",
                  }
                );
              }
            }}
            style={{
              backgroundColor:
                currentPracticeArea &&
                currentPracticeArea.masterlistLocked === false
                  ? "#E63946"
                  : "#C4C4C4",
              borderRadius: 4,
              padding: "3px 18px",
              color: "white",
            }}
          >
            Delete Class
          </button>
        </div>
        <div className={"flex space-x-3"}>
          <Dropdown overlay={<></>}>
            <div
              className="px-5 flex justify-between items-center rounded-md"
              style={{
                width: "200px",
                height: "38px",
                background: "#EAEAEA",
                fontSize: "15px",
                fontWeight: 400,
              }}
            >
              <p>Version 1</p>
              <DownOutlined />
            </div>
          </Dropdown>
        </div>
      </div>
      <Divider className={"mt-3 p-0"} />
    </>
  );
};

export default Toolbar;
