import axios from "axios";
import { isEmpty } from "validate.js";
import { Method } from "axios";

let token =
  JSON.parse(localStorage.getItem("userInfo") as string) !== null
    ? JSON.parse(localStorage.getItem("userInfo") as string).token
    : "";

export let apiNetworkCall = (url: string, method: Method, data: any) => {
  return new Promise(async (resolve, reject) => {
    if (isEmpty(token)) {
      reject(new Error("User token is not available"));
    } else {
      try {
        let res = await axios({
          url,
          method,
          data,
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
            accept: "application/json",
          },
        });

        resolve(res.data);
      } catch (e) {
        reject(e);
      }
    }
  });
};
