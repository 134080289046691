import { Divider } from "antd";
import * as React from "react";
import { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { StageContext } from "..";
import RejectedPara from "../../../../../components/data-validation/RejectedPara";
import SeedPara from "../../../../../components/data-validation/SeedPara";
import StageNode from "../../../../../components/data-validation/StageNode";
import StagePara from "../../../../../components/data-validation/StagePara";
import LoadingScreen from "../../../../../components/loading-screen";
import { nodesAtom } from "../../../../../components/masterlist/atoms";
import { NotificationAtom } from "../../../../../components/topbar/notificationAtom";
import { setActive } from "../../../../../components/workspace-nav/stage-helper";
import { GlobalState } from "../../../../../store/reducers";
import { ParaDataType } from "../../../../../store/reducers/stage1Reducer";
import {
  INIT_REJECT_PARA,
  REJECT_PARA,
  STAGE1_ADD_PARAS,
  STAGE1_NODES_REQUEST,
  STAGE1_PRE_PARAS,
  STAGE1_PROGRESS_UPDATE,
  UPDATE_NODE_FEEDBACK,
} from "../../../../../store/types/stage1-types";
import { apiNetworkCall } from "../../../../../utils/api";
import {
  GET_NODES,
  GET_NODE_PARAS,
  RESET_STAGE_1,
  SEED_PARA,
  STAGE_2_INTEGRATE,
} from "../../../../../utils/server-urls";
import style from "./stage1.module.css";
import { toast } from "react-toastify";
import { getWithoutNan } from "../../../../../utils/helper";
import usePracticeArea from "../../../../../store/zustand/usePracticeArea";
import axios from "axios";
import { getNodeInfo } from "../../../../../components/masterlist/util/helper";
import ReactGA from "react-ga";
import useNerStore from "../../../../../store/zustand/useNerStore";
import NerSidebar from "../../../../../components/ner/NerSidebar";

let Stage1: React.FC = (): any => {
  let { currentPracticeArea } = usePracticeArea();

  let nerStore = useNerStore();
  let dispatch = useDispatch();
  let stage1 = useSelector((state: GlobalState) => state.stage1);
  let [batch, setBatch] = useState<number>(1);
  let [active_node, setActiveNode] = useState<any>();
  let [, setLoading] = useState(true);
  let [nodeLoading, setNodeLoading] = useState(true);
  let [seedPara, setSeedPara] = useState<boolean>(false);
  let [integrated, setIntegrated] = useState(true);
  let [stage1percentage, setStage1Percentage] = useState("0%");
  let [state2started, setStage2Started] = useState(false);
  let [stage2percentage, setStage2percentage] = useState("0%");
  let { stages, setStages } = useContext(StageContext);
  let [current_paras, setCurrent_paras] = useState<Array<ParaDataType> | null>(
    null
  );
  let [disable, setDisable] = useState<boolean>(true);
  let comp: any = [];
  let nodesComplete: any = [];

  useEffect(() => {
    nerStore.setStage(1);
  }, []);

  useEffect(() => {
    setStages(setActive(stages, 2));
    ReactGA.pageview(window.location.pathname);
  }, []);

  useEffect(() => {
    if (
      active_node &&
      active_node?.goal <= active_node?.feedback &&
      active_node.goal !== 0
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [active_node, stage1.nodes]);

  let compare = (a: any, b: any) => {
    let paraSecId1 = a.para_info.paraSeqId;
    let paraSecId2 = b.para_info.paraSeqId;
    if (paraSecId1 < paraSecId2) return -1;
    else if (paraSecId1 > paraSecId2) return 1;
    return 0;
  };
  let nodes = useRecoilValue(nodesAtom);

  let fetchRejectedParaTag = (para: any, curNodeName: string, issueId: any) => {
    let nodeId = para.alpdsInfo?.rankMetric.stage1.value[1][0];
    let node_info = nodes.filter((nodeInfo) => nodeInfo.node_id === nodeId)[0];
    if (node_info !== undefined) {
      let node_name = node_info.node_name;
      let parentInfo = nodes.filter(
        (nodeInfo) => nodeInfo.node_id === node_info.parent_id
      )[0];

      let parent_name = "No Parent Name";

      if (parentInfo !== undefined) {
        parent_name = parentInfo.node_name;
      }
      let selected = null;
      if (issueId === "No Tag") {
        selected = 0;
      } else if (issueId !== null) {
        selected = 1;

        for (let i = 0; i < nodesComplete.length; i++) {
          if (nodesComplete[i].node_id === issueId) {
            //
            nodesComplete[i].completed_percentage += 1;
          }
        }
      }
      let nextPredicted = {
        node_name,
        parent_name,
        node_id: nodeId,
        issueId,
        selected,
      };

      dispatch({
        type: REJECT_PARA,
        payload: {
          data: {
            paraData: para.paraData,
            createdAt: para.createdAt,
            paraId: para.paraId,
          },
          nextPredicted,
          curNode: {
            node_name: curNodeName,
          },
        },
      });
    }
  };

  let checkConstrainsts = (): boolean => {
    let allowed = true;

    stage1.nodes.forEach((node) => {
      if (node && node.completed_percentage < 15 && node.feedback < node.goal) {
        allowed = false;
      }
    });

    return allowed;
  };

  let [fetching, setFetching] = useState(true);

  let fetchParas = async (node_id: string, node_name: string) => {
    setFetching(true);

    let completed = 0;
    apiNetworkCall(GET_NODE_PARAS, "post", {
      practiceAreaId: currentPracticeArea?.practiceAreaId,
      predictedIssueId: node_id,
      stage: 1,
    })
      .then((res: any) => {
        let result = res.result.data.paras;
        let total = 0,
          finished = 0;
        result.sort(compare);
        //
        result.map((para: any) => {
          if (para.accepted === "accepted") {
            completed += 1;
          }
          if (para.accepted !== null && para.batchId < 3) {
            total += 1;
            finished += 1;
          } else if (para.batchId < 3) {
            total += 1;
          }
        });

        let rejected_p = result.filter(
          (para: any) => para.accepted === "rejected"
        );
        comp.push({
          node_id,
          completed,
        });
        dispatch({
          type: STAGE1_ADD_PARAS,
          payload: {
            node_id,
            data: result,
          },
        });
        // let c=0;
        // for(let i=0;i<stage1.nodes.length;i++){
        // 	if(stage1.nodes[i].node_id===node_id){
        // 		c=stage1.nodes[i].completed_percentage;
        // 		break;
        // 	}
        // }
        dispatch({
          type: STAGE1_PROGRESS_UPDATE,
          payload: {
            node_id,
            data: completed,
          },
        });
        for (let i = 0; i < rejected_p.length; i++) {
          fetchRejectedParaTag(
            rejected_p[i].para_info,
            node_name,
            rejected_p[i].issueId
          );
        }
        //

        dispatch({
          type: UPDATE_NODE_FEEDBACK,
          payload: {
            node_id: node_id,
            goal: total,
            feedback: finished,
          },
        });

        setFetching(false);
      })
      .catch((err) => {
        setFetching(false);
      });
    setLoading(false);
  };

  let backToTop = () => {
    let ele = document.getElementById("stage_1_paras");
    ele?.scrollTo({ top: 0 });
  };

  useEffect(() => {
    backToTop();
  }, [active_node, batch]);

  let [notifications, setNotifications] = useRecoilState(NotificationAtom);

  let updateComplete = () => {
    let completed = 0;
    for (let i = 0; i < nodesComplete.length; i++) {
      let id = nodesComplete[i].node_id;
      completed = nodesComplete[i].completed_percentage;
      for (let j = 0; j < comp.length; j++) {
        if (comp[j].node_id === id) {
          let c = comp[j].completed;

          comp[j].completed = completed + c;
          break;
        }
      }
    }
    for (let i = 0; i < comp.length; i++) {
      dispatch({
        type: STAGE1_PROGRESS_UPDATE,
        payload: {
          node_id: comp[i].node_id,
          data: comp[i].completed,
        },
      });
    }
  };

  let fetchStage = async () => {
    dispatch({
      type: INIT_REJECT_PARA,
      payload: {},
    });
    try {
      let data: any = await apiNetworkCall(GET_NODES, "post", {
        practiceAreaId: currentPracticeArea?.practiceAreaId,
        stage: 1,
      });
      // stage1.rejected_paras=[];
      if (data.status === 200) {
        let nodes_res = data.result.data.issues;
        let final_nodes = nodes_res.map((node: any) => node.nodeinfo);
        final_nodes = final_nodes.map((fn: any) => {
          fn.completed_percentage = 0;
          fn.goal = 0;
          fn.feedback = 0;
          return fn;
        });
        dispatch({
          type: STAGE1_NODES_REQUEST,
          payload: final_nodes,
        });
        setActiveNode(final_nodes[0]);
        // setCurParas();
        let node_populate = [];

        try {
          for (let i = 0; i < nodes.length; i++) {
            nodesComplete.push({
              node_id: nodes_res[i].nodeinfo.node_id,
              completed_percentage: 0,
            });
            node_populate.push({
              node_id: nodes_res[i].nodeinfo.node_id,
              data: [],
            });
          }
        } catch (err) {
          console.error(err);
        }

        dispatch({
          type: STAGE1_PRE_PARAS,
          payload: node_populate,
        });

        for (let i = 0; i < nodes.length; i++) {
          fetchParas(
            nodes_res[i].nodeinfo.node_id,
            nodes_res[i].nodeinfo.node_name
          );
        }
        setTimeout(() => {
          updateComplete();
        }, 1500 * nodesComplete.length);
      } else {
        toast("Internal Server Error : Fetching the node", {
          type: "error",
        });
        setNotifications([
          ...notifications,
          "Internal Server Error : Fetching the node",
        ]);
      }
    } catch (err) {
      // notification.error({
      // 	message: "There was some error during fetching the nodes",
      // })
      // setNotifications([
      // 	...notifications,
      // 	"There was some error during fetching the nodes",
      // ])
    }
    setNodeLoading(false);
  };

  let [isAllowed, setIsAllowed] = useState<boolean>(false);

  useEffect(() => {
    let check = checkConstrainsts();
    setIsAllowed(check);
  });

  useEffect(() => {
    //fetching all the node issues

    if (integrated === true && nodes) {
      fetchStage();
    }
  }, [integrated, nodes]);

  let setCurParas = () => {
    let temp = [];
    if (stage1 && stage1.paras) {
      //@ts-ignore
      for (let i = 0; i < stage1.paras?.length; i++) {
        //@ts-ignore
        if (stage1.paras[i].node_id === active_node?.node_id) {
          //@ts-ignore
          temp = stage1.paras[i].data;
          break;
        }
      }
      temp = temp.filter((para: any) => para.batchId === batch);
      //
      setCurrent_paras(temp);
    }
  };

  useEffect(() => {
    let val = currentPracticeArea?.alpdsInfo?.percentageStatus
      ?.stage1Progress as number;

    if (val) {
      setStage1Percentage(`${val}%`);

      // setCounter(0);
      // progressiveUpdate();

      if (val === 100 || val === 0) {
        setIntegrated(true);
      } else {
        setIntegrated(false);
      }
    } else {
    }
  }, [currentPracticeArea?.alpdsInfo?.percentageStatus?.stage1Progress]);

  useEffect(() => {
    setCurParas();
  }, [active_node, batch, stage1.paras]);

  let [allowStage2, setAllowStage2] = useState(true);

  let history = useHistory();

  let moveToStage2 = async () => {
    if (allowStage2 === true) {
      setAllowStage2(false);
      await apiNetworkCall(STAGE_2_INTEGRATE, "post", {
        practiceAreaId: currentPracticeArea?.practiceAreaId,
        batchNumber: 1,
      })
        .then((res) => {
          toast("Stage 2 Auto Annotation started successfully", {
            type: "success",
          });
          setNotifications([
            ...notifications,
            "Stage 2 Auto Annotation started successfully",
          ]);
          setStage2Started(true);
          setIntegrated(false);
        })
        .catch((err) => {
          toast("Internal Server Error : Stage 2 Annotation", {
            type: "error",
          });
          setNotifications([
            ...notifications,
            "Internal Server Error : Stage 2 Annotation",
          ]);
        });
    }
  };

  useEffect(() => {
    if (
      currentPracticeArea?.alpdsInfo?.percentageStatus?.stage2Progress
        ?.batch1Progress === 100
    ) {
      setStages(setActive(stages, 3));
      history.push("/app/pa/ws/stage2");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPracticeArea?.alpdsInfo?.percentageStatus?.stage2Progress
      ?.batch1Progress,
  ]);

  useEffect(() => {
    let val = currentPracticeArea?.alpdsInfo?.percentageStatus?.stage2Progress
      ?.batch1Progress as number;

    if (val) {
      setStage2percentage(`${val}%`);

      if (val !== 100 && val !== 0) {
        setStage2Started(true);
        setIntegrated(false);
      }
    } else {
    }
  }, [
    currentPracticeArea?.alpdsInfo?.percentageStatus?.stage2Progress
      ?.batch1Progress,
  ]);

  let handleAddNewPara = (data: any) => {
    const selectedIssueId = active_node?.node_id; // from store
    if (!selectedIssueId) return console.error("Selected Issue id null");

    if (data.newParaTitle.length !== 0 && data.newParaData.length !== 0) {
      const newParaParams = {
        practiceAreaId: currentPracticeArea?.practiceAreaId,
        paraTitle: data.newParaTitle,
        paraData: data.newParaData,
        issueId: selectedIssueId,
      };
      setSeedPara(false);

      try {
        let res = apiNetworkCall(SEED_PARA, "post", newParaParams);
        toast("New para data was successfully added", { type: "success" });
        setNotifications([
          ...notifications,
          "New para data was successfully added",
        ]);
      } catch (err) {
        toast("Internal Server Error : Seed paragraph", { type: "success" });
        setNotifications([
          ...notifications,
          "Internal Server Error : Seed paragraph",
        ]);
      }
    }
  };
  let handleSkipNode = () => {
    let move = true;
    let next;
    for (let i = 0; i < stage1.nodes.length; i++) {
      if (
        stage1.nodes[i].completed_percentage >= 15 ||
        stage1.nodes[i].goal === stage1.nodes[i].feedback
      ) {
        continue;
      } else {
        next = stage1.nodes[i];
        move = false;
        break;
      }
    }
    if (move) {
      moveToStage2();
    } else {
      setActiveNode(next);
      setBatch(1);
    }
  };
  let handleCalculate = (nodes: any) => {
    let completed_percentage = 0;
    for (let i = 0; i < stage1.nodes.length; i++) {
      if (nodes[i].node_id === active_node?.node_id) {
        completed_percentage = nodes[i].completed_percentage;
      }
    }
    return completed_percentage;
  };

  let resetStage1 = () => {
    apiNetworkCall(RESET_STAGE_1, "post", {
      practiceAreaId: currentPracticeArea?.practiceAreaId,
    }).then((res) => {
      history.push("/app/pa/ws/masterlist");
    });
  };

  let [stage1Valid, setStage1Valid] = useState(true);

  // validating the result that we get for stage 1
  useEffect(() => {
    if (stage1percentage === "100%" && fetching == false) {
      let isStage1Valid = true;
      if (stage1.paras.length >= 2) {
        let count = 0;
        stage1.paras.map((data) => {
          if (data.data.length > 15) {
            count += 1;
          }
        });
        if (count < 2) {
          isStage1Valid = false;
        }
      } else {
        isStage1Valid = false;
      }

      setStage1Valid(isStage1Valid);
    }
  }, [stage1.paras, fetching]);

  if (integrated === false || currentPracticeArea?.stages[2].active === false) {
    return (
      <>
        {/* <Prompt
          when={shouldBlockNavigation}
          message={
            "You will have to restart validation process to get back to this page"
          }
        /> */}
        <LoadingScreen
          msg={"Please wait while we annotate the paragraphs for you!"}
          compleated={
            state2started
              ? getWithoutNan(parseInt(stage2percentage.trim().slice(0, -1)))
              : getWithoutNan(parseInt(stage1percentage.trim().slice(0, -1)))
          }
        />
      </>
    );
  }

  return (
    <>
      {/* <Prompt
        when={shouldBlockNavigation}
        message={
          "You will have to restart validation process to get back to this page"
        }
      /> */}
      <div className={"w-full"} style={{ marginTop: "-25px" }}>
        <div className="flex">
          <div
            style={{
              width: 320,
              marginRight: "15px",
            }}
          >
            <div
              style={{ color: "#7A7A7A", fontSize: 18, marginTop: 10 }}
              className={"text-2xl p-2"}
            >
              Tags
            </div>
            <Divider style={{ padding: 0, margin: 0 }} />
            <br />
            <div
              className={`${style.custom_scroll} space-y-3 p-3 border-r border-gray-200`}
              style={{ maxHeight: "500px" }}
            >
              {nodeLoading === false &&
                stage1.nodes?.map((node) => {
                  return (
                    <StageNode
                      onSelect={() => {
                        setActiveNode(node);
                        setBatch(1);
                      }}
                      key={node._id}
                      active={node.node_id === active_node?.node_id}
                      parent_name={
                        getNodeInfo(node.parent_id, nodes)?.node_name || "root"
                      }
                      node_name={node.node_name}
                      node_id={node.node_id}
                      completed_percentage={node.completed_percentage}
                    />
                  );
                })}
            </div>
            <br />
            <div
              onClick={() => {
                history.push("/app/help_and_guideline");
              }}
              className="flex cursor-pointer items-center space-x-3 bg-white m-3 p-3 rounded-md"
            >
              <img
                width={20}
                src="/img/help_and_guideline.svg"
                alt="help and guideline pic"
              />
              <div style={{ fontSize: 15, color: "#7A7A7A" }}>
                Help & Guidelines
              </div>
            </div>
            {/* <NerSidebar /> */}
          </div>

          <div className={"w-4/5"}>
            <div
              style={{ color: "#7A7A7A", fontSize: 18 }}
              className={"text-2xl p-2 flex justify-between mt-3"}
            >
              <p>Suggested Paragraphs</p>
              <p>
                <span style={{ color: "#11256D" }}>
                  {handleCalculate(stage1.nodes)}/15
                </span>{" "}
                Completed
              </p>
            </div>
            <Divider style={{ padding: 0, margin: 0 }} />

            {stage1Valid === false ? (
              <div className="w-full flex justify-center p-3 text-xl flex-col items-center text-center">
                You don't have enough data for annotation. Please provide
                relevant keywords for the nodes. <br />
                <button
                  onClick={() => {
                    resetStage1();
                  }}
                  className="bg-blueprimary text-white px-3 py-2 mt-6 text-base rounded-md shadow-md"
                >
                  Go to masterlist
                </button>
              </div>
            ) : (
              <div className={` space-y-3 p-3`}>
                <div id={"stage_1_paras"} className={style.para_scroll}>
                  {current_paras && current_paras.length > 0 ? (
                    current_paras?.map((paraMain: any, index) => {
                      //@ts-ignore
                      let para = paraMain.para_info;
                      if (paraMain.accepted === "rejected") return <div></div>;
                      return (
                        <StagePara
                          key={index}
                          active_node_id={active_node?.node_id}
                          accepted={paraMain.accepted}
                          node_name={
                            active_node === undefined
                              ? "loading ..."
                              : active_node?.node_name
                          }
                          para_data={para.paraData}
                          time_str={para.createdAt.toString()}
                          paraId={para.paraId}
                          stage={1}
                          predictedIssueId={
                            para.alpdsInfo.predictedIssueId.stage1
                          }
                          nextPredictedIssueId={
                            para.alpdsInfo?.rankMetric.stage1.value[1][0]
                          }
                          batchId={paraMain.batchId}
                        />
                      );
                    })
                  ) : (
                    <div className={"w-full flex justify-center items-center"}>
                      {fetching === true ? (
                        <img
                          src="/img/loading.svg"
                          alt="loading photo"
                          width={30}
                        />
                      ) : (
                        <div
                          className={"text-lg mt-4 capitalize text-gray-400"}
                        >
                          NO MORE PARAGRAPHS FOR THE SELECTED CLASS
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {seedPara ? (
                  <SeedPara handleAddNewPara={handleAddNewPara} />
                ) : null}
                <div className={"w-4/5 flex justify-between"}>
                  <button
                    className={`flex text-white ${
                      !seedPara ? "px-3 py-2" : "justify-center py-2"
                    } ml-3 mt-3 rounded-md transform hover:scale-105 duration-200`}
                    style={
                      seedPara
                        ? {
                            backgroundColor: "#E63946",
                            fontSize: 15,
                            width: "220px",
                          }
                        : {
                            backgroundColor: "#11256D",
                            fontSize: 15,
                            width: "220px",
                          }
                    }
                    onClick={() => setSeedPara(!seedPara)}
                  >
                    {!seedPara && (
                      <img
                        src="/img/seed_icon.svg"
                        alt="seed para icon"
                        className={"mr-3"}
                      />
                    )}
                    {!seedPara ? "SEED NEW PARAGRAPH" : "CANCEL"}
                  </button>
                  <div className="flex space-x-3">
                    {batch !== 1 && active_node.completed_percentage < 15 && (
                      <button
                        className={`flex duration-200  text-white px-3 py-2 ml-3 mt-3 rounded-md border-2 border-transparent   ${
                          disable
                            ? "bg-gray-400"
                            : "bg-blueprimary hover:text-blueprimary hover:border-blueprimary hover:bg-white"
                        }`}
                        disabled={disable}
                        onClick={() => handleSkipNode()}
                      >
                        SKIP NODE
                      </button>
                    )}
                    <button
                      onClick={() => {
                        if (batch === 3) {
                          toast("No more batches available", { type: "info" });
                          setNotifications([
                            ...notifications,
                            "No more batches available",
                          ]);
                        } else {
                          setBatch(batch + 1);
                        }
                      }}
                      className={
                        "flex duration-200 text-white px-3 py-2 ml-3 mt-3 rounded-md border-2 border-transparent bg-blueprimary hover:border-blueprimary hover:bg-white hover:text-blueprimary"
                      }
                    >
                      LOAD MORE
                    </button>
                  </div>
                </div>
                <Divider />
                <div
                  style={{ fontSize: 18, color: "#7A7A7A" }}
                  className={"mt-3"}
                >
                  Suggest Tags
                </div>
                <Divider />
                <div className="space-y-3">
                  {stage1.rejectedParas &&
                    stage1.rejectedParas.length > 0 &&
                    stage1.rejectedParas.map((para: any, index) => {
                      if (para.curNode) {
                        return (
                          <RejectedPara
                            node_name={para.curNode?.node_name}
                            para_data={para.data?.paraData}
                            suggestedNodeName={para.nextPredicted?.node_name}
                            suggestedNodeParentName={
                              para.nextPredicted?.parent_name
                            }
                            time_str={para.data?.createdAt?.toString()}
                            model_node_list={"sdfjh"}
                            paraId={para.data?.paraId}
                            stage={1}
                            nodeId={para.nextPredicted?.node_id}
                            selected={para.nextPredicted.selected}
                            key={index}
                          />
                        );
                      }
                    })}
                </div>
                <div className="w-full flex justify-end">
                  <button
                    disabled={!isAllowed}
                    title={
                      isAllowed
                        ? ""
                        : "Either complete target of 15 paras per class or validate at least 2 batches for every class"
                    }
                    className={`flex duration-200  text-white px-3 py-2 ml-3 mt-3 rounded-md border-2 border-transparent   ${
                      !isAllowed
                        ? "bg-gray-400"
                        : "bg-blueprimary hover:text-blueprimary hover:border-blueprimary hover:bg-white"
                    }`}
                    onClick={() => {
                      moveToStage2();
                    }}
                  >
                    {allowStage2 === false ? (
                      <img
                        src={"/img/loading_white.svg"}
                        width={30}
                        height={30}
                      />
                    ) : (
                      "MOVE TO STAGE 2"
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Stage1;
