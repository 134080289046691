import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { useHistory, withRouter } from "react-router";
import axios from "axios";
import {
  GET_MODEL_TRAINING_2_DATA,
  PARA_COUNT_URL,
} from "../../../../../../utils/server-urls";
import styles from "./modelTraining2.module.css";
import { apiNetworkCall } from "../../../../../../utils/api";
import { Dropdown, Menu } from "antd";
import { useRecoilState, useRecoilValue } from "recoil";
import { nodesAtom } from "../../../../../../components/masterlist/atoms";
import { StageContext } from "../..";
import { DownOutlined } from "@ant-design/icons";
import { setActive } from "../../../../../../components/workspace-nav/stage-helper";
import { NotificationAtom } from "../../../../../../components/topbar/notificationAtom";
import { toast } from "react-toastify";
import usePracticeArea from "../../../../../../store/zustand/usePracticeArea";

export interface ModelTraining2Props {}
const menu = (
  <Menu>
    <Menu.Item key="1">Version 1</Menu.Item>
    <Menu.Item key="2">Version 2</Menu.Item>
    <Menu.Item key="3">Version 3</Menu.Item>
    <Menu.Item key="3">Version 4</Menu.Item>
  </Menu>
);
export interface Report {
  f1_score: number;
  precision: number;
  recall: number;
  support: number;
  training_paras: number;
  info: string;
}

const ModelTraining2: React.SFC<ModelTraining2Props> = () => {
  const [details, setDetails] = useState({
    Microf1: 0,
    level: 0,
    trainingDocs: 0,
    trainingIssues: 0,
    trainingParas: 0,
    totalDocs: 0,
  });

  let { stages, setStages } = useContext(StageContext);

  let { currentPracticeArea } = usePracticeArea();

  const [showDetailedReport, setShowDetailedReport] = useState<boolean>(false);
  const [detailedReport, setDetailedReport] = useState<Array<Report>>([]);

  let [notifications, setNotifications] = useRecoilState(NotificationAtom);

  useEffect(() => {
    apiNetworkCall(GET_MODEL_TRAINING_2_DATA, "POST", {
      practiceAreaId: currentPracticeArea?.practiceAreaId,
    })
      .then((res: any) => {
        let data: any = res[0].alpdsInfo.trainInfo;

        setDetails({
          Microf1: data.MicroF1,
          level: 2,
          trainingDocs: data.trainingDocs,
          trainingIssues: data.trainingIssues,
          trainingParas: data.trainingParas,
          totalDocs: data.totalDocs,
        });
        handleConcat(res[0].alpdsInfo.classificationReports.nodeReport);
      })
      .catch((err) => {
        toast("No AutoML data available. Train a model and try again", {
          type: "warning",
        });
        setNotifications([
          ...notifications,
          "No AutoML data available. Train a model and try again",
        ]);
        history.replace("/");
      });
  }, []);
  const handleConcat = (reports: any) => {
    let temp: Array<Report> = [];

    Object.entries(reports).map((item: any) => {
      let obj: Report = {
        info: item[0],
        f1_score: item[1]["f1-score"],
        precision: item[1]["precision"],
        recall: item[1]["recall"],
        support: item[1]["support"],
        training_paras: item[1]["training paras"],
      };
      temp.push(obj);
    });

    setDetailedReport(temp);
  };
  const history = useHistory<any>();

  const nodes = useRecoilValue(nodesAtom);

  const toggleDetailedReport = () => {
    setShowDetailedReport(!showDetailedReport);
  };

  let [info, setInfo] = useState({
    totalParas: 0,
    totalTrainingParas: 0,
    totalNoClasses: 0,
    totalDocs: 0,
  });

  // for calculating the infos
  useEffect(() => {
    apiNetworkCall(PARA_COUNT_URL, "post", {
      practiceAreaId: currentPracticeArea?.practiceAreaId,
    })
      .then((data: any) => {
        let totalDocs = data.data.fileUploaded as number;
        let totalParas = data.data.totalPara as number;

        let totalClasses =
          currentPracticeArea?.alpdsInfo?.trainInfo?.trainingIssues || 0;
        let totalTrainingParas =
          currentPracticeArea?.alpdsInfo?.trainInfo?.trainingParas || 0;

        setInfo({
          totalParas: totalParas,
          totalTrainingParas: totalTrainingParas,
          totalNoClasses: totalClasses,
          totalDocs: totalDocs,
        });
      })
      .catch((err) => {
        toast("There was some error during fetching total number of paras", {
          type: "error",
        });
      });
  }, []);

  return (
    <div className="w-full ">
      <div className="w-full flex border-b border-gray-200 py-5">
        <div className="w-1/4 h-full flex flex-col justify-center">
          <div className="text-gray-400 uppercase">Total Paragraphs</div>
          <div className="font-semibold">{info.totalParas}</div>
        </div>
        <div className="w-1/4 h-full flex flex-col justify-center">
          <div className="text-gray-400 uppercase">
            No. of paragraphs validated
          </div>
          <div className="font-semibold">{info.totalTrainingParas}</div>
        </div>
        <div className="w-1/4 h-full flex flex-col justify-center">
          <div className="text-gray-400 uppercase">No. of nodes</div>
          <div className="font-semibold">{info.totalNoClasses}</div>
        </div>
        <div className="w-1/4 h-full flex flex-col justify-center">
          <div className="text-gray-400 uppercase">Total no. of documents</div>
          <div className="font-semibold">{info.totalDocs}</div>
        </div>
      </div>
      {/* <section className={styles.training_section}>
        <img src="/img/training_complete_green.svg" alt="Training Icon" />
        <div>Training complete</div>
        <div className={styles.training_percentage}>100%</div>
      </section> */}
      {/* Training Section */}
      {/* Data Section */}

      {/* Graph Section */}
      <section className={styles.graph_section}>
        <div className="flex w-full justify-between h-full items-center">
          <div
            className={`${styles.detailed_report_btn} hover:shadow-md duration-150 p-3`}
            onClick={() => toggleDetailedReport()}
          >
            {showDetailedReport === false ? "VIEW" : "HIDE"} DETAILED REPORT
          </div>
          <Dropdown overlay={<></>}>
            <div
              className="px-5 flex justify-between items-center rounded-md"
              style={{
                width: "200px",
                height: "30px",
                background: "#EAEAEA",
                fontSize: "15px",
                fontWeight: 400,
                marginTop: -12,
              }}
            >
              <p>Version 1</p>
              <DownOutlined />
            </div>
          </Dropdown>
        </div>
        <div className={styles.graph_container}>
          <div className={styles.graph}>
            <div
              style={{
                width: `${details.Microf1 * 100}%`,
                backgroundColor: details.Microf1 < 0.75 ? "#F59F00" : "#1BA94C",
              }}
            ></div>
          </div>
        </div>
        <div className={"flex justify-between text-xs text-gray-400"}>
          {[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((num) => {
            return <div>{num}</div>;
          })}
        </div>
        <br />
      </section>
      <section className={styles.tip_and_accuracy_score_section}>
        <div className={styles.tip_section}>
          <div className={styles.tip_container}>
            <img src="/img/tip_green.svg" alt="Tip Icon" />
            <div>Tip</div>
          </div>
          <div className={styles.tip_description}>
            If you want to improve the model accuracy, you can click on the
            accuracy of a specific class on the Masterlist and can either upload
            seed paragraphs, a new dataset or review more paragraphs.
          </div>
        </div>
        <div className={styles.accuracy_section}>
          <div className={styles.tip_container}>
            <div>Accuracy Score</div>
          </div>
          <div className={styles.accuracy_percentage}>
            {(details.Microf1 * 100).toFixed(2)}%
          </div>
          <div
            className={`${styles.push_to_platform_btn} hover:bg-white hover:text-primarycolor border-2 border-transparent duration-150 hover:border-primarycolor`}
            onClick={() => {
              setStages(setActive(stages, 5));
              history.push("/app/pa/ws/model-training2/dep");
            }}
          >
            DEPLOYMENT OPTIONS
          </div>
        </div>
      </section>
      {/* Graph Section */}
      {/* Detailed Report Section */}
      {showDetailedReport && (
        <section className={styles.detailed_report_section}>
          <div className={styles.detailed_report_heading}>
            <div>Detailed Report</div>
            <img
              src="/img/detailed_report_green.svg"
              alt="Detailed Report Icon"
            />
          </div>
          <table className={styles.details_table}>
            <tr>
              <th className={"text-left"}>Class</th>
              <th>Precision</th>
              <th>Recall</th>
              <th>F1 Score</th>
              <th>Support</th>
              <th>Training Paras</th>
            </tr>
            {detailedReport.map(
              (
                { info, precision, recall, f1_score, support, training_paras },
                index
              ) => (
                <>
                  {nodes.filter((node) => node.node_id === info)[0]
                    .is_model_class === true && (
                    <tr>
                      <td>
                        {
                          nodes.filter((node) => node.node_id === info)[0]
                            .node_name
                        }
                      </td>
                      <td>{precision}</td>
                      <td>{recall}</td>
                      <td>{f1_score}</td>
                      <td>{support}</td>
                      <td>{training_paras}</td>
                    </tr>
                  )}
                </>
              )
            )}
          </table>
        </section>
      )}
    </div>
  );
};

export default withRouter(ModelTraining2);
