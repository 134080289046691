import create from "zustand";

export interface Log {
  time?: string;
  log: string;
  inFile: string;
  type: string;
  data?: any;
}

// create a interface for the store below
interface LogStore {
  logs: Array<Log>;
  addLog: (log: Log) => void;
  setLogs: (log: Array<Log>) => void;
  socketConnectionStatus: boolean;
  setSocketConnectionStatus: (status: boolean) => void;
  lastPracticeArea: any;
  setLastPracticeArea: (practiceArea: any) => void;
}

// create a zustand store to store system logs
export const useDevLog = create<LogStore>((set) => ({
  logs: [],
  socketConnectionStatus: false,
  setLogs: (logs) => set({ logs }),
  addLog: (log) =>
    set((state) => ({
      logs: [
        ...state.logs,
        {
          ...log,
          time: new Date().toLocaleString(),
        },
      ],
    })),
  setSocketConnectionStatus: (status) =>
    set({ socketConnectionStatus: status }),
  lastPracticeArea: {},
  setLastPracticeArea: (practiceArea) =>
    set({ lastPracticeArea: practiceArea }),
}));

export default useDevLog;
