import { Divider } from "antd";
import * as React from "react";
import { useRecoilState } from "recoil";
import { NotificationAtom } from "../../../../../../components/topbar/notificationAtom";
import { apiNetworkCall } from "../../../../../../utils/api";
import { PREDICT_DS } from "../../../../../../utils/server-urls";
import { Link } from "react-router-dom";
import usePracticeArea from "../../../../../../store/zustand/usePracticeArea";
import { toast } from "react-toastify";

export interface Deploy1Props {}

const Deploy1: React.SFC<Deploy1Props> = () => {
  let [notifications, setNotifications] = useRecoilState(NotificationAtom);

  let [loading, setLoading] = React.useState<boolean>(false);

  let { currentPracticeArea } = usePracticeArea();

  let performPrediction = () => {
    apiNetworkCall(PREDICT_DS, "post", {
      practiceAreaId: currentPracticeArea?.practiceAreaId,
    });
    setNotifications([...notifications, "Prediction data called successfully"]);
  };

  React.useEffect(() => {
    let val =
      currentPracticeArea?.alpdsInfo?.percentageStatus
        ?.predictionOnDatabaseProgress;

    if (val === 0) {
      setLoading(true);
    } else {
      if (loading) {
        toast("Prediction on injested data completed successfully", {
          type: "success",
        });
      }
      setLoading(false);
    }
  }, [
    currentPracticeArea?.alpdsInfo?.percentageStatus
      ?.predictionOnDatabaseProgress,
  ]);

  return (
    <div className="w-full">
      <div className="bg-white rounded-md w-full p-5">
        <p className="font-sans text-base" style={{ color: "#2C2C2C" }}>
          Information about Prediction on Ingested Data
        </p>
        <Divider />
        <div className="font-thin text-base pb-10">
          Perform Prediction on Database paragraphs with trained model.
          Predictions will not be performed on the paragraphs which have been
          validated by user and will remain a part of training dataset. User can
          download the predictions performed on the database with the trained
          algorithm from the{" "}
          <Link
            className={"font-bold"}
            to={"/app/pa/ws/model-training2/dep/export"}
          >
            Export Dataset
          </Link>{" "}
          page. In order to get predictions on new data use the Prediction API
          service.
        </div>
      </div>
      <div className="flex justify-end w-full my-5">
        <button
          onClick={() => performPrediction()}
          className="px-7 duration-300 py-3 flex justify-center text-primarycolor hover:bg-primarycolor hover:text-white text-base rounded-md"
          style={{ border: "1px solid #11256D" }}
        >
          {loading ? (
            <img width={23} src="/img/loading.svg" alt="loading white" />
          ) : (
            "PERFORM PREDICTION"
          )}
        </button>
      </div>
    </div>
  );
};

export default Deploy1;
