import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

/**
 * This is the laoding component which shows progress between two stages
 */

interface LoadingScreenProps {
  msg: string;
  compleated: number;
}

let LoadingScreen: React.SFC<LoadingScreenProps> = ({ msg, compleated }) => {
  return (
    <div className={"w-full h-full"}>
      <div className={"text-center p-12 text-3xl text-gray-400"}>{msg}</div>
      <div className="flex justify-center">
        <div className="p-12 flex relative" style={{ height: 340, width: 340 }}>
          <CircularProgressbar
            styles={buildStyles({
              pathColor: "#11256D",
              textColor: "#11256D",
              textSize: 12,
            })}
            value={compleated}
            text={`${compleated}%`}
          />
          <img
            width={120}
            height={120}
            className="absolute transform translate-x-14 translate-y-14 roundef-full"
            src="/img/progress.svg"
            alt="progress"
          />
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
