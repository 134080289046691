import { Divider } from "antd";
import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../store/reducers";
import {
  REJECT_PARA,
  STAGE1_PROGRESS_UPDATE,
  UPDATE_PARA,
  ACCEPT_PARA as accept_para,
  UPDATE_NODE_FEEDBACK,
} from "../../store/types/stage1-types";
import { apiNetworkCall } from "../../utils/api";
import { ACCEPT_PARA } from "../../utils/server-urls";
import { toast } from "react-toastify";
import { NotificationAtom } from "../topbar/notificationAtom";
import { useRecoilState, useRecoilValue } from "recoil";
import { nodesAtom } from "../masterlist/atoms";
import NerPara from "../ner/NerPara";

export interface StageParaProps {
  node_name: string;
  time_str: string;
  para_data: string;
  paraId: string;
  accepted: null | string;
  active_node_id: string | undefined;
  stage: number;
  predictedIssueId: string;
  nextPredictedIssueId: string;
  batchId: number;
}

const StagePara: React.SFC<StageParaProps> = (props) => {
  const {
    active_node_id,
    node_name,
    time_str,
    para_data,
    paraId,
    accepted,
    stage,
    predictedIssueId,
    nextPredictedIssueId,
  } = props;

  let dispatch = useDispatch();
  let stage1 = useSelector((state: GlobalState) => state.stage1);
  let [expanded, setExpanded] = useState(false);
  let [fade, setFade] = useState(false);
  let [accept, setAccept] = useState<boolean | null>();
  let [notifications, setNotifications] = useRecoilState(NotificationAtom);

  React.useEffect(() => {
    setAccept(accepted === "accepted");
  }, [accepted]);

  let preRejectPara = () => {
    setFade(true);
    setTimeout(rejectPara, 1100);
    setTimeout(() => {
      setFade(false);
    }, 1100);
  };

  let nodes = useRecoilValue(nodesAtom);

  //let []

  let rejectPara = () => {
    //TODO if accepted first then rejected then update the completed percentage

    if (accept !== true) {
      dispatch({
        type: UPDATE_NODE_FEEDBACK,
        payload: {
          node_id: props.active_node_id,
          goal: 0,
          feedback: 1,
        },
      });
    }
    try {
      apiNetworkCall(ACCEPT_PARA, "post", {
        paraId,
        action: "rejected",
        stage,
      });

      let isAccepted = false;
      let completed = 0;
      for (let i = 0; i < stage1.paras.length; i++) {
        if (stage1.paras[i].node_id === active_node_id) {
          for (let j = 0; j < stage1.paras[i].data.length; j++) {
            if (
              stage1.paras[i].data[j].para_info.paraId === paraId &&
              stage1.paras[i].data[j].accepted === "accepted"
            ) {
              isAccepted = true;
              break;
            }
          }
          break;
        }
      }
      for (let i = 0; i < stage1.nodes.length; i++) {
        if (stage1.nodes[i].node_id === active_node_id) {
          completed = stage1.nodes[i].completed_percentage;
        }
      }
      if (isAccepted) {
        dispatch({
          type: STAGE1_PROGRESS_UPDATE,
          payload: {
            node_id: active_node_id,
            data: completed > 0 ? completed - 1 : 0,
          },
        });
      }

      let nodeId = nextPredictedIssueId;

      let node_info = nodes.filter(
        (nodeInfo) => nodeInfo.node_id === nodeId
      )[0];

      if (node_info !== undefined) {
        let node_name = node_info.node_name;
        let parentInfo = nodes.filter(
          (nodeInfo) => nodeInfo.node_id === node_info.parent_id
        )[0];

        let parent_name = "No Parent Name";

        if (parentInfo !== undefined) {
          parent_name = parentInfo.node_name;
        }

        let nextPredicted = {
          node_name,
          parent_name,
          node_id: nodeId,
          selected: null,
        };
        dispatch({
          type: UPDATE_PARA,
          payload: {
            paraId,
            node_id: predictedIssueId,
          },
        });

        setTimeout(() => {
          dispatch({
            type: REJECT_PARA,
            payload: {
              data: { paraData: para_data, createdAt: time_str, paraId },
              nextPredicted,
              curNode: {
                node_name: props.node_name,
              },
            },
          });
        }, 1000);
      }
    } catch (err) {
      toast("Internal Server Error : Paragraph Validation", { type: "error" });
      setNotifications([
        ...notifications,
        "Internal Server Error : Paragraph Validation",
      ]);
    }
  };
  let acceptPara = () => {
    if (accept === true) return;

    dispatch({
      type: UPDATE_NODE_FEEDBACK,
      payload: {
        node_id: props.active_node_id,
        goal: 0,
        feedback: 1,
      },
    });

    if (active_node_id !== undefined) {
      try {
        let params = {
          paraId: paraId,
          action: "accepted",
          stage: stage,
          predictedIssueId: predictedIssueId,
        };

        apiNetworkCall(ACCEPT_PARA, "post", params)
          .then((res) => {})
          .catch((err) => {
            console.error(err);
          });
        dispatch({
          type: accept_para,
          payload: { node_id: active_node_id, paraId: paraId },
        });
        setAccept(true);
        let completed = 0;

        for (let i = 0; i < stage1.nodes.length; i++) {
          if (stage1.nodes[i].node_id === active_node_id) {
            completed = stage1.nodes[i].completed_percentage + 1;
          }
        }

        dispatch({
          type: STAGE1_PROGRESS_UPDATE,
          payload: {
            node_id: active_node_id,
            data: completed,
          },
        });
      } catch (err) {
        toast("Internal Server Error : Paragraph Validation", {
          type: "error",
        });

        setNotifications([
          ...notifications,
          "Internal Server Error : Paragraph Validation",
        ]);
      }
    }
  };

  return (
    <div
      key={paraId}
      className={`w-full flex my-5 transition-all ease-out  duration-1000 ${
        fade ? "opacity-0" : ""
      }`}
    >
      <div
        className={"w-4/5 bg-white "}
        style={{ borderRadius: 4, padding: "15px 25px" }}
      >
        <div className="flex justify-between">
          <div>
            <div className="font-medium" style={{ fontSize: 15 }}>
              {node_name}
            </div>
            <div style={{ fontSize: 9, color: "#9A9A9A" }}>{time_str}</div>
          </div>
          <div className="flex space-x-6 cursor-pointer">
            <img
              className="cursor-pointer transform duration-100 hover:scale-110"
              src="/img/copy_squares.svg"
              alt="copy icon"
              onClick={() => {
                navigator.clipboard.writeText(para_data);
                toast("Data copied", { type: "success" });
                setNotifications([...notifications, "Para data copied"]);
              }}
            />
            <img
              className="cursor-pointer transform duration-100 hover:scale-110"
              src="/img/expand_arrows.svg"
              onClick={() => setExpanded(!expanded)}
              alt="expand icon"
            />
          </div>
        </div>
        <Divider />

        <NerPara paraData={para_data} paraId={paraId} expanded={expanded} />

        {/* <div className="flex flex-wrap">
          {expanded === true ? para_data : para_data.slice(0, 250)}
        </div> */}
        {/* {expanded === true ? para_data : para_data.slice(0, 250)} */}
      </div>
      <div className={"w-1/5 flex justify-center items-center space-x-3"}>
        <img
          onMouseEnter={(e) => {
            //@ts-ignore
            e.target.src = "/img/gridicons_cross-circle.svg";
          }}
          onMouseLeave={(e) => {
            //@ts-ignore
            e.target.src = "/img/circle_cross.svg";
          }}
          className="cursor-pointer mr-3"
          width={20}
          src={
            fade ? "/img/gridicons_cross-circle.svg" : "/img/circle_cross.svg"
          }
          alt="circle cross"
          onClick={() => preRejectPara()}
        />
        <img
          onMouseEnter={(e) => {
            //@ts-ignore
            e.target.src = "/img/para_accept_blue.svg";
          }}
          onMouseLeave={(e) => {
            if (accepted === null || accepted === "rejected") {
              //@ts-ignore
              e.target.src = "/img/circle_tick.svg";
            } else {
              //@ts-ignore
              e.target.src = "/img/para_accept_blue.svg";
            }
          }}
          className="cursor-pointer ml-3"
          width={20}
          onClick={() => {
            acceptPara();
          }}
          src={
            accepted === null || accepted === "rejected"
              ? "/img/circle_tick.svg"
              : "/img/para_accept_blue.svg"
          }
          alt="circle tick"
        />
      </div>
    </div>
  );
};

export default StagePara;
