import { Divider } from "antd";
import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRecoilState, useRecoilValue } from "recoil";
import { GlobalState } from "../../store/reducers";
import { apiNetworkCall } from "../../utils/api";
import { ACCEPT_PARA } from "../../utils/server-urls";
import { nodesAtom } from "../masterlist/atoms";
import { NotificationAtom } from "../topbar/notificationAtom";
import { toast } from "react-toastify";
import {
  FEEDBACK_ACCEPT_PARA,
  FEEDBACK_ADD_REJECT_PARA,
} from "../../store/types/feedback.types";

export interface StageParaProps {
  node_name: string | null;
  time_str: string;
  para_data: string;
  paraId: string;
  accepted: null | string;
  active_node_id: string | null;
  stage: number;
  predictedIssueId: string;
  nextPredictedIssueId: string;
}

const FeedbackPara: React.SFC<StageParaProps> = (props) => {
  const {
    active_node_id,
    node_name,
    time_str,
    para_data,
    paraId,
    accepted,
    stage,
    predictedIssueId,
    nextPredictedIssueId,
  } = props;
  let dispatch = useDispatch();
  let feedback = useSelector((state: GlobalState) => state.feedback);
  let [expanded, setExpanded] = useState(false);
  const [fade, setFade] = useState(false);

  let [notifications, setNotifications] = useRecoilState(NotificationAtom);

  let preRejectPara = () => {
    setFade(true);
    setTimeout(rejectPara, 1100);
    setTimeout(() => {
      setFade(false);
    }, 1100);
  };

  let nodes = useRecoilValue(nodesAtom);
  let rejectPara = () => {
    //TODO if accepted first then rejected then update the completed percentage
    try {
      apiNetworkCall(ACCEPT_PARA, "post", {
        paraId,
        action: "rejected",
        stage,
      });

      let nodeId = nextPredictedIssueId;

      let node_info = nodes.filter(
        (nodeInfo) => nodeInfo.node_id === nodeId
      )[0];

      if (node_info !== undefined) {
        let node_name = node_info.node_name;
        let parentInfo = nodes.filter(
          (nodeInfo) => nodeInfo.node_id === node_info.parent_id
        )[0];

        let parent_name = "No Parent Name";

        if (parentInfo !== undefined) {
          parent_name = parentInfo.node_name;
        }

        let nextPredicted = {
          node_name,
          parent_name,
          node_id: nodeId,
        };
        let cur_rejected_paras = feedback.rejected_paras;
        let cur_paras = feedback.current_paras;
        cur_paras = cur_paras.filter((para: any) => para.paraId !== paraId);
        let obj = {
          data: { paraData: para_data, createdAt: time_str, paraId },
          nextPredicted,
          curNode: {
            node_name: props.node_name,
          },
        };
        cur_rejected_paras.push(obj);
        setTimeout(() => {
          dispatch({
            type: FEEDBACK_ADD_REJECT_PARA,
            payload: {
              rejected_paras: cur_rejected_paras,
              current_paras: cur_paras,
            },
          });
        }, 1000);
      }
    } catch (err) {
      toast("Internal Server Error : Paragraph Validation", { type: "error" });
      setNotifications([
        ...notifications,
        "Internal Server Error : Paragraph Validation",
      ]);
    }
  };
  let acceptPara = () => {
    //if (accept === true) return;

    if (active_node_id !== undefined) {
      try {
        let params = {
          paraId: paraId,
          action: "accepted",
          stage: 2,
          predictedIssueId: predictedIssueId,
        };

        apiNetworkCall(ACCEPT_PARA, "post", params)
          .then((res) => {})
          .catch((err) => {
            console.error(err);
          });

        dispatch({
          type: FEEDBACK_ACCEPT_PARA,
          payload: {
            paraId: paraId,
          },
        });
      } catch (err) {
        toast("Internal Server Error : Paragraph Validation", {
          type: "error",
        });
      }
    }
  };

  return (
    <div
      className={`w-full flex my-5 transition-all ease-out duration-1000 ${
        fade ? "opacity-0" : ""
      }`}
    >
      <div
        className={"w-4/5 bg-white "}
        style={{ borderRadius: 4, padding: "15px 25px" }}
      >
        <div className="flex justify-between">
          <div>
            <div className="font-medium" style={{ fontSize: 15 }}>
              {node_name}
            </div>
            <div style={{ fontSize: 9, color: "#9A9A9A" }}>{time_str}</div>
          </div>
          <div className="flex space-x-6">
            <img
              className="cursor-pointer duration-100 hover:scale-110"
              src="/img/copy_squares.svg"
              alt="copy icon"
              onClick={() => {
                navigator.clipboard.writeText(para_data);
                toast("Paragraph data copied", { type: "success" });
                setNotifications([...notifications, "Para data copied"]);
              }}
            />
            <img
              className="cursor-pointer duration-100 hover:scale-110"
              src="/img/expand_arrows.svg"
              onClick={() => setExpanded(!expanded)}
              alt="expand icon"
            />
          </div>
        </div>
        <Divider />
        {expanded === true ? para_data : para_data.slice(0, 250)}
      </div>
      <div className={"w-1/5 flex justify-center items-center space-x-3"}>
        <img
          onMouseEnter={(e) => {
            //@ts-ignore
            e.target.src = "/img/gridicons_cross-circle.svg";
          }}
          onMouseLeave={(e) => {
            //@ts-ignore
            e.target.src = "/img/circle_cross.svg";
          }}
          className="cursor-pointer mr-3"
          width={20}
          src={
            fade ? "/img/gridicons_cross-circle.svg" : "/img/circle_cross.svg"
          }
          alt="circle cross"
          onClick={() => preRejectPara()}
        />
        <img
          onMouseEnter={(e) => {
            //@ts-ignore
            e.target.src = "/img/para_accept_blue.svg";
          }}
          onMouseLeave={(e) => {
            if (accepted === null || accepted === "rejected") {
              //@ts-ignore
              e.target.src = "/img/circle_tick.svg";
            } else {
              //@ts-ignore
              e.target.src = "/img/para_accept_blue.svg";
            }
          }}
          className="cursor-pointer ml-3"
          width={20}
          onClick={() => acceptPara()}
          src={
            accepted === null || accepted === "rejected"
              ? "/img/circle_tick.svg"
              : "/img/para_accept_blue.svg"
          }
          alt="circle tick"
        />
      </div>
    </div>
  );
};

export default FeedbackPara;
