import React, { useState } from "react";
import { Switch } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import useCurrentUser from "../../../store/zustand/useCurrentUser";

interface ProfileProps {}

const Profile: React.FC<ProfileProps> = () => {
  let [edit, setEdit] = React.useState(false);
  let [checked, setChecked] = useState(false);
  let { user } = useCurrentUser();
  let [vis1, setVis1] = useState(false);
  let [vis2, setVis2] = useState(false);
  let [vis3, setVis3] = useState(false);

  return (
    <div className="flex w-full justify-between space-x-5">
      <div className="flex flex-col space-y-5 w-3/5">
        <div
          className="bg-white px-5 py-5"
          style={{ boxShadow: "0px 0px 8px rgba(122, 122, 122, 0.15)" }}
        >
          <p
            className="pb-2"
            style={{
              fontSize: "15px",
              lineHeight: "18px",
              color: "#11256D",
              fontWeight: 500,
            }}
          >
            Your details
          </p>
          <p
            className="py-2"
            style={{ fontSize: "15px", lineHeight: "18px", color: "#7A7A7A" }}
          >
            Your email address is used to sign in and in case we need to contact
            you.
          </p>
          <div className="lg:w-3/5 md:w-4/5 mt-5" style={{ fontSize: "15px" }}>
            <div className="flex">
              <div>
                <p className="mb-3" style={{ fontWeight: 500 }}>
                  First Name
                </p>
                <input
                  className="px-5 py-2 bg-gray-100 rounded-md outline-none"
                  type="text"
                  value={user?.firstName}
                  style={{ width: "250px", border: "1px solid #C4C4C4" }}
                ></input>
              </div>
              <div className="mx-8">
                <p className="mb-3" style={{ fontWeight: 500 }}>
                  Last Name
                </p>
                <input
                  className="px-5 py-2 bg-gray-100 rounded-md outline-none"
                  type="text"
                  value={user?.lastName}
                  style={{ width: "250px", border: "1px solid #C4C4C4" }}
                ></input>
              </div>
            </div>
            <div className="mt-5">
              <p className="mb-3" style={{ fontWeight: 500 }}>
                Email Address
              </p>
              <div className="flex">
                {edit ? (
                  <input
                    className="px-5 py-2 bg-gray-100 rounded-md outline-none"
                    type="text"
                    style={{ width: "250px", border: "1px solid #C4C4C4" }}
                    value={user?.email}
                    autoFocus
                  ></input>
                ) : (
                  <input
                    className="px-5 py-2 bg-gray-100 rounded-md outline-none cursor-not-allowed"
                    type="text"
                    value={user?.email}
                    style={{ width: "250px", border: "1px solid #C4C4C4" }}
                    disabled
                  ></input>
                )}
                <p
                  className="ml-2 cursor-pointer mt-2 underline"
                  style={{ color: "#11256D" }}
                  onClick={() => setEdit(!edit)}
                >
                  Edit
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="bg-white px-5 py-5"
          style={{ boxShadow: "0px 0px 8px rgba(122, 122, 122, 0.15)" }}
        >
          <p
            className="pb-2"
            style={{
              fontSize: "15px",
              lineHeight: "18px",
              color: "#11256D",
              fontWeight: 500,
            }}
          >
            Your Password
          </p>
          <div className="lg:w-3/5 md:w-4/5 mt-5" style={{ fontSize: "15px" }}>
            <div className="mt-3">
              <p className="mb-3">Enter Your Current Password</p>
              <div
                className="bg-gray-100 rounded-md outline-none flex items-center"
                style={{ width: "250px", border: "1px solid #C4C4C4" }}
              >
                <input
                  type={`${!vis1 ? "password" : "text"}`}
                  className="px-5 py-2 w-9/10 bg-gray-100 outline-none"
                ></input>
                <div onClick={() => setVis1(!vis1)}>
                  {vis1 ? (
                    <EyeTwoTone className="cursor-pointer" />
                  ) : (
                    <EyeInvisibleOutlined className="cursor-pointer" />
                  )}
                </div>
              </div>
            </div>
            <div
              className="flex mt-5"
              style={{ fontSize: "15px", fontWeight: 500 }}
            >
              <div>
                <p className="mb-3">Enter Your New Password</p>
                <div
                  className="bg-gray-100 rounded-md outline-none flex items-center"
                  style={{ width: "250px", border: "1px solid #C4C4C4" }}
                >
                  <input
                    type={`${!vis2 ? "password" : "text"}`}
                    className="px-5 py-2 w-9/10 bg-gray-100 outline-none"
                  ></input>
                  <div onClick={() => setVis2(!vis2)}>
                    {vis2 ? (
                      <EyeTwoTone className="cursor-pointer" />
                    ) : (
                      <EyeInvisibleOutlined className="cursor-pointer" />
                    )}
                  </div>
                </div>
              </div>
              <div className="mx-8">
                <p className="mb-3">Re-enter Your New Password</p>
                <div
                  className="bg-gray-100 rounded-md outline-none flex items-center"
                  style={{ width: "250px", border: "1px solid #C4C4C4" }}
                >
                  <input
                    type={`${!vis3 ? "password" : "text"}`}
                    className="px-5 py-2 w-9/10 bg-gray-100 outline-none"
                  ></input>
                  <div onClick={() => setVis3(!vis3)}>
                    {vis3 ? (
                      <EyeTwoTone className="cursor-pointer" />
                    ) : (
                      <EyeInvisibleOutlined className="cursor-pointer" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 flex w-full justify-end">
            <div
              className="px-5 py-2 text-white rounded-md cursor-pointer"
              style={{ background: "#11256D", fontSize: "14px" }}
            >
              Change Password
            </div>
          </div>
        </div>
      </div>
      <div className="w-2/6">
        <div
          className="bg-white px-5 py-5 w-full"
          style={{
            boxShadow: "0px 0px 8px rgba(122, 122, 122, 0.15)",
            fontSize: "15px",
          }}
        >
          <p className="mb-2" style={{ fontWeight: 500, color: "#11256D" }}>
            Multi-Factor Authentication
          </p>
          <p className="my-5">
            Add an extra layer of security by setting up email authentication
            when logging in.
          </p>
          <div className="flex">
            <Switch
              onChange={() => setChecked(!checked)}
              style={{ background: `${checked ? "#11256D" : ""}` }}
            />
            <p className="mx-5" style={{ fontWeight: 500 }}>
              Email Authentication Enabled
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
