import * as React from 'react';
import styles from '../../pages/app/practice-area/pa/stage-1/stage1.module.css';
export interface SeedParaProps {
    handleAddNewPara:any,
}
 
const SeedPara: React.SFC<SeedParaProps> = (props) => {
    const [newPara, setNewPara]=React.useState({
        newParaTitle:'',
        newParaData:'',
    })

    const handleChange=(e:any)=>{
        setNewPara({...newPara, [e.target.name]:e.target.value});
    }

    return ( 
        <div className={styles.right_section_container} style={{width:'80%'}}>
            <div className={styles.right_section_container_heading}>
              <input
                className={styles.edit_title}
                placeholder=" Edit Title"
                onChange={handleChange}
                value={newPara.newParaTitle}
                name="newParaTitle"
             />
              <button
                className={styles.submit_btn}
                style={
                  newPara.newParaTitle.length > 0 && newPara.newParaData.length > 0
                    ? { backgroundColor: '#11256d', color: '#fff' }
                    : {}
                }
                onClick={e=>props.handleAddNewPara(newPara)}
                disabled={!(newPara.newParaTitle.length > 0 && newPara.newParaData.length > 0)}
              >
                SUBMIT
              </button>
            </div>
            <textarea
              placeholder="Type your paragraph here ..."
              className={styles.para_text_area}
              onChange={handleChange}
              value={newPara.newParaData}
              name="newParaData"
            />
        </div>
     );
}
 
export default SeedPara;