import React, { useState } from "react";
import { useHistory } from "react-router";
import { saveAs } from "file-saver";

let HelpAndGuideline: React.FC = () => {
  let [current, setCurrent] = useState<number>(0);

  let help_data = [
    {
      key: 0,
      title: "CREATE SCOPE",
      component: (
        <div style={{ width: "70vw" }}>
          <div className="text-xl font-bold">CREATE SCOPE</div>
          <p className={"mt-3"}>
            Users can select any practice area(s) unique to their organizations
            and then start building the attributes around it. Masterlist can be
            set up for any practice area or any domain that you are working on.
          </p>
          <br />
          To add a new Practice Area, follow these steps:
          <br />
          <div className="ml-3">
            1. Select ‘Create New Scope’ <br />
            2. Type in the Scope Name <br />
            3. Type in the Scope Acronym <br />
            4. Select 'Create Scope'
          </div>
        </div>
      ),
    },
    {
      key: 1,
      title: "FILE UPLOAD",
      component: (
        <div style={{ width: "70vw" }}>
          <div className="text-xl font-bold">FILE UPLOAD</div>
          <br />
          <p>
            The user can upload files containg the paragraphs that will be
            annotated/ labeled by the DataNeuron ALP platform. The User can
            upload a file by clicking the upload button and selecting it. The
            progress bar displays the file upload status.
          </p>
          <div className="mt-2">
            <br />
            The user can upload their data sources following different formats:
            <br />
            <br />
          </div>
          <div>
            <div className="flex justify-end">
              <div style={{ width: "97%" }}>
                <ul className={"list-outside list-decimal px-6"}>
                  <li className={"list-item"}>
                    <b> CSV</b> - A “.csv” file with one column of paragraph
                    text and one row containing one paragraph. CSV file should
                    not contain any header.
                  </li>
                  <li className={"list-item"}>
                    <b>TEXT</b> - A (.txt) file with multiple paragraphs
                    separated by a single blank line.
                  </li>
                  <li className={"list-item"}>
                    <b>ZIP</b> - A “.zip” file containing multiple txt files,
                    each with one paragraph. The compressed folder must have the
                    extension “.zip”.
                  </li>
                  <li className={"list-item"}>
                    Files larger than 100 MB are not permitted.
                  </li>
                  <li>
                    “Utf-8" encoding is assumed. <br />
                  </li>
                </ul>
              </div>
            </div>
            <br />
            <button
              onClick={() => {
                let starting_link =
                  process.env.NODE_ENV !== "production"
                    ? "http://localhost:3000"
                    : "https://portal.dataneuron.ai";
                saveAs(starting_link + "/doc/Template_csv_file.csv");
                saveAs(starting_link + "/doc/Template_For_Zip_File.zip");
                saveAs(starting_link + "/doc/Template_Text_File.txt");
              }}
              className={
                "bg-primarycolor duration-200 text-white rounded-md mt-3 rouned-md px-3 py-2 hover:bg-white hover:text-primarycolor border-2 border-transparent hover:border-primarycolor"
              }
            >
              Download Template
            </button>
          </div>
        </div>
      ),
    },
    {
      key: 2,
      title: "DEFINE MASTERLIST",
      component: (
        <div style={{ width: "70vw" }}>
          <div className="text-xl font-bold">DEFINE MASTERLIST</div>
          <div className="text-lg my-3">Defining Class Taxonomy</div>

          <p>
            Users can define any class hierarchy or taxonomy that will be
            included in the Masterlist for the scope they have created. Users
            are only permitted to create one Masterlist per scope. The
            Masterlist can be created up to any level.
          </p>
          <div className="mt-3">
            Example of a two-level masterlist is shown below. Users can define
            each node to their specific use case or problem statement.
          </div>
          <img src="/img/help_1.png" alt="help pic" />
          <div className="mt-3">
            Single Level of Masterlist can be created if taxonomy or hierarchy
            is not available.
          </div>
          <div className="font-bold text-xl mt-3">Adding Keywords</div>
          <div className="mt-3">
            The user must enter keywords for each model node in the Masterlist.
            The keywords essentially aid in better understanding of the class.
            The keywords are only relevant for model nodes, but users can define
            them for all nodes. More keywords assist the algorithm in annotating
            a broader range of paragraphs. More keywords assist the algorithm in
            annotating a broader range of paragraphs. Furthermore, the keyword
            must be associated with the class in a unique way.
          </div>
          <div className="mt-3">
            To speed up the learning process for the algorithms or to gain a
            better understanding of each class defined at the Masterlist level,
            it is recommended that the user add some keywords that define the
            class along with the section or rule numbers.
          </div>
          <div className="">
            <div className="ml-3">
              <ul className={"list-outside list-decimal p-3"}>
                <li className={"list-item"}>
                  Node Name: The name of the class/label that the user wishes to
                  define as a Masterlist building block.
                </li>
                <li>
                  Model Node: The node that will be used to classify paragraphs.
                </li>

                <li>Non-Model Node: The node that will not be classified.</li>
                <li>Root Node: The node at the top of the hierarchy.</li>
                <li>
                  Leaf Node: The last node of each branch when the Masterlist is
                  viewed as a tree structure.
                </li>
                <li>
                  Node Accuracy: The accuracy of each node will be visible only
                  after the Training is completed. It should be noted that
                  accuracy is initially computed only for model nodes.
                  Estimation is used to obtain accuracy at non-model parent
                  nodes.
                </li>
              </ul>
            </div>
            <div className="font-bold text-lg mt-3">
              Features of the Masterlist:
            </div>

            <ul className={"list-decimal list-outside p-6"}>
              <li className="list-item">
                Select as Model Class Button: This is a button that allows you
                to select a node as a model node/ class.
              </li>
              <li className="list-item">
                Delete Node Button: If a node/ class is no longer required, it
                can be deleted. It should be noted that when a node is deleted,
                all of its child nodes are also deleted.
              </li>

              <li className="list-item">
                No-Tag Toggle Button: This button allows you to enable or
                disable No-Tag. The No-Tag feature also allows you to handle
                out-of-scope paragraphs. If the user is confident that their
                database contains no out-of-scope paragraphs, they can disable
                the No-Tag option.
              </li>
            </ul>
          </div>
        </div>
      ),
    },
    {
      key: 3,
      title: "STAGE 1 VALIDATION",
      component: (
        <div style={{ width: "70vw" }}>
          <div className="font-bold text-xl">STAGE 1 VALIDATION</div>
          <div className="mt-3">
            The user will be presented with a set of paragraphs that must be
            validated. ALP will identify the paragraphs based on the Masterlist
            and provide the relevant paragraphs for validation in Stage 1, user
            needs to validate (~15 paras / class).
          </div>
          <div className="mt-3">
            The user can select the checkmark against the paragraph if the
            paragraph belongs to the predicted class. In case the prediction is
            wrong for a paragraph, the user can even suggest the correct class.
            <i>
              (It is highly recommended to the user to suggest the correct class
              at this level to expedite the process and get good results)
            </i>
          </div>
          <div className="mt-3">
            At this stage, the paragraphs are available for user validation
            class-wise, i.e., paragraphs are loaded for one class at a time. The
            user can click on the ‘Load More’ option to get more paragraphs for
            validation
          </div>
        </div>
      ),
    },
    {
      key: 4,
      title: "STAGE 2 VALIDATION",
      component: (
        <div style={{ width: "70vw" }}>
          <div className="text-xl font-bold">STAGE 2 VALIDATION</div>
          <div className="mt-3">
            Stage 2 will be generated based on our ensemble model (which is a
            context-filtering and semi-supervised based model) to provide higher
            accuracy at Stage 2. The user must validate whether or not the
            paragraph belongs to the annotated class.
          </div>
          <div className="mt-3">
            At this point, the paragraphs are ready for user validation in
            batches, i.e., paragraphs are loaded for a batch that will be a mix
            of multiple classes defined at the Masterlist level. To proceed to
            the next batch for validation, the user can select the ‘Load More’
            option
          </div>
          <div className="mt-3">
            In other words, before moving on to the next batch in Stage 2, the
            user must complete the validation for all of the classes in the
            current batch.
          </div>
        </div>
      ),
    },
    {
      key: 5,
      title: "SUMMARY PAGE",
      component: (
        <div style={{ width: "70vw" }}>
          <div className="text-xl font-bold">VALIDATION SUMMARY</div>
          <div className="mt-3">
            After Batch 1 of Stage 2, the summary page will appear. The summary
            page directs the user towards improving overall project accuracy.
          </div>
          <div className="mt-6 font-lg">
            The summary page directs the user towards improving overall project
            accuracy. <br /> <br /> Summary Page Elements: <br /> <br />
          </div>
          <div className="ml-3">
            <div className={"flex"}>
              <div>1. </div>
              <div>
                <b>Model Node Info:</b> The user will see a list of model nodes,
                as well as the number of paragraphs validated for each node up
                to that point.
              </div>
            </div>
            <div className={"flex"}>
              <div>2. </div>
              <div>
                <b>Color Code :</b> Each model node has three different colour
                codes that indicate different levels of progress, as shown
                below:
                <br />
                <div className="ml-3">
                  <span style={{ color: "#1BA94C", fontWeight: 700 }}>
                    Green :
                  </span>{" "}
                  indicates that the model nodes have been validated with a
                  sufficient number of paragraphs and accuracy.
                  <br />
                  <span style={{ color: "#F59F00", fontWeight: 700 }}>
                    Yellow :
                  </span>{" "}
                  indicates that the model nodes have been partially validated
                  using a moderate number of paragraphs.
                  <br />
                  <span style={{ color: "#E63946", fontWeight: 700 }}>
                    Red :&nbsp;
                  </span>
                  indicates that the model node has been validated
                  insufficiently with only a few paragraphs and will be excluded
                  from the the model training
                  <br />
                </div>
                NOTE : Before beginning the Model Training, the user must ensure
                that none of the model nodes are “Red.” All red-colored nodes
                will be excluded from the training.
                <br />
              </div>
            </div>
            <div className={"flex"}>
              <div>3. </div>
              <div>
                <b>Choose Merge:</b> If the user determines that there are no
                more paragraphs available to validate for a specific node, the
                user can merge that node with any of the nodes that have the
                same parent node.
                <br />
                NOTE: After merging, the two classes will become one, and their
                parameters such as keywords and paragraphs will be part of the
                new merged class.
                <br />
              </div>
            </div>
            <div className={"flex"}>
              <div>4. </div>
              <div>
                <b>Deselect for Classification :</b> Users can deselect any node
                from classification, this also means that the class will be
                marked as a non model class. <br />
              </div>
            </div>
            <div className={"flex"}>
              <div>5. </div>
              <div>
                <b> Move on to Training </b>: After completing the validation in
                stage 2, users can move to the Model Training.
                <br />
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: 6,
      title: "MODEL TRAINING",
      component: (
        <div style={{ width: "70vw" }}>
          <div className="font-bold text-xl">MODEL TRAINING</div>
          <div className="mt-3">
            ALP trains on dozens of algorithms and selects the best one
            automatically. At this point, the tool will generate a detailed
            report on the masterlist and data training, which the user can use
            to improve classes that have low accuracy.
          </div>
          <div className="mt-3">
            The following information will be included in the report:
          </div>
          <div className="font-bold">1 ) Class-specific detailed metrics:</div>
          <div className="ml-3">
            - Precision: this metric indicates how many of all paragraphs
            labelled as belonging to class A by algorithm actually belonged to
            class A. <br />
            - Recall: this metric tells you how many paragraphs the algorithm
            labelled as class A out of all the paragraphs that are truly class
            A.
            <br />
            - F1: The F1 measure provides a single score that balances precision
            and recall in a single value.
            <br />
          </div>
          <div className="font-bold mt-3">
            2 ) Training paragraph per class:{" "}
          </div>
          <div className="ml-3">
            - The number of paragraphs used to train a specific class is
            indicated by the training paragraph per class. The general trend is
            that algorithms will learn better with a higher number of
            paragraphs.
            <br />
            - If a class has a lower F1 score and a lower number of training
            paragraphs, it indicates that the user should try to validate more
            paragraphs for that class. <br />
          </div>
          <div className="mt-3">
            Following that, the user can deploy the model using a ready to
            consume API, run predictions on the ingested data or work on
            improving accuracy in the Feedback & Review stage.
          </div>
        </div>
      ),
    },
    {
      key: 7,
      title: "FEEDBACK & REVIEW",
      component: (
        <div style={{ width: "70vw" }}>
          <div className="text-xl font-bold">FEEDBACK & REVIEW</div>
          <div className="mt-3">
            To improve an accuracy score, the user can choose any class on the
            Masterlist. The user can improve the accuracy of the class that have
            low results by doing the following:
          </div>
          <div className="ml-3">
            1. Uploading seed paras for the specific class. <br />
            2. Uploading additional data for the relevant classes. <br />
            3. Validate more paragraphs. <br />
          </div>
          <div className="my-3 font-bold">Seed Paragraph</div>
          <div>
            The user can manually add or upload paragraphs to a class to
            increase its training size, which will help the class’s accuracy.
          </div>
          <div className="mt-3">
            Please keep in mind that the seed paragraphs will be used directly
            for training and will not require validation by the user.
          </div>
          <div className="my-3 font-bold">Upload Source</div>
          <div>
            The user can upload documents related to the specific issues to
            increase the size of the training set, which will help to improve
            the accuracy of that class. Please keep in mind that the seed
            paragraphs will be used directly for training and will not require
            validation by the user.
          </div>
          <div className="my-3 font-bold">Review More</div>
          <div>
            The user can validate more paragraphs from the automatically
            annotated database for specific classes.
          </div>
          <div className="mt-3">
            NOTE : In case the database does not contain documents related to a
            specific class, it is suggested to the user to first upload
            documents pertaining to that class and then start the validation
            process.
          </div>
        </div>
      ),
    },
  ];

  let history = useHistory();

  return (
    <div className={"p-12"}>
      <div>
        <div className={"flex items-center"}>
          <svg
            onClick={() => {
              history.goBack();
            }}
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.625 10.5L4.375 10.5"
              stroke="#C4C4C4"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.5 16.625L4.375 10.5L10.5 4.375"
              stroke="#C4C4C4"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div className={"my-3 ml-6"}>
            <div className="text-gray-300">ALP/</div>
            <div className={"text-primarycolor text-lg"}>Help & Guidelines</div>
          </div>
        </div>
      </div>
      <div className={"flex w-full"}>
        <div style={{ width: 400 }}>
          {/* <div className="text-gray-500 mb-2">Topics</div>
          <div className="flex  text-green-600">
            <div className={"bg-green-500 w-2"}></div>{" "}
            <div className="px-3 flex-grow py-4 border-t-2 border-b-2 border-gray-100">
              Practice Area Selection
            </div>
          </div>
          <div className="p-6">
            <div className="p-3">Defining Masterlist</div>
            <div className="pt-2 hover:text-green-600 cursor-pointer">
              - &nbsp;&nbsp;&nbsp; Define class toxonomy
            </div>
            <div className="pt-2 hover:text-green-600 cursor-pointer">
              - &nbsp;&nbsp;&nbsp; Add keywords for the class taxonomy
            </div>
            <div className="pt-2 hover:text-green-600 cursor-pointer">
              - &nbsp;&nbsp;&nbsp; Add provision details, if applicable
            </div>
           
          </div> */}
          <div
            onClick={() => setCurrent(0)}
            className={`text-lg p-3 border-l-4 border-transparent hover:border-primarycolor cursor-pointer ${
              current === 0 && "border-primarycolor"
            }`}
          >
            1. Create Scope
          </div>
          <div
            onClick={() => setCurrent(1)}
            className={`text-lg p-3 border-l-4 border-transparent hover:border-primarycolor cursor-pointer ${
              current === 1 && "border-primarycolor"
            }`}
          >
            2. File Upload
          </div>
          <div
            onClick={() => setCurrent(2)}
            className={`text-lg p-3 border-l-4 border-transparent hover:border-primarycolor cursor-pointer ${
              current === 2 && "border-primarycolor"
            }`}
          >
            3. Define Masterlist
          </div>
          <div>
            <div
              onClick={() => setCurrent(3)}
              className={`text-lg p-3 border-l-4 border-transparent hover:border-primarycolor cursor-pointer ${
                current === 3 && "border-primarycolor"
              }`}
            >
              4. Stage 1 Validation
            </div>
          </div>
          <div>
            <div
              onClick={() => setCurrent(4)}
              className={`text-lg p-3 border-l-4 border-transparent hover:border-primarycolor cursor-pointer ${
                current === 4 && "border-primarycolor"
              }`}
            >
              5. Stage 2 Validation
            </div>
          </div>
          <div>
            <div
              onClick={() => setCurrent(5)}
              className={`text-lg p-3 border-l-4 border-transparent hover:border-primarycolor cursor-pointer ${
                current === 5 && "border-primarycolor"
              }`}
            >
              6. Validation Summary
            </div>
          </div>
          <div
            onClick={() => setCurrent(6)}
            className={`text-lg p-3 border-l-4 border-transparent hover:border-primarycolor cursor-pointer ${
              current === 6 && "border-primarycolor"
            }`}
          >
            7. Model Training
          </div>
          <div
            onClick={() => setCurrent(7)}
            className={`text-lg p-3 border-l-4 border-transparent hover:border-primarycolor cursor-pointer ${
              current === 7 && "border-primarycolor"
            }`}
          >
            8. User Feedback
          </div>
        </div>
        <div className={"flex-grow text-lg"}>
          {help_data[current].component}

          {help_data[current + 1] !== undefined && (
            <div
              onClick={() => setCurrent(current + 1)}
              className={
                "border-t-2 flex items-center justify-between border-gray-200 px-2 py-3 mt-12"
              }
            >
              <div>
                <div className="text-gray-400 ">NEXT</div>
                <div className={"text-lg font-bold"}>
                  {help_data[current + 1].title}
                </div>
              </div>
              <div>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.25 10H18.75"
                    stroke="#11256D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10 1.25L18.75 10L10 18.75"
                    stroke="#11256D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HelpAndGuideline;
