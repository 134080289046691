import { reverse } from "lodash";
import * as React from "react";
import ReactApexChart from "react-apexcharts";

export interface InfoGraphProps {
  active: number;
  accuracy: number;
  acronym: String;
  data: Array<any>;
  setCard: any;
}

const InfoGraph: React.SFC<InfoGraphProps> = (props) => {
  const { data, active } = props;
  let colors: any = [];
  const projects = [];
  let factor = 100 / data.length;
  for (let i = 0; i < data.length; i++) {
    if (i === active) {
      let total = factor;
      let colored = (props.accuracy / 100) * total;
      if (colored === 0) colored = 1;
      projects.push(colored);
      let rest = total - colored;
      projects.push(rest);
    } else {
      projects.push(factor);
    }
  }

  // React.useEffect(()=>{

  // }, [])
  let temp = [];
  let codes = [
    "#686868",
    "#707070",
    "#808080",
    "#909090",
    "#A0A0A0",
    "#A9A9A9",
    "#B8B8B8",
    "#C0C0C0",
    "#D0D0D0",
    "#D8D8D8",
    "#E0E0E0",
    "#F0F0F0",
  ];
  reverse(codes);

  let cur = 0;
  for (let idx = 0; idx < projects.length; idx++) {
    if (idx === active) {
      if (props.accuracy < 75) {
        temp.push("#F59F00");
      } else {
        temp.push("#1BA94C");
      }
      // temp.push("#DCDCDC");
      temp.push("#FFF");
      idx++;
    } else {
      // let val=rgba2hex(`rgba(${initValue}, ${initValue}, ${initValue}, 0.5)`);
      // initValue+=dFactor;
      // if(data.length>7){
      //     temp.push("#A3A3A3")
      // }else{
      //     temp.push(codes[cur++]);
      // }
      if (cur >= codes.length) {
        temp.push("#505050");
      } else {
        temp.push(codes[cur++]);
      }
    }
  }
  colors = temp;

  const options = {
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          labels: {
            show: true,
            name: {
              show: false,
              fontSize: "20px",
              color: "#11256D",
              backrgoundColor: "#fff",
              formatter: function () {
                return ``;
              },
            },
            value: {
              show: true,
              fontSize: "16px",
              color: "#11256D",
              formatter: function () {
                return `${props.accuracy}%`;
              },
            },
            total: {
              showAlways: true,
              show: true,
              fontSize: "16px",
              color: "#11256D",
              formatter: function () {
                if (props.accuracy === 0) {
                  return `${props.acronym} Not Trained`;
                }
                return `${props.accuracy}% accuracy in ${props.acronym}`;
              },
            },
          },
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    toolbar: {
      enabled: false,
    },
    stroke: {
      colors: ["#0000000"],
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
      active: {
        filter: {
          type: "none" /* none, lighten, darken */,
          value: 0,
        },
      },
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: false,
            position: "bottom",
          },
        },
      },
    ],
    colors: colors,
  };
  return (
    <div>
      {data.length !== 0 && (
        <ReactApexChart
          height={450}
          series={projects}
          options={options}
          type="donut"
        />
      )}
      {data.length === 0 && (
        <div className="flex justify-center items-center mt-12">
          <div
            className={
              "w-96 h-96 rounded-full flex justify-center items-center bg-gray-300"
            }
          >
            <div className="w-72 rounded-full h-72 flex items-center justify-center text-primarycolor bg-gray-50">
              NO PRACTICE AREA FOUND
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default InfoGraph;

const colors: any = {};
colors.names = {
  gainsboro: "#DCDCDC",
  lightgray: "#D3D3D3",
  silver: "#C0C0C0",
  darkgray: "#A9A9A9",
  gray: "#808080",
  dimgray: "#696969",
  lightslategray: "#778899",
  slategray: "#708090",
  darkslategray: "#2F4F4F",
};
colors.random = function () {
  var result;
  var count = 0;
  for (let name in colors.names) if (Math.random() < 1 / ++count) result = name;
  return result;
};
// function rgba2hex(rgba: any) {
//   rgba = rgba.match(
//     /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
//   );
//   return rgba && rgba.length === 4
//     ? "#" +
//         ("0" + parseInt(rgba[1], 10).toString(16)).slice(-2) +
//         ("0" + parseInt(rgba[2], 10).toString(16)).slice(-2) +
//         ("0" + parseInt(rgba[3], 10).toString(16)).slice(-2)
//     : "";
// }
// #DCDCDC for progress
// #A3A3A3
