import React, { useEffect, useState } from "react";
import Profile from "./profile";
import BillingInfo from "./billingInfo";
import Usage from "./usage";
import AccessManagement from "./accessManagement";
import { useLocation } from "react-router-dom";

const Admin: React.FC = () => {
  let [active, setActive] = useState(0);
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const paramField = query.get("create-billing-acc");

  useEffect(() => {
    if (paramField) {
      setActive(parseInt(paramField));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full">
      <div className="my-14 ml-8">
        <h1
          style={{
            fontSize: "40px",
            fontFamily: "Roboto",
            lineHeight: "47px",
            fontWeight: 500,
          }}
        >
          Account Summary
        </h1>
      </div>
      <div className="w-full">
        <div className="w-full">
          <div
            className="flex justify-around"
            style={{ width: "46%", fontSize: "16px" }}
          >
            <p
              className="cursor-pointer p-3"
              style={{
                color: `${active === 0 ? "#11256D" : "#000"}`,
                borderBottom: active === 0 ? "3px solid #11256D" : "none",
              }}
              onClick={() => setActive(0)}
            >
              Usage
            </p>
            <p
              className="cursor-pointer p-3"
              style={{
                color: `${active === 1 ? "#11256D" : "#000"}`,
                borderBottom: active === 1 ? "3px solid #11256D" : "none",
              }}
              onClick={() => setActive(1)}
            >
              Billing Information
            </p>
            <p
              className="cursor-pointer p-3"
              style={{
                color: `${active === 2 ? "#11256D" : "#000"}`,
                borderBottom: active === 2 ? "3px solid #11256D" : "none",
              }}
              onClick={() => setActive(2)}
            >
              Your Information
            </p>
            <p
              className="cursor-pointer p-3"
              style={{
                color: `${active === 3 ? "#11256D" : "#000"}`,
                borderBottom: active === 3 ? "3px solid #11256D" : "none",
              }}
              onClick={() => setActive(3)}
            >
              Access Management
            </p>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: "1px",
            background: "#C4C4C4",
            marginBottom: 20,
            transform: "translateY(-1.5px)",
          }}
        ></div>
        <div style={{ margin: "0 25px 0 40px" }}>
          {active === 1 && <BillingInfo />}
        </div>
        <div style={{ margin: "0 40px" }}>{active === 2 && <Profile />}</div>
        <div style={{ margin: "0 25px 0 40px" }}>
          {active === 0 && <Usage />}
        </div>
        <div style={{ margin: "0 25px 0 40px" }}>
          {active === 3 && <AccessManagement />}
        </div>
      </div>
    </div>
  );
};
export default Admin;
