import { Divider, Select } from "antd";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRecoilState } from "recoil";
import { GlobalState } from "../../store/reducers";
import {
  STAGE2_PROGRESS_UPDATE,
  STAGE2_UPDATE_REJECT_PARA,
} from "../../store/types/stage-2-types";
import {
  REJECT_PARA,
  STAGE1_PROGRESS_UPDATE,
  UPDATE_REJECT_PARA,
} from "../../store/types/stage1-types";
import { apiNetworkCall } from "../../utils/api";
import { NotificationAtom } from "../topbar/notificationAtom";
import { toast } from "react-toastify";
export interface RejectedParaProps {
  suggestedNodeName: string;
  suggestedNodeParentName: string;
  time_str: string;
  para_data: string;
  model_node_list: string;
  node_name: string;
  stage: number;
  paraId: string;
  nodeId: string;
  selected: any;
}

const RejectedPara: React.SFC<RejectedParaProps> = (props) => {
  const [selected, setSelected] = React.useState<number | null>(props.selected);
  let stage1 = useSelector((state: GlobalState) => state.stage1);
  let stage2 = useSelector((state: GlobalState) => state.stage2);
  let dispatch = useDispatch();

  let acceptPara = (val: any) => {
    // TODO do something about change in progress when switched from nextpredicted to No tag
    if (props.stage === 1 && val !== "No Tag") {
      let completed = 0;
      let isAccepted = false;
      for (let i = 0; i < stage1.rejectedParas.length; i++) {
        if (
          stage1.rejectedParas[i].data.paraId === props.paraId &&
          stage1.rejectedParas[i].nextPredicted.issueId === props.nodeId
        ) {
          isAccepted = true;
        }
      }
      if (!isAccepted) {
        for (let i = 0; i < stage1.nodes.length; i++) {
          if (stage1.nodes[i].node_id === props.nodeId) {
            completed = stage1.nodes[i].completed_percentage;
            dispatch({
              type: STAGE1_PROGRESS_UPDATE,
              payload: {
                node_id: props.nodeId,
                data: completed + 1,
              },
            });
            break;
          }
        }
      }
    } else if (val !== "No Tag") {
      let completed = 0;
      let goal = 0;
      let isAccepted = false;
      for (let i = 0; i < stage2.rejectedParas.length; i++) {
        if (
          stage2.rejectedParas[i].data.paraId === props.paraId &&
          stage2.rejectedParas[i].nextPredicted.issueId === props.nodeId
        ) {
          isAccepted = true;
        }
      }
      if (!isAccepted) {
        for (let i = 0; i < stage2.nodes.length; i++) {
          if (stage2.nodes[i].node_id === props.nodeId) {
            completed = stage2.nodes[i].completed_percentage;
            goal = stage2.nodes[i].goal;
            dispatch({
              type: STAGE2_PROGRESS_UPDATE,
              payload: {
                node_id: props.nodeId,
                data: goal === completed ? goal : completed + 1,
              },
            });
            break;
          }
        }
      }
    } else if (props.stage === 1) {
      if (selected === 1) {
        let completed = 0;
        for (let i = 0; i < stage1.nodes.length; i++) {
          if (stage1.nodes[i].node_id === props.nodeId) {
            completed = stage1.nodes[i].completed_percentage;
            dispatch({
              type: STAGE1_PROGRESS_UPDATE,
              payload: {
                node_id: props.nodeId,
                data: completed === 0 ? 0 : completed - 1,
              },
            });
            break;
          }
        }
      }
    } else if (props.stage === 2) {
      if (selected === 1) {
        let completed = 0;
        for (let i = 0; i < stage2.nodes.length; i++) {
          if (stage2.nodes[i].node_id === props.nodeId) {
            completed = stage2.nodes[i].completed_percentage;
            dispatch({
              type: STAGE2_PROGRESS_UPDATE,
              payload: {
                node_id: props.nodeId,
                data: completed === 0 ? 0 : completed - 1,
              },
            });
            break;
          }
        }
      }
    }
    if (val === "No Tag") {
      setSelected(0);
    } else {
      setSelected(1);
    }

    if (props.stage === 1) {
      dispatch({
        type: UPDATE_REJECT_PARA,
        payload: {
          paraId: props.paraId,
          issueId: val,
          selected,
        },
      });
    } else {
      dispatch({
        type: STAGE2_UPDATE_REJECT_PARA,
        payload: {
          paraId: props.paraId,
          issueId: val,
          selected,
        },
      });
    }

    apiNetworkCall(REJECT_PARA, "post", {});

    //TODO update the frontend also
  };

  let [notifications, setNotifications] = useRecoilState(NotificationAtom);
  let [expanded, setExpanded] = React.useState(false);

  return (
    <div style={{ width: "95%" }} className={"flex ml-6"}>
      <div
        className={"w-9/12 bg-white "}
        style={{ borderRadius: 4, padding: "15px 25px" }}
      >
        <div className="flex justify-between">
          <div>
            <div className="font-medium" style={{ fontSize: 15 }}>
              {props.node_name}
            </div>
            <div style={{ fontSize: 9, color: "#9A9A9A" }}>NOV 2020</div>
          </div>
          <div className="flex space-x-6">
            <img
              className="cursor-pointer transform duration-100 hover:scale-110"
              onClick={() => {
                navigator.clipboard.writeText(props.para_data);
                toast("Paragraph data copied", {
                  type: "success",
                });

                setNotifications([...notifications, "Paragraph data copied"]);
              }}
              src="/img/copy_squares.svg"
              alt="copy icon"
            />
            <img
              onClick={() => setExpanded(!expanded)}
              src="/img/expand_arrows.svg"
              alt="expand icon"
              className="cursor-pointer transform duration-100 hover:scale-110"
            />
          </div>
        </div>
        <Divider />
        {expanded ? props.para_data : props.para_data.slice(0, 250)}
      </div>
      <div className={"w-64 flex justify-center w-"}>
        <div className="ml-3 w-full">
          <div
            style={{ fontSize: 15 }}
            className="flex justify-between cursor-pointer ml-3 font-medium "
          >
            {props.stage === 2 ? (
              <Select
                onChange={(value) => {
                  acceptPara(value);
                }}
                defaultValue={"No Tag"}
                className={"rounded-md w-64"}
                size={"large"}
              >
                <Select.Option key={"No Tag"} value={"No Tag"}>
                  No tag
                </Select.Option>
                {stage2.nodes.map((node) => {
                  return (
                    <Select.Option key={node.node_id} value={node.node_id}>
                      {node.node_name}
                    </Select.Option>
                  );
                })}
              </Select>
            ) : (
              <Select
                onChange={(value) => {
                  acceptPara(value);
                }}
                defaultValue={"No Tag"}
                className={" rounded-md w-64"}
                size={"large"}
              >
                <Select.Option key={"No Tag"} value={"No Tag"}>
                  No tag
                </Select.Option>
                {stage1.nodes.map((node, i) => {
                  return (
                    <Select.Option key={node.node_id} value={node.node_id}>
                      {node.node_name}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          </div>
          <div
            style={{ borderRadius: 4 }}
            className="flex justify-between cursor-pointer ml-3  mt-3 bg-white p-3"
            onClick={() => acceptPara(props.nodeId)}
          >
            <div>
              <div style={{ color: "#9A9A9A", fontSize: 12 }}>
                {props.suggestedNodeParentName}
              </div>
              <div style={{ fontSize: 15 }} className={"w-full font-medium"}>
                {props.suggestedNodeName.slice(0, 25)}...
              </div>
            </div>
            {(selected === 0 || selected === null) && (
              <img width={30} src="/img/circle_tick.svg" alt="circle tick" />
            )}
            {selected === 1 && (
              <img
                width={30}
                src="/img/para_accept_blue.svg"
                alt="circle tick"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RejectedPara;
