import { Divider } from "antd";
import * as React from "react";
import { useState } from "react";
import { apiNetworkCall } from "../../../../../../utils/api";
import { GENERATE_TOKEN } from "../../../../../../utils/server-urls";
import { Modal, Input } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import { NotificationAtom } from "../../../../../../components/topbar/notificationAtom";
import { toast } from "react-toastify";

const { confirm } = Modal;
export interface Deploy3Props {}

const Deploy3: React.SFC<Deploy3Props> = () => {
  function showConfirm() {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: "On Regenerating keys your previous key will stop working!",
      onOk() {
        generateToken();
      },
      onCancel() {},
      centered: true,
      okText: "Proceed",
    });
  }

  let [passConVisible, setPassVisible] = useState(false);

  let [token, setToken] = useState("");
  let [notifications, setNotifications] = useRecoilState(NotificationAtom);

  let [password, setPassword] = useState("");

  let validateGenerateToken = () => {
    if (token !== "") {
      showConfirm();
    } else {
      generateToken();
    }
  };

  let generateToken = () => {
    if (password !== "") {
      apiNetworkCall(GENERATE_TOKEN, "POST", {
        password,
      })
        .then((data: any) => {
          setToken(data.token);
        })
        .catch((err) => {
          toast("Internal Server Error : Token generation", {
            type: "error",
          });
          setNotifications([
            ...notifications,
            "Internal Server Error : Token generation",
          ]);
        });
      setPassVisible(false);
    } else {
      alert("Please enter a password");
    }
  };

  return (
    <div className="w-full">
      <div className="bg-white rounded-md w-full p-5">
        <p className="font-sans text-base" style={{ color: "#2C2C2C" }}>
          Prediction API and PyPi Support
        </p>
        <Divider />
        <p
          className="font-sans font-normal text-lg pb-10"
          style={{ color: "#000000", lineHeight: "21px" }}
        >
          Perform Predictions at real time using our API and PyPI package on new
          data using your trained model. Refer to api documentation to know the
          exact steps of Performing Prediction and deploying our algorithm to
          your application. Use the below API token for secure authentication
          process.
        </p>
      </div>
      <div className="flex mt-10 w-full">
        <input
          //   onMouseEnter={(e) => {
          //     navigator.clipboard.writeText(token);
          //     notification.success({
          //       message: "Copied to clipboard",
          //     });
          //   }}
          disabled
          value={token}
          onChange={(e) => setToken(e.target.value)}
          type="text"
          className="px-5 cursor-pointer py-3 border border-black bg-transparent rounded-tl-md rounded-bl-md w-9/12"
        ></input>
        <div
          onClick={() => {
            navigator.clipboard.writeText(token);
            toast("Copied successfully", { type: "success" });
          }}
          className={"border-b border-t border-blueprimary p-3 cursor-pointer"}
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 8H10C8.89543 8 8 8.89543 8 10V19C8 20.1046 8.89543 21 10 21H19C20.1046 21 21 20.1046 21 19V10C21 8.89543 20.1046 8 19 8Z"
              stroke="#7A7A7A"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4 14H3C2.46957 14 1.96086 13.7893 1.58579 13.4142C1.21071 13.0391 1 12.5304 1 12V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H12C12.5304 1 13.0391 1.21071 13.4142 1.58579C13.7893 1.96086 14 2.46957 14 3V4"
              stroke="#7A7A7A"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <button
          onClick={() => setPassVisible(true)}
          className="px-7 w-56 py-3 text-blueprimary border border-blueprimary text-base rounded-tr-md rounded-br-md"
        >
          REGENERATE KEY
        </button>
      </div>
      <Modal
        visible={passConVisible}
        onOk={() => generateToken()}
        onCancel={() => setPassVisible(false)}
      >
        Please enter your password <br />
        <Input
          onChange={(e) => setPassword(e.target.value)}
          type={"password"}
          placeholder={"Password"}
        />
      </Modal>
    </div>
  );
};

export default Deploy3;
