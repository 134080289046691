import React, { useEffect } from "react";
import useCurrentUser from "../../../store/zustand/useCurrentUser";
import PricingModal from "./modal";
interface BillingInfoProps {}

const BillingInfo: React.FC<BillingInfoProps> = () => {
  let [show, setShow] = React.useState(false);
  let { user } = useCurrentUser();

  useEffect(() => {
    if (!user?.billingAccountId) {
      setShow(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="w-full">
      <div className="w-full flex justify-between">
        <div className="flex flex-col space-y-5 w-3/5">
          <div
            className="px-3 py-3 bg-white"
            style={{
              color: "#11256D",
              fontWeight: 500,
              fontSize: "18px",
              width: "190px",
              boxShadow: "0px 0px 8px rgba(122, 122, 122, 0.15)",
            }}
          >
            Billing ID: AB2U172
          </div>
          <div
            className="px-5 py-5 bg-white"
            style={{ boxShadow: "0px 0px 8px rgba(122, 122, 122, 0.15)" }}
          >
            <p style={{ fontWeight: 500, color: "#11256D", fontSize: "15px" }}>
              Subscription Level
            </p>
            <p className="my-3" style={{ fontWeight: 500, fontSize: "25px" }}>
              On Demand
            </p>
            <div className="flex justify-between">
              <p style={{ color: "#7A7A7A", fontSize: "15px" }}>
                Monthly Subscription. Learn more about our different
                subscriptions.
              </p>
              <div
                onClick={() => setShow(true)}
                className="px-5 py-1 text-white rounded-md cursor-pointer"
                style={{ background: "#11256D", fontSize: "14px" }}
              >
                Update Subscription
              </div>
            </div>
          </div>
          <div
            className="px-5 py-5 bg-white"
            style={{ boxShadow: "0px 0px 8px rgba(122, 122, 122, 0.15)" }}
          >
            <p style={{ fontWeight: 500, color: "#11256D", fontSize: "15px" }}>
              Next Billing Date
            </p>
            <p className="my-3" style={{ fontWeight: 500, fontSize: "25px" }}>
              01/08/2021
            </p>
            <div className="flex justify-between">
              <p style={{ color: "#7A7A7A", fontSize: "15px" }}>
                Ending in 9182
              </p>
              <div
                className="px-5 py-1 text-white rounded-md cursor-pointer"
                style={{ background: "#11256D", fontSize: "14px" }}
              >
                Update Billing Information
              </div>
            </div>
          </div>
          <div
            className="px-5 py-5 bg-white"
            style={{ boxShadow: "0px 0px 8px rgba(122, 122, 122, 0.15)" }}
          >
            <p style={{ fontWeight: 500, color: "#11256D", fontSize: "15px" }}>
              Your Billing History
            </p>
            <p className="mt-3" style={{ fontWeight: 500, fontSize: "15px" }}>
              Most Recent Invoice:
            </p>
            <p
              style={{ fontSize: "25px", fontWeight: 500 }}
              className="underline"
            >
              AB2U172_20/07/2021
            </p>
            <div className="flex justify-end">
              <div
                className="px-5 py-1 text-white rounded-md cursor-pointer"
                style={{ background: "#11256D", fontSize: "14px" }}
              >
                View Full Billing History
              </div>
            </div>
          </div>
        </div>
        <div className="w-2/6" style={{ marginTop: "75px" }}>
          <div
            className="bg-white px-5 py-5 w-full"
            style={{
              boxShadow: "0px 0px 8px rgba(122, 122, 122, 0.15)",
              fontSize: "15px",
            }}
          >
            <p className="mb-2" style={{ fontWeight: 500, color: "#11256D" }}>
              Frequently Asked Questions About Billing
            </p>
            <p className="my-5">Question 1</p>
            <p className="my-5">Question 1</p>
            <p className="my-5">Question 1</p>
          </div>
        </div>
      </div>
      <PricingModal show={show} setShow={setShow} />
    </div>
  );
};

export default BillingInfo;
