import * as React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import MasterPracticeAreaLayout from "../../../components/layouts/master-pa-layout";
import { GlobalState } from "../../../store/reducers";
import usePracticeArea from "../../../store/zustand/usePracticeArea";
import Feedback from "../feedback";
import Masterlist from "./masterlist";
import PracticeArea from "./pa";

const Workspace: React.FC = () => {
  let { currentPracticeArea } = usePracticeArea();

  useEffect(() => {}, []);

  if (currentPracticeArea === null) {
    return <Redirect to={"/"} />;
  }

  return (
    <MasterPracticeAreaLayout>
      <Switch>
        <Route
          exact
          path={"/app/pa"}
          render={() => <Redirect to={"/app/pa/ws"} />}
        />
        <Route path={"/app/pa/masterlist"} component={Masterlist} />
        <Route path={"/app/pa/update"} component={Feedback} />
        <Route path={"/app/pa/ws"} component={PracticeArea} />
        <Route render={() => <Redirect to={"/not-found"} />} />
      </Switch>
    </MasterPracticeAreaLayout>
  );
};

export default Workspace;
