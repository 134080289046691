import * as React from "react";
import { IoMdNotificationsOutline } from "react-icons/io";
import { VscSignOut } from "react-icons/vsc";
import { Popover } from "antd";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { NotificationAtom } from "./notificationAtom";
import { FiSmile } from "react-icons/fi";
import useCurrentUser from "../../store/zustand/useCurrentUser";
import { FaDiagnoses } from "react-icons/fa";

export interface TopBarProps {}

let AvatarPopout = () => {
  //let history = useHistory();

  let signOutUser = () => {
    localStorage.removeItem("userInfo");
    window.location.reload();
  };

  /**
   *
   *
   * Will take the user to the admin page
   */
  // let accountClick = () => {
  //   history.push("/app/admin");
  // };

  return (
    <div>
      {/* <div
        onClick={accountClick}
        className="flex justify-between px-3 py-2 space-x-3 cursor-pointer hover:bg-gray-200"
      >
        <VscSignOut size={26} />
        <span>Account Summary</span>
      </div> */}
      <div
        onClick={signOutUser}
        className="flex justify-between px-3 py-2 space-x-3 cursor-pointer hover:bg-gray-200"
      >
        <VscSignOut size={26} />
        <span>Log out</span>
      </div>
      {/* <div className="flex justify-between px-3 py-2 space-x-3 cursor-pointer hover:bg-gray-200">
				<RiSettings2Line size={26} />
				<span>Account Settings</span>
			</div> */}
    </div>
  );
};

let NotificationPopout = () => {
  let notifications = useRecoilValue(NotificationAtom);

  return (
    <div
      style={{ maxHeight: 600, overflowY: "scroll" }}
      className={"custom_scroll"}
    >
      {notifications.length === 0 ? (
        <div className={"flex flex-col items-center"}>
          <div>
            <FiSmile size={26} />
          </div>
          <div className={"text-lg text-center"}>
            It's all clear here. <br /> You don't have any notifications
          </div>
        </div>
      ) : (
        notifications.map((noti) => {
          return (
            <div
              style={{ maxWidth: 340 }}
              className="flex items-center text-lg px-3 py-2 space-x-3 cursor-pointer hover:bg-gray-200"
            >
              <div className="pr-3">
                <IoMdNotificationsOutline size={36} />
              </div>
              <div className={"flex-grow text-center"}>{noti}</div>
            </div>
          );
        })
      )}
    </div>
  );
};

const TopBar: React.SFC<TopBarProps> = () => {
  let { user } = useCurrentUser();

  return (
    <div
      id={"topbar"}
      className={
        "fixed top-0 left-0 right-0 z-20 w-full flex justify-between bg-white items-center"
      }
    >
      <Link to={"/app/dashboard"}>
        <div
          style={{
            fontSize: 24,
            padding: "17px 24px",
            color: "#7A7A7A",
          }}
        >
          <div className={"flex items-center"}>
            <img src="/img/gray_logo1.svg" alt="logo" />
          </div>
        </div>
      </Link>
      <div className={"flex space-x-6 mr-12 items-center"}>
        {/* {process.env.NODE_ENV === "development" && (
          <Link to={"/app/devlog"}>
            <div>
              <FaDiagnoses size={24} />
            </div>
          </Link>
        )} */}
        <Link to={"/app/help_and_guideline"}>
          <div>
            <img src="/img/help_black.svg" alt="help and guidelines" />
          </div>
        </Link>
        {/* <div
					className={
						"bg-pink-600 space-x-2 text-white font-medium rounded-full flex justify-center items-center px-3 py-2"
					}
				>
					<span>12:34</span>
					<img src="/img/clock_icon.svg" alt="clock icon" width={20} />
				</div> */}
        <div>
          <Popover placement={"bottom"} content={NotificationPopout}>
            <IoMdNotificationsOutline size={30} />
          </Popover>
        </div>
        {/* <div>
					<img src="/img/analatics_logo.svg" alt="analatics logo" />
				</div> */}
        <div className="flex items-center justify-center">
          <Popover placement={"bottom"} content={AvatarPopout}>
            <img src="/img/user.png" width={28} alt="avatar temp" />
          </Popover>
          <div style={{ fontSize: 18 }} className={" text-gray-500 ml-3"}>
            {user?.firstName}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
