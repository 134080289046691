import * as React from "react";
import Loader from "../loader";
import useMasterlist from "./hooks/useMasterlist";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useEffect } from "react";
import { layersAtom, masterlistIdAtom, nodesAtom } from "./atoms";
import Layer from "./layer";
import { calculateFirstLayer, injestProperties } from "./util/helper";
import Toolbar from "./toolbar";
import usePracticeArea from "../../store/zustand/usePracticeArea";
import { toast } from "react-toastify";
import { apiNetworkCall } from "../../utils/api";
import {
  MARK_MASTERLIST_LOCKED,
  STAGE_1_INTEGRATE,
} from "../../utils/server-urls";
import { NotificationAtom } from "../topbar/notificationAtom";
import { useHistory } from "react-router";
import ReactGA from "react-ga";
import ReactSwipe from "react-swipezor";

interface Check {
  allowed: boolean;
  messages: Array<string>;
}

const Masterlist: React.FC = () => {
  let { loading, data, error } = useMasterlist();
  let [layers, setLayers] = useRecoilState(layersAtom);
  let setMasterlist = useSetRecoilState(masterlistIdAtom);
  let { currentPracticeArea } = usePracticeArea();
  let [nodes, setNodes] = useRecoilState(nodesAtom);
  let [notifications, setNotifications] = useRecoilState(NotificationAtom);
  let history = useHistory();
  let [startAnnoEnabled, setStartAnnoEnabled] = React.useState(true);

  useEffect(() => {
    if (loading === false && error === undefined && data !== undefined) {
      let injestedNodes = injestProperties(data.nodes);
      setMasterlist(data);
      setLayers([calculateFirstLayer(injestedNodes)]);
      setNodes(injestedNodes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data, error]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <div>
        There was some issue during fetching the masterlist. Please try again
        after sometime.
      </div>
    );
  }

  let allowToStartStage1 = (): Check => {
    let allowed = true;
    let messages = [];

    let modelNodes = nodes.filter(
      (nodeInfo) => nodeInfo.is_model_class === true
    );
    let leafNodes = nodes.filter((nodeInfo) => nodeInfo.is_leaf_node === true);

    if (modelNodes.length > 100 || modelNodes.length < 2) {
      allowed = false;
      messages.push(
        "The number of model nodes should be more 1 and should be less than 101"
      );
    }

    if (leafNodes.length > 100) {
      allowed = false;
      messages.push("The number of leaf nodes should not be greater than 100");
    }

    //TODO : The number of paras should ne n ^ 2 times the number of modal nodes (n)

    return { allowed, messages };
  };

  let lockMasterlist = async () => {
    setStartAnnoEnabled(false);

    // let res: any = await apiNetworkCall(CHECK_MASTERLIST, "post", {
    //   practiceAreaId: currentPracticeArea?.practiceAreaId,
    // });

    // let isValid = res.body.isValid as boolean;

    // if (isValid === true) {
    try {
      let check = allowToStartStage1();
      let lockMasterlist: any = await apiNetworkCall(
        MARK_MASTERLIST_LOCKED,
        "post",
        {
          practiceAreaId: currentPracticeArea?.practiceAreaId,
          markAs: check.allowed,
        }
      );
      if (lockMasterlist.result) {
        if (check.allowed === true) {
          await apiNetworkCall(STAGE_1_INTEGRATE, "post", {
            practiceAreaId: currentPracticeArea?.practiceAreaId,
          })
            .then((res) => {
              toast("Stage 1 Auto Annotation started successfully", {
                type: "success",
              });
              setNotifications([
                ...notifications,
                "Stage 1 Auto Annotation started successfully",
              ]);
            })
            .catch((err) => {
              setStartAnnoEnabled(true);
              toast("Internal Server Error : Stage 1 Auto Annotation", {
                type: "error",
              });
              setNotifications([
                ...notifications,
                "Internal Server Error : Stage 1 Auto Annotation",
              ]);
            });

          history.push("/app/pa/ws/stage1");
        } else {
          setStartAnnoEnabled(true);
          check.messages.forEach((msg) => {
            toast(msg, { type: "warning" });
          });
        }
      } else {
        console.error(lockMasterlist);
      }
    } catch (err) {
      console.error(err);
    }
    // } else {
    //   setStartAnnoEnabled(true);
    //   hide();
    //   Modal.info({
    //     title: "Please check your masterlist",
    //     content:
    //       "The keywords provided are not relevant to the data you have uploaded. Provide relevant keywords to proceed to the next stage.",
    //   });
    // }
  };

  return (
    <div>
      <Toolbar />
      <div
        id={"masterlist_holder"}
        className="flex min-h-screen transform -translate-y-8 p-3 overflow-x-scroll m-3"
      >
        {layers.map((layer) => {
          return <Layer layerInfo={layer} key={layer.id} />;
        })}
      </div>

      <div
        className={`text-white py-3 px-8 cursor-${
          currentPracticeArea?.masterlistLocked && startAnnoEnabled
            ? "not-allowed"
            : "pointer"
        } uppercase shadow-md rounded-md`}
        style={{
          position: "fixed",
          bottom: "40px",
          right: "60px",
          background: currentPracticeArea?.masterlistLocked
            ? "rgb(172, 172, 172)"
            : "#11256D",
          fontSize: "15px",
          fontWeight: 400,
          boxShadow: "1px 1px 15px rgb(172, 172, 172)",
        }}
        onClick={() => {
          if (
            currentPracticeArea &&
            currentPracticeArea.masterlistLocked === false &&
            startAnnoEnabled === true
          ) {
            lockMasterlist();
          } else {
            toast(
              "Masterlist is locked. Please create a new practice area to define new masterlist",
              {
                type: "info",
              }
            );
          }
        }}
      >
        {startAnnoEnabled ? (
          "Start Auto Annotation"
        ) : (
          <img width={30} height={30} src={"/img/loading_white.svg"} />
        )}
      </div>
    </div>
  );
};

export default Masterlist;
