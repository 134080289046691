import * as React from "react";
import { useState } from "react";
import { useHistory, withRouter, useParams } from "react-router-dom";
import { Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { BASE_URL } from "../../utils/server-urls";
import { toast } from "react-toastify";

interface Credentials {
  email: string;
  password: string;
  rePassword: string;
}

const SetNewPass: React.FC = () => {
  const history = useHistory();
  const [credentials, setCredentials] = useState<Credentials>({
    email: "",
    password: "",
    rePassword: "",
  });
  const [error, setError] = useState({
    emailErr: "",
    passErr: "",
    captchaErr: "",
  });
  const [captchaDone, setCaptchaDone] = useState<boolean>(false);
  const { email } = useParams<{ email?: string }>();

  React.useEffect(() => {
    return () => {};
  }, []);

  //On Input change
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCredentials({
      ...credentials,
      [event.target.name]: event.target.value,
    });
  };

  //On form submit
  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (email && email.length && error.emailErr === "") {
      if (captchaDone === true) {
        if (credentials.password === credentials.rePassword) {
          axios
            .post(BASE_URL + "/user/updatePassword", {
              email,
              password: credentials.password,
            })
            .then((res) => {
              if (res.data.status === 200) {
              } else {
                toast("Email doesn't exist", {
                  type: "error",
                });
              }
            })
            .catch(() => toast("Invalid email!", { type: "error" }));
        } else {
          toast("Passwords doesn't match!", { type: "warning" });
        }
      } else {
        toast("Please complete the captcha and try again", { type: "warning" });
      }
    } else {
      toast("Invalid email", { type: "warning" });
    }
  };

  const verifyCaptcha = () => {
    setError({ ...error, captchaErr: "" });
    setCaptchaDone(true);
  };

  return (
    <div className="w-100 h-screen flex justify-center items-center">
      <form
        style={{
          marginBottom: "120px",
        }}
        className="lg:w-3/5 md:w-4/5 xs:w-9/10"
        onSubmit={handleSubmit}
      >
        <div
          style={{
            marginBottom: "50px",
          }}
          className={"text-4xl font-medium w-100"}
        >
          Set Your New Password
        </div>
        <div
          style={{
            marginBottom: "30px",
          }}
          className="w-9/10"
        >
          <Input.Password
            name="password"
            style={{
              border: "1px solid #C4C4C4",
              padding: "14px",
              fontSize: "14px",
            }}
            value={credentials.password}
            placeholder="Please enter your new password"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e)
            }
            iconRender={(visible: boolean) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            className={"rounded-md w-full"}
          />
          {error.passErr !== "" ? (
            <p className="text-red-500">{error.passErr}</p>
          ) : (
            ""
          )}
        </div>
        <div
          style={{
            marginBottom: "45px",
          }}
          className="w-9/10"
        >
          <Input.Password
            name="rePassword"
            style={{
              border: "1px solid #C4C4C4",
              padding: "14px",
              fontSize: "14px",
            }}
            value={credentials.rePassword}
            placeholder="Please re-enter your new password"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e)
            }
            iconRender={(visible: boolean) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            className={"rounded-md w-full"}
          />
          {error.passErr !== "" ? (
            <p className="text-red-500">{error.passErr}</p>
          ) : (
            ""
          )}
        </div>
        <div
          style={{
            marginBottom: "15px",
          }}
          className="w-100 flex"
        >
          <div>
            <ReCAPTCHA
              sitekey="6Ld3LAMcAAAAAEMFc3k4QUu8D5CdeDS9eRfCDcnr"
              onChange={verifyCaptcha}
            />
            {error.captchaErr !== "" ? (
              <p className="text-red-500 text-left">{error.captchaErr}</p>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="w-100 flex">
          <button
            style={{
              backgroundColor: "#32373B",
              fontSize: "19px",
              width: "231px",
              height: "50px",
              padding: "12px 86px",
            }}
            type="submit"
            className="btn text-white rounded-full my-5 font-thin"
          >
            Sign In
          </button>
        </div>
      </form>
    </div>
  );
};

export default withRouter(SetNewPass);
