import React, { useContext } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { NotificationAtom } from "../../../../../components/topbar/notificationAtom";
import usePracticeArea from "../../../../../store/zustand/usePracticeArea";
import { apiNetworkCall } from "../../../../../utils/api";
import { AUTOML_INTEGRATION } from "../../../../../utils/server-urls";

interface NoParaProps {
  paraEmpty: boolean;
  isAllowed: boolean;
  isFeedback: boolean;
}

const NoPara: React.SFC<NoParaProps> = ({
  paraEmpty,
  isAllowed,
  isFeedback,
}) => {
  let history = useHistory();
  let currentPrac = usePracticeArea();
  let [notifications, setNotifications] = useRecoilState(NotificationAtom);

  return (
    <div className={"w-full flex justify-center items-center"}>
      {paraEmpty ? (
        <div className={"flex flex-col items-center"}>
          <div className="text-xl text-gray-500 capitalize">
            {isFeedback
              ? "NO MORE PARAGRAPH PRESENT FOR VALIDATION"
              : "NO MORE PARAGRAPHS AVALIBALE FOR THIS CLASS"}
          </div>
          {isFeedback === false && (
            <button
              disabled={!isAllowed}
              title={
                isAllowed
                  ? ""
                  : "Validate more than 21 paras for at least 2 classes."
              }
              onClick={() => {
                apiNetworkCall(AUTOML_INTEGRATION, "post", {
                  practiceAreaId:
                    currentPrac.currentPracticeArea?.practiceAreaId,
                })
                  .then((res) => {})
                  .catch((err) => {
                    toast("Internal Server Error : Auto ML integration", {
                      type: "success",
                    });
                    setNotifications([
                      ...notifications,
                      "Internal Server Error : Auto ML integration",
                    ]);
                  });

                history.push("/app/pa/ws/model-training1");
              }}
              className={`flex duration-200  text-white px-3 py-2 ml-3 mt-3 rounded-md border-2 border-transparent   ${
                !isAllowed
                  ? "bg-gray-400"
                  : "bg-blueprimary hover:text-blueprimary hover:border-blueprimary hover:bg-white"
              }`}
            >
              TRAIN PLATFORM
            </button>
          )}
        </div>
      ) : (
        <div>
          <img src="/img/loading.svg" alt="loading" width={40} />
        </div>
      )}
    </div>
  );
};
export default NoPara;
