import * as React from "react";

let MasterPracticeAreaLayout: React.FC = ({ children }) => {
  return (
    <div>
      {/* <MainTabs /> */}
      {children}
    </div>
  );
};

export default MasterPracticeAreaLayout;
