import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Auth from "./pages/auth";
import MainApp from "./pages/app";
import PrivateRoute from "./components/private-route";
import { useEffect, useState } from "react";
import Page404 from "./pages/page-404";
import { useDeviceWidth } from "./utils/hooks";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import useCurrentUser from "./store/zustand/useCurrentUser";

function App() {
  let userStore = useCurrentUser();
  let [authCheckDone, setAuthCheckDone] = useState(false);

  let checkAuth = () => {
    if (localStorage.getItem("userInfo") !== null) {
      let user = JSON.parse(localStorage.getItem("userInfo") as string);

      if (user) {
        userStore.setIsAuth(true);
        userStore.setUser(user);
      } else {
        console.error("user parsing error");
      }
    }
    setAuthCheckDone(true);
  };

  let width = useDeviceWidth();

  useEffect(() => {
    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (width < 840) {
    return (
      <div
        className={
          "m-12 w-full h-screen flex justify-center text-center items-center text-xl"
        }
      >
        This application is not supported <br /> in any device below 840px.
      </div>
    );
  }

  if (authCheckDone === false) {
    return <div>loading ...</div>;
  }

  return (
    // Root router
    <div>
      <ToastContainer position={"bottom-right"} />
      <Router>
        <Switch>
          <Route exact path={"/not-found"} component={Page404} />
          <Route path={"/auth"} component={Auth} />
          <Route exact path={"/"} render={() => <Redirect to={"/app"} />} />
          <PrivateRoute path={"/app"} component={MainApp} />
          <Route component={Page404} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
