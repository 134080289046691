import * as React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import SignUp from "./signup";
import SignIn from "./signin";
import SignUpSuccess from "./sign-up-success";
import ForgotPass from "./forgot-password";
import setNewPassword from "./set-new-password";
import Marquee from "react-fast-marquee";
import ReactGA from "react-ga";

const Auth: React.SFC = () => {
  let history = useHistory();

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  React.useEffect(() => {
    //For updating the title of the page according to sign up or sign in page
    if (history.location.pathname === "/auth/signup") {
      document.title = "Sign up | Dataneuron";
    } else {
      document.title = "Sign In | Dataneuron";
    }
  }, [history.location.pathname]);

  return (
    <div className={"w-full md:h-screen overflow-y-hidden md:flex"}>
      <img
        style={{
          zIndex: -1000,
        }}
        className="fixed top-0 right-0"
        src="/img/quarter-green.svg"
        alt="quarter"
      />

      <img
        className="fixed bottom-0 left-0"
        src="/img/quarter-pink.svg"
        alt="quarter"
        style={{
          zIndex: -1000,
        }}
      />

      <div className={"w-full h-screen"}>
        <div className={"ml-16 mt-12"}>
          <img width={227} src="/img/gray_logo1.svg" alt="dataneuron logo" />
        </div>
        <Switch>
          <Route
            path={"/auth"}
            exact
            render={() => <Redirect to={"/auth/signin"} />}
          />
          <Route exact path={"/auth/signin"} component={SignIn} />
          <Route exact path={"/auth/signup"} component={SignUp} />
          <Route exact path={"/auth/thank_you"} component={SignUpSuccess} />
          <Route exact path={"/auth/forgot-password"} component={ForgotPass} />
          <Route
            exact
            path={"/auth/set-new-password/:email"}
            component={setNewPassword}
          />
          <Route render={() => <Redirect to={"/"} />} />
        </Switch>
      </div>
      <div
        style={{
          minWidth: "50%",
        }}
        className={
          "h-10/12 w-3/5 overflow-y-hidden mt-20 p-6 text-center flex items-center justify-center"
        }
      >
        <div
          style={{
            backgroundColor: "#32373B",
            borderRadius: "79px",
          }}
          className={"w-full h-full"}
        >
          <div className="w-full h-1/3"></div>
          <div className="w-full h-1/3 flex justify-center items-center">
            {/* <Game /> */}
            <Marquee gradient={false} style={{ width: 700 }} direction={"left"}>
              <img className="h-36" src="/img/flow.svg" alt="flow" />
            </Marquee>
          </div>
          <div
            style={{
              fontSize: "19pt",
            }}
            className="w-full h-1/3 text-white text-center flex justify-center items-center px-12"
          >
            An advanced platform for complex data annotations, model training,
            predictions & lifecycle management.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
