import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import usePracticeArea from "../../store/zustand/usePracticeArea";

interface IterateCardProps {}

const IterateCard: React.FC<IterateCardProps> = () => {
  const history = useHistory();
  let { currentPracticeArea } = usePracticeArea();
  let [trained, setTrained] = useState(false);
  useEffect(() => {
    setTrained(currentPracticeArea?.stages[5].active ? true : false);
    return () => {};
  }, [currentPracticeArea?.stages]);
  return (
    <div
      className="p-3 bg-white w-full"
      style={{ height: "180px", width: "300px" }}
    >
      <div className="flex space-x-2">
        <img
          width={17}
          height={22}
          src="/img/iterationBlue.svg"
          alt="iterate"
        ></img>
        <p style={{ fontWeight: 500, fontSize: "15px" }}>Iterate</p>
      </div>
      <div
        className="my-3"
        style={{ fontSize: "16px", lineHeight: "19px", fontWeight: 300 }}
      >
        Improve model accuracy by seeding, uploading, or validating more
        paragraphs.
      </div>
      <div className="w-full flex justify-center mt-10 mb-4">
        <div
          className={`flex justify-center items-center text-center cursor-${
            trained ? "pointer" : "not-allowed"
          } ${
            trained
              ? "hover:bg-primarycolor hover:text-white text-primarycolor"
              : "bg-gray-300 text-white"
          }`}
          onClick={() =>
            trained
              ? history.push("/app/pa/ws/masterlist")
              : toast("Complete training the model first!", { type: "warning" })
          }
          style={{
            border: `1px solid ${trained ? "#2C2C2C" : "white"}`,
            fontSize: "16px",
            width: "250px",
            height: "30px",
          }}
        >
          Start Iteration
        </div>
      </div>
    </div>
  );
};

export default IterateCard;
