import { Divider } from "antd";
import * as React from "react";
import { useState } from "react";
import { useRecoilState } from "recoil";
import NewDataCard from "../../../components/new-data-card";
import SearchBarDropDown from "../../../components/searchbar-dropdown";
import { NotificationAtom } from "../../../components/topbar/notificationAtom";
import { PracticeArea } from "../../../store/reducers/practice-area-reducer";
import { apiNetworkCall } from "../../../utils/api";
import {
  CREATE_PRACTICE_AREA_URL,
  GET_ALL_PA,
} from "../../../utils/server-urls";
import AddPracticeArea from "./addPracticeArea";
import InfoGraph from "./infoGraph";
import { toast } from "react-toastify";
import usePracticeArea from "../../../store/zustand/usePracticeArea";
import useCurrentUser from "../../../store/zustand/useCurrentUser";
import ReactGA from "react-ga";
import useDevLog from "../../../store/zustand/useDevLog";

export interface DashboardProps {
  authenticated: boolean;
}

// interface AddPracticeAreaCardProps {
//   setOpenModal: Function;
// }

// let AddPracticeAreaCard: React.SFC<AddPracticeAreaCardProps> = ({
//   setOpenModal,
// }) => {
//   return (
//     <div
//       style={{ height: "430px" }}
//       className="flex w-80 flex-col justify-center items-center cursor-pointer bg-white shadow-md hover:bg-gray-50"
//       onClick={() => setOpenModal(true)}
//     >
//       <RiAddCircleLine size={30} />
//       <p className="text-lg">Add New Scope</p>
//     </div>
//   );
// };

const Dashboard: React.SFC<DashboardProps> = () => {
  let { user } = useCurrentUser();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [active, setActive] = useState(0);
  const [details, setDetails] = useState({
    name: "",
    value: 0,
  });

  let [notifications, setNotifications] = useRecoilState(NotificationAtom);

  let pracStore = usePracticeArea();
  let logStore = useDevLog();

  React.useEffect(() => {
    if (JSON.parse(localStorage.getItem("userInfo") as string) !== null) {
      apiNetworkCall(GET_ALL_PA, "POST", {})
        .then((pas: any) => {
          let all_pracs: Array<PracticeArea> = pas.result.data;

          pracStore.setAllPracticeAreas(all_pracs);

          if (all_pracs.length === 0) {
            logStore.addLog({
              type: "info",
              log: "There was no practice area in the database",
              inFile: "src/pages/app/dashboard/dashboard.tsx",
            });
            setModalOpen(true);
          }
        })
        .catch((err) => {
          console.error(err);
          logStore.addLog({
            type: "error",
            log: "There was some problem in fetching practice areas",
            data: err,
            inFile: "src/pages/app/dashboard/dashboard.tsx",
          });
          toast("Internal Server Error : Fetching the masterlist", {
            type: "success",
          });

          setNotifications([
            ...notifications,
            "Internal Server Error : Fetching the masterlist",
          ]);
        });
    }

    ReactGA.pageview(window.location.pathname);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    if (pracStore.allPracticeAreas.length !== 0) {
      let accuracy =
        pracStore.allPracticeAreas[pracStore.allPracticeAreas.length - 1]
          .alpdsInfo?.trainInfo?.MicroF1 * 100;
      cardSelectedHandler(
        pracStore.allPracticeAreas.length - 1,
        accuracy,
        pracStore.allPracticeAreas[pracStore.allPracticeAreas.length - 1]
          .acronym
      );
    } else {
      cardSelectedHandler(1, 1, "No practice area added");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pracStore.allPracticeAreas]);

  const handleCreateScope = (scopeDetails: any, done: Function) => {
    apiNetworkCall(CREATE_PRACTICE_AREA_URL, "POST", {
      name: scopeDetails.name,
      acronym: scopeDetails.acronym,
    })
      .then((res_data: any) => {
        pracStore.setAllPracticeAreas([
          ...pracStore.allPracticeAreas,
          res_data.result.data as PracticeArea,
        ]);
        setModalOpen(false);
        done();
      })
      .catch((err) => {
        logStore.addLog({
          type: "error",
          log: "There was some problem in creating the practice area",
          data: err,
          inFile: "src/pages/app/dashboard/dashboard.tsx",
        });
        done();
        console.error(err);
        toast("The scope already exists", { type: "success" });
      });
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const handleSearch = (searchKey: any) => {};
  let validateAccuracy = (accuracy: any) => {
    if (accuracy !== undefined && !isNaN(accuracy)) {
      return accuracy;
    }
    return 0;
  };
  const cardSelectedHandler = (
    index: number,
    accuracy: number,
    acronym: string
  ) => {
    setActive(index);
    setDetails({ name: acronym, value: validateAccuracy(accuracy) });
  };

  return (
    <div className={"w-full"}>
      <div className="p-3">
        <div
          className="w-full flex justify-between items-center"
          style={{ height: "150px" }}
        >
          <div className="w-full px-5">
            <div
              className={"text-skin-darkGrey font-sans font-medium"}
              style={{ fontSize: "24px" }}
            >
              Welcome back,
            </div>
            <div
              className={"text-xl font-sans font-medium"}
              style={{ fontSize: "24px" }}
            >
              {user?.firstName} {user?.lastName}
            </div>
          </div>
          {/* <div
            className="bg-white p-5 flex mx-5 rounded-md"
            style={{
              width: "450px",
              boxShadow: "0px 0px 8px rgba(122, 122, 122, 0.15)",
            }}
          >
            <img src="/img/project_file.svg" alt="projects"></img>
            <div className="mx-5 lg:w-4/5 md:w-3/5">
              <p
                className="font-sans text-skin-darkGrey"
                style={{ fontSize: "12px" }}
              >
                Current Validating
              </p>
              <p className="font-sans font-medium text-lg text-skin-darkGrey">
                {pa ? pa.length : 0} Projects
              </p>
              <div style={{
                width:'100%',
                background:"#7A7A7A",
                height:"1px",
                margin:'8px 0'
              }}>

              </div>
              <div onClick={handleOpenModal} className="flex justify-between">
                <p className="font-sans text-base text-skin-darkGrey">
                  Create New Scope
                </p>
                <img
                  src="/img/add_plus_grey.svg"
                  alt="add scope"
                  className="cursor-pointer"
                ></img>
              </div>
            </div> */}
          {/* </div> */}
        </div>
        <div className="w-full my-5 px-5">
          <SearchBarDropDown handleSearch={handleSearch} />
          <Divider />
        </div>
        <div className="flex w-full">
          <div className="w-1/2">
            <div className="text-2xl font-medium font-sans flex items-center px-5">
              <p>Projects Overview</p>
              <img
                src="/img/overview.svg"
                className={"ml-3"}
                alt="overview icon"
              />
              <div
                onClick={handleOpenModal}
                className="flex transform hover:scale-105 duration-300 font-sans font-normal text-white text-base mx-5 px-5 py-2 rounded-md cursor-pointer"
                style={
                  modalOpen
                    ? { backgroundColor: "#9A9A9A" }
                    : { backgroundColor: "#11256D" }
                }
              >
                Create New Scope
                <img
                  className="ml-2"
                  src="/img/add_plus_white.svg"
                  alt="add"
                ></img>
              </div>
            </div>
            <div>
              <div
                className="custom_scroll w-9/10 pl-5 my-5 border-r-2 pr-10"
                style={{
                  height: "450px",
                  overflowY: "auto",
                  overflowX: "hidden",
                  maxWidth: "100%",
                }}
              >
                {modalOpen && (
                  <AddPracticeArea
                    setShowModal={handleCloseModal}
                    createPaHandler={handleCreateScope}
                  />
                )}
                {pracStore.allPracticeAreas
                  .map((item: any, index: number) => (
                    <div key={item.acronym}>
                      <NewDataCard
                        active={active === index}
                        index={index}
                        data={item}
                        cardSelectedHandler={cardSelectedHandler}
                      />
                    </div>
                  ))
                  .reverse()}
              </div>
            </div>
          </div>
          <div className="w-1/2">
            <div className="flex justify-between w-full">
              <div className="text-2xl font-medium font-sans flex items-center px-5 justify-items-start">
                <p className="ml-14">Project Information</p>
                <img
                  src="/img/clock_icon_green.svg"
                  className={"ml-3"}
                  alt="information"
                />
              </div>
              <img
                src="/img/filter.svg"
                alt="filter"
                className="cursor-pointer px-5"
              ></img>
            </div>
            <div>
              {/* graph here */}
              <div className="my-5">
                {/* @ts-ignore */}
                <InfoGraph
                  data={pracStore.allPracticeAreas}
                  active={active}
                  accuracy={details.value}
                  acronym={details.name}
                  setCard={cardSelectedHandler}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full my-5 px-5">
          <Divider />
        </div>
        {/* <div>
          <div
            className="font-medium font-sans flex items-center px-5"
            style={{ fontSize: "24px", lineHeight: "28px" }}
          >
            <p>Tips and Guidelines</p>
          </div>
          <div className="flex w-full justify-between px-5 my-5">
            <FooterCard
              text={`More data in the model class can enhance the accuracy`}
            />
            <FooterCard
              text={`For improved accuracy, include a variety of keywords on the masterlist`}
            />
            <FooterCard
              text={`Only one Masterlist can be defined inside a Scope`}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Dashboard;
