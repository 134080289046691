import * as React from "react";
import { createContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import ChildPracticeAreaLayout from "../../../../components/layouts/child-pa-layout";
import ModelTraining1 from "./modal-training-1";
import ModelTraining2 from "./model-training-2";
import Stage1 from "./stage-1";
import Stage2 from "./stage-2";
import Upload from "./upload";
import { useHistory } from "react-router";
import {
  SET_STAGE2_BATCH2_INIT,
  SET_STAGE2_BATCH3_INIT,
} from "../../../../store/types/stage-2-types";
import useMasterlist from "../../../../components/masterlist/hooks/useMasterlist";
import { injestProperties } from "../../../../components/masterlist/util/helper";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { nodesAtom } from "../../../../components/masterlist/atoms";
import { setActive } from "../../../../components/workspace-nav/stage-helper";
import {
  sendPracticeAreaInfo,
  subscribeToPracticeAreaChange,
} from "../../../../realtime";
import usePracticeArea from "../../../../store/zustand/usePracticeArea";
import { PracticeArea as PracArea } from "../../../../store/reducers/practice-area-reducer";
import useCurrentUser from "../../../../store/zustand/useCurrentUser";
import Masterlist from "../../../../components/masterlist";
import Feedback from "../../feedback";
import WorkFlow from "../../workflow";
import { tempFixAtom } from "./stage-2/temp";
import ConsoleHelper from "../../../../utils/consoleHelper";
import useDevLog from "../../../../store/zustand/useDevLog";

export interface StageTab {
  des: string;
  stageNo: number;
  active: boolean;
  redirLink: string;
  showTitle: boolean;
}

export interface StageContextType {
  setStages: Function;
  stages: Array<StageTab>;
}

//@ts-ignore
export let StageContext = createContext<StageContextType>();

const PracticeArea: React.FC = () => {
  let [states, setStages] = React.useState<Array<StageTab>>([
    {
      des: "select source",
      stageNo: 1,
      active: true,
      redirLink: "/app/pa/ws/upload",
      showTitle: true,
    },
    {
      des: "stage 1 validation",
      stageNo: 2,
      active: false,
      redirLink: "/app/pa/ws/stage1",
      showTitle: false,
    },
    {
      des: "stage 2 validation",
      stageNo: 3,
      active: false,
      redirLink: "/app/pa/ws/stage2",
      showTitle: false,
    },
    {
      des: "Model training",
      stageNo: 4,
      active: false,
      redirLink: "/app/pa/ws/model-training2",
      showTitle: false,
    },
    {
      des: "Deployment options",
      stageNo: 5,
      active: false,
      redirLink: "/app/pa/ws/model-training2/dep",
      showTitle: false,
    },
  ]);

  let dispatch = useDispatch();
  let { loading, data, error } = useMasterlist();
  let setNodes = useSetRecoilState(nodesAtom);

  let devlog = useDevLog();

  let { user } = useCurrentUser();

  React.useEffect(() => {
    //@ts-ignore
    sendPracticeAreaInfo(user?.userId || "", currentPracticeArea?._id || "");

    devlog.setLastPracticeArea({
      name: currentPracticeArea?.name,
      practiceAreaId: currentPracticeArea?.practiceAreaId,
    });

    ConsoleHelper(
      `%c Send the current practice area to the server via socket [${currentPracticeArea?._id}]`,
      "color : green"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (loading === false && error === undefined && data !== undefined) {
      let injestedNodes = injestProperties(data.nodes);
      setNodes(injestedNodes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data, error]);

  React.useEffect(() => {
    dispatch({
      type: SET_STAGE2_BATCH3_INIT,
      payload: {
        action: false,
      },
    });
    dispatch({
      type: SET_STAGE2_BATCH2_INIT,
      payload: {
        action: false,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let { currentPracticeArea, setCurrentPracticeArea } = usePracticeArea();

  React.useEffect(() => {
    subscribeToPracticeAreaChange((data) => {
      setCurrentPracticeArea(data as PracArea);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPracticeArea]);

  return (
    <StageContext.Provider value={{ stages: states, setStages }}>
      <ChildPracticeAreaLayout>
        <Switch>
          <Route
            exact
            path={"/app/pa/ws"}
            render={() => <Redirect to={"/app/pa/ws/flow"} />}
          />
          <Route path={"/app/pa/ws/masterlist"} component={Masterlist} />
          <Route path={"/app/pa/ws/update"} component={Feedback} />
          <Route path={"/app/pa/ws/flow"} component={WorkFlow} />
          <Route path={"/app/pa/ws/upload"} component={Upload} />
          <Route path={"/app/pa/ws/stage1"} component={Stage1} />
          <Route path={"/app/pa/ws/stage2"} component={Stage2} />
          <Route
            path={"/app/pa/ws/model-training1"}
            component={ModelTraining1}
          />
          <Route
            path={"/app/pa/ws/model-training2"}
            component={ModelTraining2}
          />
          <Route render={() => <Redirect to={"/not-found"} />} />
        </Switch>
      </ChildPracticeAreaLayout>
    </StageContext.Provider>
  );
};

export default PracticeArea;
