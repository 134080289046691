import * as React from "react";

export interface Stage2NodeProps {
  parent_info: string;
  node_name: string;
  completedPercentage: number;
  active: boolean;
  onSelect: React.MouseEventHandler<HTMLDivElement>;
  goal: number;
}

const Stage2Node: React.SFC<Stage2NodeProps> = ({
  active,
  onSelect,
  parent_info,
  node_name,
  completedPercentage,
  goal,
}) => {
  goal = goal === 0 ? 1 : goal;
  return (
    <div
      className="cursor-pointer mb-4 duration-200"
      style={{
        borderRadius: 4,
        minWidth: 269,
        padding: "12px 15px",
        border: active ? "1px solid #11256D" : "none",
        backgroundColor: active ? "white" : "#EAEAEA",
      }}
      onMouseEnter={(e) => {
        if (active === false) {
          //@ts-ignore
          e.target.style.opacity = 0.7;
        }
      }}
      onMouseLeave={(e) => {
        //@ts-ignore
        e.target.style.opacity = 1;
      }}
      onClick={onSelect}
    >
      <div style={{ color: "#9A9A9A", fontSize: 12 }}>{parent_info}</div>
      <div className="font-medium" style={{ fontSize: 15 }}>
        {node_name}
      </div>
      <div style={{ width: "100%", backgroundColor: "#ffffff", height: 3 }}>
        <div
          style={{
            width: `${(completedPercentage * 100) / goal}%`,
            backgroundColor: "#11256D",
            height: 2,
          }}
        />
      </div>
    </div>
  );
};

export default Stage2Node;
