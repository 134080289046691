import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { GlobalState } from "../../../store/reducers";
import ReviewInfo from "./components/info";
import UploadPage from "./uploadPage";
import ValidateAnnotations from "./components/validateAnnotations";
import styles from "./feedback.module.css";
import SeedPara from "./seedpara";
import { SET_FEEDBACK_DETAILS } from "../../../store/types/feedback.types";
import { useRecoilState } from "recoil";
import usePracticeArea from "../../../store/zustand/usePracticeArea";
import { apiNetworkCall } from "../../../utils/api";
import { AUTOML_INTEGRATION } from "../../../utils/server-urls";
import { toast } from "react-toastify";
import { NotificationAtom } from "../../../components/topbar/notificationAtom";
import { Button } from "antd";
import ReactGA from "react-ga";

export interface FeedbackProps {}
// TODO give dynamic practice area name
const Feedback: React.SFC<FeedbackProps> = () => {
  const seed_grey = "/img/seed_grey.svg";
  const seed_white = "/img/seed_white.svg";
  const upload_grey = "/img/upload_grey.svg";
  const upload_white = "/img/upload_white.svg";
  const review_more_grey = "/img/review_more.svg";
  const review_more_white = "/img/review_more_white.svg";

  let feedback = useSelector((state: GlobalState) => state.feedback);
  const dispatch = useDispatch();
  const [active, setThisActive] = useState<Number>(feedback.tab);
  let [trainClicked, setTrainedClicked] = useState<boolean>(false);

  const handleClick = (index: number) => {
    setThisActive(index);
    dispatch({
      type: SET_FEEDBACK_DETAILS,
      payload: {
        tab: index,
        node_id: feedback.node_id,
        node_name: feedback.node_name,
        accuracy: feedback.accuracy,
      },
    });
  };

  let history = useHistory();

  let { currentPracticeArea, setForAutoMl } = usePracticeArea();
  let [notifications, setNotifications] = useRecoilState(NotificationAtom);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div className="w-full">
      <div className="w-full px-5">
        <ReviewInfo />
      </div>
      <div className={styles.container}>
        <div className={styles.tabsContainer}>
          <div
            className={active !== 0 ? styles.tabButton : styles.tabButtonActive}
            onClick={() => handleClick(0)}
          >
            <img src={active === 0 ? seed_white : seed_grey} alt="seed"></img>
            Seed Paragraph
          </div>
          <div
            className={active !== 1 ? styles.tabButton : styles.tabButtonActive}
            onClick={() => handleClick(1)}
          >
            <img
              src={active === 1 ? upload_white : upload_grey}
              alt="upload"
            ></img>
            Upload Source
          </div>
          <div
            className={active !== 2 ? styles.tabButton : styles.tabButtonActive}
            onClick={() => handleClick(2)}
          >
            <img
              src={active === 2 ? review_more_white : review_more_grey}
              alt="review more"
            ></img>
            Review More
          </div>
        </div>
        <div className={styles.content}>
          {active === 0 && <SeedPara />}
          {active === 1 && <UploadPage />}
          {active === 2 && <ValidateAnnotations />}
        </div>
      </div>
      <div className="flex justify-end p-6">
        <Button
          loading={trainClicked}
          onClick={() => {
            if (trainClicked === false) {
              setTrainedClicked(true);
              apiNetworkCall(AUTOML_INTEGRATION, "post", {
                practiceAreaId: currentPracticeArea?.practiceAreaId,
              })
                .then((res) => {
                  setTimeout(() => {
                    setForAutoMl();
                    history.push("/app/pa/ws/model-training1");
                  }, 2000);
                })
                .catch((err) => {
                  toast("Internal Server Error : Auto ML integration", {
                    type: "success",
                  });
                  setNotifications([
                    ...notifications,
                    "Internal Server Error : Auto ML integration",
                  ]);
                });
            }
          }}
          className={"flex text-white rounded-md hover:text-white"}
          style={{ backgroundColor: "#11256D", fontSize: 15 }}
        >
          TRAIN PLATFORM
        </Button>
      </div>
    </div>
  );
};

export default Feedback;
