import { atom } from "recoil";

export interface SignUpData {
    firstName : string,
    lastName : string,
    email : string
}


export let SignUpAtom = atom<SignUpData>({
    default : {
        firstName : "",
        lastName : "",
        email : ""
    },
    key : "signupdata"
})