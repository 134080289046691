import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import useCurrentUser from "../../store/zustand/useCurrentUser";

interface PrivateRouteProps extends RouteProps {
  component: React.FC;
}

const PrivateRoute: React.SFC<PrivateRouteProps> = ({ component, ...rest }) => {
  let { user } = useCurrentUser();

  const routeComponent = (props: any) =>
    user !== null ? (
      React.createElement(component, props)
    ) : (
      <Redirect to={{ pathname: "/auth" }} />
    );
  return <Route {...rest} render={routeComponent} />;
};

export default PrivateRoute;
