import config from "../config";
import { connect } from "socket.io-client";
import ConsoleHelper from "../utils/consoleHelper";

const socket = connect(config.socket_server_url);

type SocketFunction = (data: any) => void;

socket.on("connect", () => {
  let user = JSON.parse(localStorage.getItem("userInfo") as string);
  if (user) {
    sendInitialData(user.userId);
    ConsoleHelper(
      `%c connected to the server and shared user id ${user.userId}`,
      "color : green"
    );
  }
});

const sendInitialData = (userId: string) => {
  socket.emit("initialInfo", {
    userId: userId,
  });
};

const sendPracticeAreaInfo = (userId: string, docId: string) => {
  socket?.emit("pracChanged", {
    userId,
    docId,
  });
};

const subscribeToPracticeAreaChange = (cb: SocketFunction) => {
  socket.off("practiceAreaChanged");
  socket?.on("practiceAreaChanged", (data) => {
    cb(data);
  });
};

export { sendInitialData, sendPracticeAreaInfo, subscribeToPracticeAreaChange };

export default socket;
