import { atom } from "recoil";
import { Layer, Masterlist, Node } from "./util/interface";

export let layersAtom = atom<Array<Layer>>({
  key: "layers",
  default: [],
});

export let nodesAtom = atom<Array<Node>>({
  key: "nodes",
  default: [],
});

export let masterlistIdAtom = atom<Masterlist | null>({
  key: "masterlistId",
  default: null,
});

export let activeNodeAtom = atom<Node | null>({
  key: "activeNode",
  default: null,
});

export let activeNodeAccuracy = atom<string>({
  key: "activeNodeAccuracy",
  default: "0",
});
