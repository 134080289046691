import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRecoilState } from "recoil";
import FeedbackPara from "../../../../components/data-validation/feedbackPara";
import RejectedPara from "../../../../components/data-validation/RejectedPara";
import { NotificationAtom } from "../../../../components/topbar/notificationAtom";
import { GlobalState } from "../../../../store/reducers";
import { ParaDataType } from "../../../../store/reducers/stage1Reducer";
import { FEEDBACK_ADD_PARAS } from "../../../../store/types/feedback.types";
import { apiNetworkCall } from "../../../../utils/api";
import { GET_NODE_PARAS } from "../../../../utils/server-urls";
import { toast } from "react-toastify";
import usePracticeArea from "../../../../store/zustand/usePracticeArea";
import NoPara from "../../practice-area/pa/stage-2/NoPara";
export interface ValidateAnnotationsProps {}

const ValidateAnnotations: React.SFC<ValidateAnnotationsProps> = () => {
  let { currentPracticeArea } = usePracticeArea();
  // practiceAreaId="10w3uf6smytkszx431j";
  let feedback = useSelector((state: GlobalState) => state.feedback);
  let dispatch = useDispatch();
  let [active_node_Id, setActiveNode] = useState<string | null>(
    feedback.node_id
  );
  let [active_node_name, setActiveNodeName] = useState<string | null>(
    feedback.node_name
  );
  let [paras, setParas] = useState<Array<ParaDataType>>([]);

  let [notifications, setNotifications] = useRecoilState(NotificationAtom);
  let [fetching, setFetching] = useState(false);

  let [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    if (fetching === true) {
      setIsEmpty(false);
    } else {
      if (paras.length === 0) {
        setIsEmpty(true);
      } else {
        setIsEmpty(false);
      }
    }
  }, [fetching]);

  let fetchParas = () => {
    setFetching(true);
    const params = {
      practiceAreaId: currentPracticeArea?.practiceAreaId,
      predictedIssueId: active_node_Id,
      stage: 2,
    };
    let data = apiNetworkCall(GET_NODE_PARAS, "post", params)
      .then((res: any) => {
        let para_data = res.result.data.paras;
        para_data = para_data.filter((para: any) => para.accepted === null);
        let final_para_data = para_data.map((p: any) => p.para_info);
        // let temp = paras;
        let temp = final_para_data;
        setParas(temp);
        setFetching(false);
      })
      .catch((err) => {
        setFetching(false);

        toast("Internal Server Error : Fetching the paras", {
          type: "success",
        });
        setNotifications([
          ...notifications,
          "Internal Server Error : Fetching the paras.",
        ]);
      });
  };
  useEffect(() => {
    fetchParas();
  }, []);
  useEffect(() => {
    //
    let filtered_data = paras.filter(
      (para: ParaDataType) => para.accepted === null
    );

    dispatch({
      type: FEEDBACK_ADD_PARAS,
      payload: filtered_data,
    });
  }, [paras]);
  return (
    <div className="w-full">
      <div className={` space-y-3 px-3`} style={{ marginTop: "-18px" }}>
        <div>
          {feedback.current_paras && feedback.current_paras.length > 0 ? (
            feedback.current_paras.map((para: ParaDataType) => {
              return (
                <FeedbackPara
                  active_node_id={active_node_Id}
                  accepted={para?.accepted}
                  node_name={active_node_name}
                  para_data={para.paraData}
                  time_str={para.createdAt?.toString()}
                  paraId={para.paraId}
                  stage={2}
                  predictedIssueId={para.alpdsInfo?.predictedIssueId?.stage2}
                  nextPredictedIssueId={
                    para.alpdsInfo?.rankMetric.stage2.value[1][0]
                  }
                />
              );
            })
          ) : (
            <NoPara isFeedback={true} isAllowed={true} paraEmpty={isEmpty} />
          )}
        </div>
        <Divider />
        <div style={{ fontSize: 18, color: "#7A7A7A" }}>Suggest Tags</div>
        <Divider />
        <div className="space-y-3">
          {feedback.rejected_paras.map((para: any) => {
            return (
              <RejectedPara
                node_name={para.curNode.node_name}
                para_data={para.data.paraData}
                suggestedNodeName={para.nextPredicted?.node_name}
                suggestedNodeParentName={para.nextPredicted?.parent_name}
                time_str={para.data.createdAt.toString()}
                model_node_list={"sdfjh"}
                paraId={para.data.paraId}
                stage={2}
                nodeId={para.nextPredicted?.node_id}
                selected={para.nextPredicted?.selected}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ValidateAnnotations;
