import * as React from "react";
import { Wire } from "./util/interface";

export interface WireContainerProps {
  wires: Array<Wire>;
}

let calculateHeight = (wires: Array<Wire>) => {
  let biggest = 0;
  wires.forEach((wire) => {
    if (wire.y1 > biggest) {
      biggest = wire.y1;
    }
    if (wire.y2 > biggest) {
      biggest = wire.y2;
    }
  });
  return biggest + 100;
};

const WireContainer: React.SFC<WireContainerProps> = ({ wires }) => {
  let containerWidth = 100;

  return (
    <svg style={{ width: containerWidth, height: calculateHeight(wires) }}>
      {wires.map((wire, i) => {
        return (
          <path
            stroke={wire.stroke}
            strokeWidth={1}
            key={i * Math.random()}
            fill={"transparent"}
            d={`M 0 ${wire.y1} L ${containerWidth / 2 + 2 * i} ${wire.y1} L ${
              containerWidth / 2 + 2 * i
            } ${wire.y2} L ${containerWidth} ${wire.y2}`}
          />
        );
      })}
    </svg>
  );
};

export default WireContainer;
