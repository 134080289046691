import { Divider } from "antd";
import * as React from "react";
import { apiNetworkCall } from "../../../../../../utils/api";
import {
  DOWNLOAD_DATA,
  EXPORT_DATASET,
} from "../../../../../../utils/server-urls";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import usePracticeArea from "../../../../../../store/zustand/usePracticeArea";

export interface Deploy2Props {}

const Deploy2: React.SFC<Deploy2Props> = () => {
  const [stat, setStat] = React.useState({
    count: 0,
    download: 0,
  });
  let { currentPracticeArea } = usePracticeArea();

  let [requiredExports, setRequiredExports] = React.useState({
    masterlist: false,
    training: false,
    prediction: false,
  });

  // let io = React.useContext(SocketContext);

  let [trackingIds, setTrackingIds] = React.useState({
    masterlist: "",
    training: "",
    predict: "",
  });
  const [disabled, setDisabled] = React.useState(false);
  let downloadCall = async (trackingID: string) => {
    if (trackingID !== "") {
      apiNetworkCall(DOWNLOAD_DATA, "POST", {
        tracking: trackingID,
      })
        .then((res: any) => {
          let { data } = res;

          if (data && data.url) {
            saveAs(data.url);
            setStat((s) => ({ ...s, download: s.download + 1 }));
          } else {
            toast("Internal Server Error : Report Generation", {
              type: "error",
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  React.useEffect(() => {
    if (stat.count === stat.download) {
      setDisabled(false);
      setStat((s) => ({ ...s, count: 0 }));
      setStat((s) => ({ ...s, download: 0 }));
    }
  }, [stat.count, stat.download]);

  let initDownloadData = () => {
    if (requiredExports.masterlist) {
      setStat((s) => ({ ...s, count: s.count + 1 }));
    }
    if (requiredExports.prediction) {
      setStat((s) => ({ ...s, count: s.count + 1 }));
    }
    if (requiredExports.training) {
      setStat((s) => ({ ...s, count: s.count + 1 }));
    }
    apiNetworkCall(EXPORT_DATASET, "POST", {
      practiceAreaId: currentPracticeArea?.practiceAreaId,
      masterlist: requiredExports.masterlist,
      training: requiredExports.training,
      prediction: requiredExports.prediction,
    })
      .then((res: any) => {
        setTrackingIds(res);
      })
      .catch((err) => {
        toast("Internal Server Error : Downloading the report", {
          type: "error",
        });
      });
  };

  let [masterlistloading, setMasterlistLoading] = React.useState(false);
  let [trainingloading, setTrainingLoading] = React.useState(false);
  let [predictloading, setPredictLoading] = React.useState(false);

  //for masterlist
  React.useEffect(() => {
    let val =
      currentPracticeArea?.alpdsInfo?.percentageStatus?.exportData
        ?.masterlistExportProgress;
    if (val === 0) {
      setMasterlistLoading(true);
    } else {
      if (masterlistloading) {
        downloadCall(trackingIds.masterlist);
      }
      setMasterlistLoading(false);
    }
  }, [
    currentPracticeArea?.alpdsInfo?.percentageStatus?.exportData
      ?.masterlistExportProgress,
  ]);

  React.useEffect(() => {
    let val =
      currentPracticeArea?.alpdsInfo?.percentageStatus?.exportData
        ?.trainDataExportProgress;

    if (val === 0) {
      setTrainingLoading(true);
    } else {
      if (trainingloading) {
        downloadCall(trackingIds.training);
      }
      setTrainingLoading(false);
    }
  }, [
    currentPracticeArea?.alpdsInfo?.percentageStatus?.exportData
      ?.trainDataExportProgress,
  ]);

  React.useEffect(() => {
    let val =
      currentPracticeArea?.alpdsInfo?.percentageStatus?.exportData
        ?.predictedDataExportProgress;

    if (val === 0) {
      setPredictLoading(true);
    } else {
      if (predictloading) {
        downloadCall(trackingIds.predict);
      }
      setPredictLoading(false);
    }
  }, [
    currentPracticeArea?.alpdsInfo?.percentageStatus?.exportData
      ?.predictedDataExportProgress,
  ]);

  return (
    <div className="my-3 mx-5 w-full">
      <div>
        <p className={"font-thin mb-3 text-lg w-11/12"}>
          Download the data by selecting the checkboxes below and then click on
          "EXPORT DATASET" button. The Download process will initiate
          automatically. Data will be downloaded in .xlsx format.
        </p>
        <p className="font-sans text-base">
          Select which dataset you wish to export
        </p>
        <div className="my-5">
          <div className="flex items-center mt-3">
            <input
              onChange={(e) =>
                setRequiredExports({
                  ...requiredExports,
                  masterlist: !requiredExports.masterlist,
                })
              }
              type="checkbox"
              className="rounded-md mr-5"
              style={{ width: "15px", height: "15px" }}
            />
            <p className="font-sans text-lg">Masterlist</p>
          </div>
          <div className="flex items-center mt-3">
            <input
              onChange={(e) =>
                setRequiredExports({
                  ...requiredExports,
                  training: !requiredExports.training,
                })
              }
              type="checkbox"
              className="rounded-md mr-5"
              style={{ width: "15px", height: "15px" }}
            />
            <p className="font-sans text-lg">Training Dataset</p>
          </div>
          <div className="flex items-center mt-3">
            <input
              disabled={
                currentPracticeArea?.alpdsInfo?.percentageStatus
                  ?.predictionOnDatabaseProgress != 100
              }
              onChange={(e) =>
                setRequiredExports({
                  ...requiredExports,
                  prediction: !requiredExports.prediction,
                })
              }
              type="checkbox"
              className="rounded-md mr-5"
              style={{ width: "15px", height: "15px" }}
            />

            <p className="font-sans text-lg">
              Prediction Results on Ingested Data
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-end my-10">
        {disabled ? (
          <div className="px-4 rounded-md py-3 border-primarycolor bg-white border-2">
            <img className="w-24 h-6" src="/img/loading.svg" alt="loader" />
          </div>
        ) : (
          <button
            onClick={() => {
              if (
                requiredExports.masterlist === true ||
                requiredExports.training === true ||
                requiredExports.prediction === true
              ) {
                setDisabled(true);
                initDownloadData();
              } else {
                toast("Please select any one export option", {
                  type: "warning",
                });
              }
            }}
            title={
              requiredExports.masterlist === true ||
              requiredExports.training === true ||
              requiredExports.prediction === true
                ? ""
                : "Please select any one export option"
            }
            className={`${
              requiredExports.masterlist === true ||
              requiredExports.training === true ||
              requiredExports.prediction === true
                ? "bg-primarycolor"
                : "bg-gray-400 cursor-not-allowed"
            } px-7 py-3 text-white font-sans text-base rounded-md`}
          >
            EXPORT DATASET
          </button>
        )}
      </div>
      <div className={"mt-6 w-1/2"}>
        <div className="flex">
          <img src="/img/tip_green.svg" alt="tip" className="mr-5"></img>
          <p className="font-sans text-lg text-skin-darkGrey">Tip</p>
        </div>
        <Divider style={{ margin: "10px 0" }} />
        <p
          className="text-gray-500 font-sans"
          style={{ fontSize: "18px", lineHeight: "21px" }}
        >
          In order to download data please enable "Pop Up" through your browser.
          <br />
          Please perform "Prediction on Ingested Data" before exporting
          "Prediction Results on Ingested Data".
        </p>
      </div>
    </div>
  );
};

export default Deploy2;
