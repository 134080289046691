import create from "zustand";
import { PracticeArea } from "../reducers/practice-area-reducer";
import produce from "immer";

interface UsePracticeAreaType {
  currentPracticeArea: PracticeArea | null;
  allPracticeAreas: Array<PracticeArea>;
  setCurrentPracticeArea: (pracArea: PracticeArea) => void;
  setAllPracticeAreas: (pracAreas: Array<PracticeArea>) => void;
  addPracticeArea: (pracArea: PracticeArea) => void;
  setForAutoMl: () => void;
}

let usePracticeArea = create<UsePracticeAreaType>((set) => ({
  currentPracticeArea: null,
  allPracticeAreas: [],
  setCurrentPracticeArea: (pracArea: PracticeArea) =>
    set({
      currentPracticeArea: pracArea,
    }),
  setAllPracticeAreas: (pracAreas: Array<PracticeArea>) =>
    set({
      allPracticeAreas: pracAreas,
    }),
  addPracticeArea: (pracArea: PracticeArea) =>
    set((state) =>
      produce(state, (draft: UsePracticeAreaType) => {
        draft.allPracticeAreas.push(pracArea);
      })
    ),
  setForAutoMl: () => {
    set((state) => ({
      currentPracticeArea: produce(state.currentPracticeArea, (copy) => {
        if (copy !== null) {
          copy.stages[5].active = false;
          copy.alpdsInfo.percentageStatus.automlProgress.automlClassifier1Progress = 0;
          copy.alpdsInfo.percentageStatus.automlProgress.automlClassifier2Progress = 0;
          copy.alpdsInfo.percentageStatus.automlProgress.dataLoadingProgress = 0;
        }
      }),
    }));
  },
}));

export default usePracticeArea;
