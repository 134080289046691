import create from "zustand";

export interface User {
  company: string;
  designation: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  token: string;
  userId: string;
  billingAccountId: string;
}

interface UseCurrentUser {
  user: User | null;
  isAuth: boolean;
  setUser: (user: User) => void;
  setIsAuth: (isAuth: boolean) => void;
}

let useCurrentUser = create<UseCurrentUser>((set) => ({
  user: null,
  isAuth: false,
  setUser: (user: User) =>
    set({
      user,
    }),
  setIsAuth: (isAuth: boolean) =>
    set({
      isAuth,
    }),
}));

export default useCurrentUser;
