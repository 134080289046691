import { Dropdown, Menu } from "antd";
import React, { useRef } from "react";
import { FiChevronDown } from "react-icons/fi";
import { Node as NodeType } from "./util/interface";
import { useRecoilState } from "recoil";
import { activeNodeAtom, layersAtom, nodesAtom } from "./atoms";
import { calculateNextLayer, updateNode, updateNodeProp } from "./util/helper";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  SET_FEEDBACK_DETAILS,
  SET_UPDATE_ACTIVE,
} from "../../store/types/feedback.types";
import { useHistory } from "react-router";
import { NotificationAtom } from "../topbar/notificationAtom";
import { Report } from "../../pages/app/practice-area/pa/model-training-2/model_training2";
import { toast } from "react-toastify";
import usePracticeArea from "../../store/zustand/usePracticeArea";

export interface NodeProps {
  nodeInfo: NodeType;
}

const Node: React.SFC<NodeProps> = ({ nodeInfo }) => {
  let [layers, setLayers] = useRecoilState(layersAtom);
  let [nodes, setNodes] = useRecoilState(nodesAtom);
  let [activeNodeState, setActiveNodeState] = useRecoilState(activeNodeAtom);

  let getNodeInfo = (nodeId: string): Report | undefined => {
    //@ts-ignore
    if (allNodes !== undefined && allNodes[nodeId] !== undefined) {
      //@ts-ignore
      return allNodes[nodeId];
    } else {
      return undefined;
    }
  };

  let [notifications, setNotifications] = useRecoilState(NotificationAtom);
  let [allNodes, setAllNodes] = useState();
  let [node_name, setNodeName] = useState("");
  let [keywords, setKeywords] = useState("");
  let [editable, setEditable] = useState(false);

  // console.log(nodeInfo);
  let history = useHistory();

  const dispatch = useDispatch();
  const { currentPracticeArea } = usePracticeArea();

  let inputRef = useRef(null);

  useEffect(() => {
    if (currentPracticeArea?.stages[4]?.active === true) {
      if (
        currentPracticeArea.alpdsInfo &&
        currentPracticeArea.alpdsInfo.classificationReports
      ) {
        console.log(
          currentPracticeArea.alpdsInfo.classificationReports.nodeReport
        );

        setAllNodes(
          //@ts-ignore
          currentPracticeArea.alpdsInfo.classificationReports.nodeReport
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNodeName(nodeInfo.node_name);
    setKeywords(nodeInfo.keywords.join(","));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let addNewLayer = (e: any) => {
    let new_layer = calculateNextLayer(nodeInfo, nodes);
    setLayers([...layers.slice(0, nodeInfo.node_level + 1), new_layer]);
  };

  let update = () => {
    updateNode(nodeInfo._id, node_name, keywords)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
        toast("Internal server error : Node update", {
          type: "error",
        });
        setNotifications([
          ...notifications,
          "Internal server error : Node update",
        ]);
      });
    let new_state = updateNodeProp(
      nodeInfo,
      {
        node_name: node_name,
        keywords: keywords.split(",").filter((n) => n.trim() !== ""),
      },
      nodes,
      layers
    );
    setNodes(new_state.nodes);
    setLayers(new_state.layers);
  };

  let pencilClick = (e: any) => {
    //@ts-ignore
    inputRef.current.focus();
    setEditable(true);
  };

  let handleUpdate = () => {
    dispatch({ type: SET_UPDATE_ACTIVE, payload: { action: true } });
    history.push("/app/pa/update");
  };
  let handleFeedbackClick = (tab: number) => {
    dispatch({
      type: SET_FEEDBACK_DETAILS,
      payload: {
        tab: tab,
        node_id: nodeInfo.node_id,
        node_name: nodeInfo.node_name,
        //@ts-ignore
        accuracy: getNodeInfo(nodeInfo.node_id)["f1-score"] * 100,
      },
    });
    handleUpdate();
  };
  let MasterlistMenu = () => {
    return (
      <Menu
        style={{
          borderRadius: 6,
          overflow: "hidden",
          boxShadow: "0px 0px 12px rgba(122, 122, 122, 0.15);",
        }}
      >
        <Menu.Item style={{ width: 240 }}>
          <div
            className={"flex space-x-3 items-center p-3 "}
            onClick={() => {
              handleFeedbackClick(0);
            }}
          >
            <img src="/img/seed_para_ml.svg" alt="seed para icon" width={16} />
            <div
              className={"flex-grow"}
              style={{ color: "#7A7A7A", fontSize: 15 }}
            >
              Seed Paragraph
            </div>
          </div>
        </Menu.Item>
        <Menu.Item
          style={{ width: 240 }}
          onClick={() => handleFeedbackClick(1)}
        >
          <div className={"flex space-x-3 items-center p-3 "}>
            <img src="/img/upload_icon.svg" alt="seed para icon" width={16} />
            <div
              className={"flex-grow"}
              style={{ color: "#7A7A7A", fontSize: 15 }}
            >
              Upload Source
            </div>
          </div>
        </Menu.Item>
        <Menu.Item
          style={{ width: 240 }}
          onClick={() => handleFeedbackClick(2)}
        >
          <div className={"flex space-x-3 items-center p-3 "}>
            <img src="/img/cube.svg" alt="seed para icon" width={16} />
            <div
              className={"flex-grow"}
              style={{ color: "#7A7A7A", fontSize: 15 }}
            >
              Review More
            </div>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <>
      <div
        id={nodeInfo._id}
        className={
          "bg-white flex overflow-hidden hover:shadow-md duration-100 cursor-pointer"
        }
        style={{
          width: 320,
          borderRadius: 4,
          border:
            nodeInfo._id === activeNodeState?._id
              ? "1px solid #555"
              : "1px solid transparent",
          userSelect: "none",
        }}
      >
        {nodeInfo.is_model_class && (
          <div style={{ backgroundColor: "#11256D", width: 4 }} />
        )}
        <div
          onClick={(e) => {
            setActiveNodeState(nodeInfo);
            addNewLayer(e);
          }}
          className={"flex-grow flex"}
        >
          <div className={"flex-grow p-2"}>
            <input
              ref={inputRef}
              id={nodeInfo._id + "1"}
              disabled={!editable}
              type="text"
              value={node_name}
              className="w-full font-semibold bg-white outline-none cursor-pointer"
              onChange={(e) => setNodeName(e.target.value)}
              placeholder={"node name"}
              onBlur={() => {
                update();
              }}
            />
            <input
              type="text"
              disabled={!editable}
              value={keywords}
              style={{ color: "#555" }}
              className="w-full bg-white outline-none cursor-pointer"
              onChange={(e) => setKeywords(e.target.value)}
              placeholder={"Please enter keywords"}
              onBlur={() => {
                update();
                setEditable(false);
              }}
            />
          </div>
          {nodeInfo._id === activeNodeState?._id &&
            currentPracticeArea &&
            currentPracticeArea.masterlistLocked === false && (
              <div className={"p-2"}>
                <img
                  onClick={pencilClick}
                  src="/img/pencil.svg"
                  alt="pencil icon"
                  width={20}
                />
              </div>
            )}
        </div>
        {nodeInfo.is_model_class === true &&
          getNodeInfo(nodeInfo.node_id) !== undefined && (
            <Dropdown trigger={["click"]} overlay={MasterlistMenu}>
              <div
                className={
                  "cursor-pointer flex p-3 justify-center items-center"
                }
                style={{
                  backgroundColor: "#EAEAEA",
                  width: 76,
                  color: "#11256D",
                }}
              >
                {
                  //@ts-ignore
                  (getNodeInfo(nodeInfo.node_id)["f1-score"] * 100).toFixed(2)
                }
                %
                <FiChevronDown />
              </div>
            </Dropdown>
          )}
      </div>
    </>
  );
};

export default Node;
