import * as React from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch } from "react-router";
import MainLayout from "../../components/layouts/main-layout";
import { SET_UPDATE_ACTIVE } from "../../store/types/feedback.types";
import usePracticeArea from "../../store/zustand/usePracticeArea";
import Admin from "./Admin";
import Dashboard from "./dashboard/dashboard";
import Dev from "./dev";
import Feedback from "./feedback";
import HelpAndGuideline from "./help-and-guideline";
import Workspace from "./practice-area";
//import socket from "../../realtime";
import useDevLog from "../../store/zustand/useDevLog";

const MainApp: React.FC = () => {
  let { currentPracticeArea } = usePracticeArea();
  const dispatch = useDispatch();

  let devLog = useDevLog();

  // React.useEffect(() => {
  //   socket.on("connect", () => {
  //     devLog.setSocketConnectionStatus(socket.connected);
  //   });
  //   socket.on("disconnect", () => {
  //     devLog.setSocketConnectionStatus(socket.connected);
  //   });
  // }, []);

  React.useEffect(() => {
    dispatch({
      type: SET_UPDATE_ACTIVE,
      payload: {
        action: false,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPracticeArea]);
  return (
    <MainLayout>
      <Switch>
        <Route
          exact
          path={"/app"}
          render={() => <Redirect to={"/app/dashboard"} />}
        />
        {/* {process.env.NODE_ENV === "development" && (
          <Route path={"/app/devlog"} component={Dev} />
        )} */}
        <Route path={"/app/dashboard"} component={Dashboard} />
        <Route path={"/app/pa"} component={Workspace} />
        <Route path={"/app/feedback"} component={Feedback} />
        <Route path={"/app/help_and_guideline"} component={HelpAndGuideline} />
        <Route exact path={"/app/admin"} component={Admin} />
        <Route render={() => <Redirect to={"/not-found"} />} />
      </Switch>
    </MainLayout>
  );
};

export default MainApp;
