import { useEffect, useState } from "react";

export let useDeviceWidth = () => {
  // let [deviceWidth , setDeviceWidth] = useState(window.innerWidth)

  // useEffect(() => {
  //     window.onresize = () => {
  //         setDeviceWidth(window.innerWidth)
  //     }
  // } , [])

  return window.innerWidth;
};
