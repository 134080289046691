import * as React from "react";
import { useState } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import { Input, notification } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { SIGN_IN_URL } from "../../utils/server-urls";
import { useDispatch } from "react-redux";
import { SET_USER } from "../../store/types/auth-types";
import { isEmpty } from "validate.js";
import { toast } from "react-toastify";

interface Credentials {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const history = useHistory();
  const [credentials, setCredentials] = useState<Credentials>({
    email: "",
    password: "",
  });
  const [error, setError] = useState({
    emailErr: "",
    passErr: "",
    captchaErr: "",
  });
  const [captchaDone, setCaptchaDone] = useState<boolean>(false);

  let [signInClicked, setSignInClicked] = useState(false);

  let dispatch = useDispatch();

  //On Input change
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCredentials({
      ...credentials,
      [event.target.name]: event.target.value,
    });
  };

  //On form submit
  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    if (signInClicked === false) {
      if (isEmpty(credentials.email) || isEmpty(credentials.password)) {
        toast("Please check your email id and password and try again", {
          type: "info",
        });
      } else {
        if (captchaDone === true) {
          setSignInClicked(true);
          try {
            let res = await axios.post(SIGN_IN_URL, {
              email: credentials.email,
              password: credentials.password,
            });
            setSignInClicked(false);
            let userInfo = res.data.result.data;
            localStorage.setItem("userInfo", JSON.stringify(userInfo));
            dispatch({
              type: SET_USER,
              payload: userInfo,
            });
            window.location.replace("/");
          } catch (err) {
            setSignInClicked(false);
            toast(
              "Invalid email id and password or you are not approved yet. Please try again or reset your password.",
              { type: "error" }
            );
          }
        } else {
          toast("Please complete the captcha and try again", { type: "info" });
        }
      }
    }
  };

  const verifyCaptcha = () => {
    setError({ ...error, captchaErr: "" });
    setCaptchaDone(true);
  };

  const goToForgotPassword = () => {
    history.push("/auth/forgot-password");
  };
  return (
    <div className=" h-screen flex flex-col justify-center items-center">
      <form
        // className="lg:w-3/5 md:w-4/5 xs:w-9/10"
        onSubmit={handleSubmit}
        className={"w-4/5"}
      >
        <div
          style={{
            marginBottom: "3px",
          }}
          className={"text-4xl font-medium w-full"}
        >
          Sign in to the DataNeuron ALP
        </div>
        <div
          style={{
            marginBottom: "25px",
          }}
          className="font-thin text-lg"
        >
          Don’t have an account?{" "}
          <Link
            style={{
              color: "#0000FF",
            }}
            to="/auth/signup"
            className="underline hover:underline"
          >
            Sign Up
          </Link>
          .
        </div>
        <div
          style={{
            marginBottom: "15px",
          }}
          className="w-9/10"
        >
          <Input
            className={"rounded-md w-full"}
            style={{
              border: "1px solid #C4C4C4",
              padding: "14px",
              fontSize: "14px",
            }}
            name="email"
            value={credentials.email}
            placeholder="Please enter your email address"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e)
            }
          />
          {error.emailErr !== "" ? (
            <p className="text-red-500">{error.emailErr}</p>
          ) : (
            ""
          )}
        </div>
        <div
          style={{
            marginBottom: "15px",
          }}
          className="w-9/10"
        >
          <Input.Password
            name="password"
            style={{
              border: "1px solid #C4C4C4",
              padding: "14px",
              fontSize: "14px",
            }}
            value={credentials.password}
            placeholder="Please enter your password"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e)
            }
            iconRender={(visible: boolean) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            className={"rounded-md w-full"}
          />
          {error.passErr !== "" ? (
            <p className="text-red-500">{error.passErr}</p>
          ) : (
            ""
          )}
          <p
            style={{
              fontSize: "15px",
              color: "#0000FF",
            }}
            className="my-3 cursor-pointer underline"
            onClick={goToForgotPassword}
          >
            Forgot Your Password?
          </p>
        </div>
        <div
          style={{
            marginBottom: "12px",
          }}
          className="w-100 flex"
        >
          <div>
            <ReCAPTCHA
              sitekey="6Ld3LAMcAAAAAEMFc3k4QUu8D5CdeDS9eRfCDcnr"
              onChange={verifyCaptcha}
            />
            {error.captchaErr !== "" ? (
              <p className="text-red-500 text-left">{error.captchaErr}</p>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="w-100 flex">
          <button
            disabled={signInClicked}
            style={{
              backgroundColor: "#32373B",
              fontSize: "19px",
              width: "231px",
              height: "50px",
              padding: "12px 86px",
              display: "flex",
              justifyContent: "center",
            }}
            type="submit"
            className="btn text-white rounded-full my-3 font-thin"
          >
            {signInClicked ? (
              <img width={30} height={30} src="/img/loading_white.svg" />
            ) : (
              "Sign In"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};
export default withRouter(SignIn);
