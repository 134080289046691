import produce from "immer";
import { v4 as uuid } from "uuid";
import { apiNetworkCall } from "../../../utils/api";
import {
  ADD_NODE,
  DELETE_BULK_NODES,
  NODE_UPDATE_BULK,
  UPDATE_NODE,
} from "../../../utils/server-urls";
import { Layer, Node, Wire } from "./interface";

export let injestProperties = (nodes: Array<Node>): Array<Node> => {
  let res: Array<Node> = [];

  nodes.forEach((node) => {
    let _node: Node = {
      ...node,
      active: false,
    };

    res.push(_node);
  });

  return res;
};

export let calculateWires = (
  nodes: Array<Node>,
  parent_node: Node | undefined
): Array<Wire> => {
  if (parent_node !== undefined) {
    let root = document.getElementById("masterlist_holder");
    let offSet = root?.getBoundingClientRect().top;

    let wires: Array<Wire> = [];
    let parent_ele = document.getElementById(parent_node._id);
    let y1 = parent_ele?.getBoundingClientRect().y;

    nodes.forEach((node) => {
      let child_ele = document.getElementById(node._id);
      let y2 = child_ele?.getBoundingClientRect().y;
      let stroke = node.is_model_class ? "green" : "#7a7a7a";
      if (y1 !== undefined && y2 !== undefined && offSet !== undefined) {
        let wire: Wire = {
          y1: y1 - offSet - 25,
          y2: y2 - offSet - 25,
          stroke,
        };
        wires.push(wire);
      } else {
        console.error("Either y1 , y2 or root is undefined");
      }
    });

    return wires;
  } else {
    console.error("Parent is undefined");
    return [];
  }
};

export let calculateFirstLayer = (nodes: Array<Node>): Layer => {
  return {
    id: uuid(),
    level: 0,
    parent: undefined,
    nodes: nodes.filter((node) => node.node_level === 0),
    wires: [],
  };
};

export let deselectForClassificationLocal = (
  nodeId: string,
  nodes: Array<Node>
) => {
  let new_nodes: Array<Node> = produce(nodes, (cpy_node) => {
    cpy_node = cpy_node.map((_node) => {
      if (_node.node_id === nodeId) {
        _node.is_model_class = false;
      }
      return _node;
    });
  });
  return new_nodes;
};

export let calculateNextLayer = (node: Node, nodes: Array<Node>): Layer => {
  return {
    nodes: nodes.filter((_node) => {
      return _node.parent_id === node._id;
    }),
    id: uuid(),
    level: node.node_level + 1,
    parent: node,
    wires: [],
  };
};

export let getNodeInfo = (
  node_id: string | undefined,
  nodes: Array<Node>
): Node | undefined => {
  let node: Node | undefined = undefined;

  let temp = nodes.filter((node) => node._id === node_id);

  if (temp.length !== 0) {
    node = temp[0];
  }
  return node;
};

export let getNodeInfoNodeId = (
  node_id: string | undefined,
  nodes: Array<Node>
): Node | undefined => {
  let node: Node | undefined = undefined;

  let temp = nodes.filter((node) => node.node_id === node_id);

  if (temp.length !== 0) {
    node = temp[0];
  }
  return node;
};

let getAllPreviousNodes = (node: Node, nodes: Array<Node>) => {
  let prev_nodes: Array<Node> = [];
  let curr_node: Node | undefined = getNodeInfo(node.parent_id, nodes);

  while (curr_node !== undefined) {
    prev_nodes.push(curr_node);
    curr_node = getNodeInfo(curr_node.parent_id, nodes);
  }

  return prev_nodes;
};

let getAllNextNodes = (node: Node, nodes: Array<Node>) => {
  let next_nodes: Array<string> = [];

  let calculate = (node: Node) => {
    if (node.is_leaf_node === false) {
      node.child_node_ids.forEach((id) => {
        next_nodes.push(id);
        let node = getNodeInfo(id, nodes);
        if (node !== undefined) calculate(node);
      });
    }
  };

  calculate(node);
  return next_nodes;
};

export interface StateChange {
  nodes: Array<Node>;
  layers: Array<Layer>;
}

export let addNewNode = (
  layers: Array<Layer>,
  nodes: Array<Node>,
  keywords: string,
  parent_id: string | undefined,
  masterlistId: string,
  level: number
) => {
  return new Promise<StateChange>(async (resolve, reject) => {
    await apiNetworkCall(ADD_NODE, "POST", {
      node_name: "name" + nodes.length,
      keywords: keywords.split(","),
      parent_id: parent_id,
      masterlistId: masterlistId,
      is_model_class: true,
      node_level: String(level),
    })
      .then((res: any) => {
        let new_node: Node = res.data;
        new_node.active = false;

        /**
         * NODE ADDITION
         * STEP 1 - Go to all the preivous nodes [parent to parent] and mark them as non modal and non leaf node
         * STEP 2 - Add the new node to the nodes array
         * STEP 3 - Add the new node to the layers by finding the correct layer
         * STEP 4 - Update it in the server
         *
         * One thing more we also have to push the node to the nodes array of it's parent
         */

        //step 1
        let allPreviousNodesIds = getAllPreviousNodes(new_node, nodes).map(
          (n) => n._id
        );

        let new_nodes: Array<Node> = [];
        nodes.forEach((node) => {
          if (allPreviousNodesIds.indexOf(node._id) !== -1) {
            if (node._id === parent_id) {
              let temp: Node = {
                ...node,
                is_leaf_node: false,
                is_model_class: false,
                child_node_ids: [...node.child_node_ids, new_node._id],
              };

              new_nodes.push(temp);
            } else {
              let temp: Node = {
                ...node,
                is_leaf_node: false,
                is_model_class: false,
              };
              new_nodes.push(temp);
            }
          } else {
            new_nodes.push(node);
          }
        });

        //step - 2
        new_nodes.push(new_node);

        let new_layers: Array<Layer> = [];

        layers.forEach((layer) => {
          let layer_nodes: Array<Node> = [];
          layer.nodes.forEach((node) => {
            if (allPreviousNodesIds.indexOf(node._id) !== -1) {
              if (node._id === parent_id) {
                let temp: Node = {
                  ...node,
                  is_leaf_node: false,
                  is_model_class: false,
                  child_node_ids: [...node.child_node_ids, new_node._id],
                };

                layer_nodes.push(temp);
              } else {
                let temp: Node = {
                  ...node,
                  is_leaf_node: false,
                  is_model_class: false,
                };
                layer_nodes.push(temp);
              }
            } else {
              layer_nodes.push(node);
            }
          });

          //---- step - 3 ----
          if (layer.level === level) {
            layer_nodes.push(new_node);
          }
          //-------------------
          let temp = {
            ...layer,
            nodes: layer_nodes,
          };
          new_layers.push(temp);
        });

        //updating this in the server as well [step - 4]
        apiNetworkCall(NODE_UPDATE_BULK, "POST", {
          nodes: allPreviousNodesIds,
          props: {
            is_leaf_node: false,
            is_model_class: false,
          },
        })
          .then((res: any) => {
            //node updated bulk
          })
          .catch((err) => {
            console.error(err);
          });

        resolve({
          nodes: new_nodes,
          layers: new_layers,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export let updateNodeProp = (
  old_node: Node,
  propsToUpdate: any,
  nodes: Array<Node>,
  layers: Array<Layer>
): StateChange => {
  //updating the nodes state first
  let new_nodes = nodes.map((node) => {
    if (node._id === old_node._id) {
      return {
        ...node,
        ...propsToUpdate,
      };
    }

    return node;
  });

  let new_layers: Array<Layer> = [];

  layers.forEach((layer) => {
    layer.nodes.map((node) => {
      if (node._id === old_node._id) {
        return {
          ...node,
          ...propsToUpdate,
        };
      }
      return node;
    });

    new_layers.push(layer);
  });

  return {
    nodes: new_nodes,
    layers: new_layers,
  };
};

export let updateNode = (
  node_id: string,
  node_name: string,
  keywords: string
) => {
  return new Promise<StateChange>((resolve, reject) => {
    apiNetworkCall(UPDATE_NODE, "POST", {
      nodeId: node_id,
      updated_node: {
        node_name,
        keywords: keywords.split(","),
      },
    })
      .then((res: any) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export let markAsActive = (
  nodeId: string,
  nodes: Array<Node>,
  layers: Array<Layer>
): StateChange => {
  let new_nodes: Array<Node> = [];

  nodes.forEach((node: Node) => {
    if (node._id === nodeId) {
      let _node: Node = {
        ...node,
        active: true,
      };
      new_nodes.push(_node);
    } else {
      let _node: Node = {
        ...node,
        active: false,
      };
      new_nodes.push(_node);
    }
  });

  let new_layers: Array<Layer> = [];

  layers.forEach((layer) => {
    let new_nodes: Array<Node> = [];

    layer.nodes.forEach((node: Node) => {
      if (node._id === nodeId) {
        let _node: Node = {
          ...node,
          active: true,
        };
        new_nodes.push(_node);
      } else {
        let _node: Node = {
          ...node,
          active: false,
        };
        new_nodes.push(_node);
      }
    });

    let _layer: Layer = {
      ...layer,
      nodes: new_nodes,
    };

    new_layers.push(_layer);
  });

  return {
    nodes: new_nodes,
    layers: new_layers,
  };
};

export let doubleclick = (el: any, onsingle: Function, ondouble: Function) => {
  if (el.target.getAttribute("data-dblclick") === null) {
    el.target.setAttribute("data-dblclick", 1);
    setTimeout(function () {
      if (el.target.getAttribute("data-dblclick") === 1) {
        onsingle();
      }
      el.target.removeAttribute("data-dblclick");
    }, 200);
  } else {
    el.target.removeAttribute("data-dblclick");
    ondouble();
  }
};

export let markAsModalClass = (
  node: Node,
  nodes: Array<Node>,
  layers: Array<Layer>
): StateChange => {
  let allNodesInFlow = [
    ...getAllPreviousNodes(node, nodes).map((node) => node._id),
    ...getAllNextNodes(node, nodes),
  ];

  let new_nodes: Array<Node> = produce(nodes, (cpy_node) => {
    cpy_node = cpy_node.map((_node) => {
      if (allNodesInFlow.find((id) => id === _node._id)) {
        _node.is_model_class = false;
      }

      if (_node._id === node._id) {
        _node.is_model_class = true;
      }

      return _node;
    });
  });

  let new_layers: Array<Layer> = produce(layers, (lyr_cpy) => {
    lyr_cpy = lyr_cpy.map((single_lyr) => {
      single_lyr.nodes = single_lyr.nodes.map((_node) => {
        if (allNodesInFlow.find((id) => id === _node._id)) {
          _node.is_model_class = false;
        }
        if (_node._id === node._id) {
          _node.is_model_class = true;
        }
        return _node;
      });
      return single_lyr;
    });
  });

  //let's update for this node
  apiNetworkCall(UPDATE_NODE, "POST", {
    nodeId: node._id,
    updated_node: {
      is_model_class: true,
    },
  });

  //updating this in the server as well [step - 4]
  apiNetworkCall(NODE_UPDATE_BULK, "POST", {
    nodes: allNodesInFlow,
    props: {
      is_model_class: false,
    },
  })
    .then((res: any) => {
      //node updated bulk
    })
    .catch((err) => {
      console.error(err);
    });

  return {
    nodes: new_nodes,
    layers: new_layers,
  };
};

export let deleteNodes = (
  node: Node,
  nodes: Array<Node>,
  layers: Array<Layer>,
  masterlistId: string
): StateChange => {
  let new_nodes: Array<Node> = [];
  let new_layers: Array<Layer> = [];
  let allNextNodes = getAllNextNodes(node, nodes);

  allNextNodes.push(node._id);

  nodes.forEach((every_node) => {
    if (allNextNodes.indexOf(every_node._id) === -1) {
      if (every_node._id === node.parent_id) {
        let temp_node: Node = {
          ...every_node,
          is_model_class:
            layers[node.node_level].nodes.length === 1
              ? true
              : every_node.is_model_class,
          is_leaf_node:
            layers[node.node_level].nodes.length === 1
              ? true
              : every_node.is_leaf_node,
          child_node_ids: every_node.child_node_ids.filter(
            (id) => id !== node._id
          ),
        };
        new_nodes.push(temp_node);
      } else {
        if (every_node._id === node.parent_id) {
          let temp_node: Node = {
            ...every_node,
            is_leaf_node: true,
            is_model_class: true,
          };
          new_nodes.push(temp_node);
        } else {
          new_nodes.push(every_node);
        }
      }
    }
  });

  layers.forEach((layer) => {
    let layer_nodes: Array<Node> = [];
    layer.nodes.forEach((every_node) => {
      if (allNextNodes.indexOf(every_node._id) === -1) {
        if (every_node._id === node.parent_id) {
          let temp_node: Node = {
            ...every_node,
            is_model_class:
              layers[node.node_level].nodes.length === 1
                ? true
                : every_node.is_model_class,
            is_leaf_node:
              layers[node.node_level].nodes.length === 1
                ? true
                : every_node.is_leaf_node,
            child_node_ids: every_node.child_node_ids.filter(
              (id) => id !== every_node._id
            ),
          };
          layer_nodes.push(temp_node);
        } else {
          if (every_node._id === node.parent_id) {
            let temp_node: Node = {
              ...every_node,
              is_leaf_node: true,
              is_model_class: true,
            };
            layer_nodes.push(temp_node);
          } else {
            layer_nodes.push(every_node);
          }
        }
      }
    });

    if (
      layers[node.node_level].nodes.length === 1 &&
      node.parent_id !== undefined
    ) {
      apiNetworkCall(UPDATE_NODE, "POST", {
        nodeId: node.parent_id,
        updated_node: {
          is_model_class: true,
          is_leaf_node: true,
        },
      })
        .then((res: any) => {
          //updated
        })
        .catch((err) => {
          console.error(err);
        });
    }

    let _layer: Layer = {
      ...layer,
      nodes: layer_nodes,
    };

    new_layers.push(_layer);
  });

  apiNetworkCall(DELETE_BULK_NODES, "POST", {
    nodeIds: allNextNodes,
    masterlistId: masterlistId,
  })
    .then((res) => {
      //delete successfully
    })
    .catch((err) => {
      console.error(err);
    });

  return {
    nodes: new_nodes,
    layers: new_layers,
  };
};

export let markModelClassAsFalse = (
  nodeId: Array<string>,
  nodes: Array<Node>
) => {
  return produce(nodes, (node_cpy) => {
    node_cpy = node_cpy.map((node) => {
      if (nodeId.find((n) => n === node.node_id)) {
        node.is_model_class = false;
      }
      return node;
    });
  });
};
