export let SIGN_UP_CONSTRAINTS = {
    firstName: {
      presence: {
        allowEmpty: false,
      },
      length: { maximum: 50 },
    },
    lastName: {
      presence: {
        allowEmpty: false,
      },
      length: { maximum: 50 },
    },
    orgEmail: {
      email: true,
      presence: {
        allowEmpty: false,
      },
      length: {
        maximum: 100,
      },
    },
    password: {
      presence: {
        allowEmpty: false,
      },
      length: {
        maximum: 100,
        minimum: 3,
      },
    },
    rePassword: {
      presence: {
        allowEmpty: false,
      },
      length: {
        maximum: 100,
      },
    },
    orgName: {
      presence: {
        allowEmpty: false,
      },
      length: {
        maximum: 1000,
      },
    },
    // paymentType: {
    //   presence: {
    //     allowEmpty: false,
    //   },
    //   length: {
    //     maximum: 30,
    //   },
    //},
  };
  
  export let SIGN_IN_CONSTRAINTS = {
    email: {
      email: true,
      presence: {
        allowEmpty: false,
      },
      length: {
        maximum: 150,
      },
    },
    password: {
      presence: {
        allowEmpty: false,
      },
      length: {
        maximum: 100,
        minimum: 3,
      },
    },
  };
  
  
  export let PRACTICE_AREA_CONSTRAINTS = {
    practiceArea : {
      presence : {
        allowEmpty : false
      },
      length : {
        maximum : 300
      }
    },
    acronym : {
      presence : {
        allowEmpty : false
      },
      length : {
        maximum : 300
      }
    },
  }