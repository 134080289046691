import * as React from "react";
import { useState } from "react";
import { /*Upload,*/ Modal, Progress } from "antd";
import axios from "axios";
import {
  DELETE_FILE,
  GET_ALL_UPLOADED_FILES,
  TRIGGER_FILE_SPLIT,
  UPLOAD_FILE_URL,
} from "../../../../../utils/server-urls";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { apiNetworkCall } from "../../../../../utils/api";
import { useRecoilState } from "recoil";
import { NotificationAtom } from "../../../../../components/topbar/notificationAtom";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import usePracticeArea from "../../../../../store/zustand/usePracticeArea";
import ConsoleHelper from "../../../../../utils/consoleHelper";
import ReactGA from "react-ga";
export interface UploadPageProps {
  practiceAreaId: string;
}

export interface UloadedFile {
  extension: string;
  fileId: string;
  fileName: string;
  filePath: string;
  practiceAreaId: string;
  size: number;
  status: string;
  uploadedBy: string;
  _id: string;
}

const UploadPage: React.SFC<UploadPageProps> = () => {
  let { currentPracticeArea } = usePracticeArea();
  let [uploadedFiles, setUploadedFiles] = useState<Array<UloadedFile>>([]);
  let history = useHistory();
  let [notifications, setNotifications] = useRecoilState(NotificationAtom);
  let [uploadBtnActive, setUploadBtnActive] = useState(true);
  let [uploadPercent, setUploadPercent] = useState(0);

  let [showProgress, setShowProgress] = useState(false);

  useEffect(() => {
    // make window scroll top
    window.scrollTo(0, 0);
  }, []);

  let deleteUploadedfile = async (fileId: string) => {
    Modal.warn({
      title: "File will be deleted",
      content: "File will be deleted from the database if you click ok.",
      onOk: async () => {
        try {
          await apiNetworkCall(DELETE_FILE, "post", {
            fileId,
          });
          setUploadedFiles(uploadedFiles.filter((file) => file._id !== fileId));
        } catch (err) {
          toast("Internal Server Error : Deleting the file", {
            type: "error",
          });
          setNotifications([
            ...notifications,
            "Internal Server Error : Deleting the file",
          ]);
        }
      },
    });
  };

  let getUploadedFiles = async () => {
    try {
      let uploadedFiles: any = await apiNetworkCall(
        GET_ALL_UPLOADED_FILES,
        "post",
        {
          practiceAreaId: currentPracticeArea?.practiceAreaId,
        }
      );
      setFileId([
        uploadedFiles.result.data.uploadedFiles.map((file: any) => file.fileId),
      ]);
      setUploadedFiles(uploadedFiles.result.data.uploadedFiles);
    } catch (err) {
      toast("Internal Server Error : Fetching the files", {
        type: "error",
      });
      setNotifications([
        ...notifications,
        "Internal Server Error : Fetching the files",
      ]);
    }
  };

  useEffect(() => {
    getUploadedFiles();

    ReactGA.pageview(window.location.pathname);

    return () => {
      Modal.destroyAll();
    };
  }, []);

  let [fileId, setFileId] = useState<Array<string>>([]);

  const uploadFile = async (selectedFile: Array<File>) => {
    for (let i = 0; i < selectedFile.length; i++) {
      let currentFile = selectedFile[i];

      let formData = await new FormData();
      formData.append("uploaded_file", currentFile);
      formData.append(
        "practiceAreaId",
        currentPracticeArea?.practiceAreaId || ""
      );
      formData.append("type", "upload");

      try {
        let res = await axios({
          method: "post",
          url: UPLOAD_FILE_URL,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("userInfo") as string).token,
          },
          onUploadProgress: (e) => {
            setUploadPercent(Math.round((100 * e.loaded) / e.total));
          },
        });

        if (res.status === 200) {
          setFileId([...fileId, res.data.saved_file.fileId]);
          setUploadedFiles((files) => [res.data.saved_file, ...files]);
        }
      } catch (err) {
        console.error(err);
      }
    }
    //After the upload is done let's trigger the split para

    await apiNetworkCall(TRIGGER_FILE_SPLIT, "POST", {
      practiceAreaId: currentPracticeArea?.practiceAreaId,
    })
      .then((res) => {
        ConsoleHelper("%c Para splitting called successfully ", "color : blue");
      })
      .catch((err) => {
        console.error(err);
      });

    setUploadBtnActive(true);
  };

  return (
    <div>
      <div
        className="flex justify-around my-10 w-full bg-white"
        style={{ height: "430px" }}
      >
        <div
          className="w-full"
          style={{
            width: "49%",
            minWidth: "250px",
            color: "#11256D",
            borderRight: "1px solid #E0E0E0",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <div className="flex justify-center items-center mx-1 h-full flex-col">
            <div>
              <img
                style={{
                  position: "absolute",
                  transform: "translateX(60px)",
                  pointerEvents: "none",
                }}
                src="/img/upload.svg"
                alt="upload"
              />

              <input
                disabled={!uploadBtnActive}
                style={{ width: 300, height: 140, opacity: 0 }}
                className={"border-dashed border-2 border-green-500"}
                accept={
                  ".csv,.zip,.txt,text/plain,text/csv,application/zip,.pdf"
                }
                type="file"
                multiple
                placeholder={"Select file"}
                onChange={(e) => {
                  if (e.target.files?.length !== 0) {
                    setShowProgress(true);
                    //@ts-ignore
                    uploadFile(e.target.files);
                  }
                }}
              />

              {showProgress && (
                <Progress strokeColor={"#11256D"} percent={uploadPercent} />
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col p-5 overflow-auto w-1/2">
          {uploadedFiles.length !== 0 ? (
            <div>
              {uploadedFiles.map((file: UloadedFile, i: number) => {
                return (
                  <div key={file._id}>
                    <div className={"w-full rounded-md"}>
                      <div
                        style={{
                          borderRadius: "5px 5px 0px 0px",
                          width: "100%",
                          height: 3,
                          backgroundColor: "#ddd",
                        }}
                      >
                        <div
                          style={{
                            borderRadius: "5px 5px 0px 0px",
                            width: `${
                              currentPracticeArea?.alpdsInfo?.percentageStatus
                                ?.dataIngestionProgress[file.fileId] || 0
                            }%`,
                            height: 3,
                            backgroundColor:
                              currentPracticeArea?.alpdsInfo?.percentageStatus
                                ?.dataIngestionProgress[file.fileId] == 100
                                ? "#1BA94C"
                                : "#11256D",
                          }}
                        ></div>
                      </div>
                      <div className={"flex p-2 justify-between"}>
                        <div className="flex">
                          <img
                            src={
                              file.fileName.endsWith(".txt") ||
                              file.fileName.endsWith(".csv")
                                ? "/img/file_icon.svg"
                                : "/img/zip_icon.svg"
                            }
                            width={14}
                            alt=""
                          />
                          <div className="pl-3">{file.fileName}</div>
                        </div>
                        <div>{`${
                          currentPracticeArea?.alpdsInfo?.percentageStatus
                            ?.dataIngestionProgress[file.fileId] || 0
                        }%`}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>
              {uploadedFiles.length === 0 && (
                <div
                  className={
                    "w-full h-56 flex justify-center items-center text-4xl text-gray-300"
                  }
                >
                  NO UPLOADS IN PROGRESS
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className={"flex justify-end"}>
        {currentPracticeArea?.stages[0].active === true && (
          <button
            onClick={() => {
              history.push("/app/pa/ws/masterlist");
            }}
            className={`px-3 cursor-pointer bg-blueprimary text-white hover:bg-white border-transparent mr-4 duration-300 py-2 hover:border-blue-800 border-2 w-56 text-center rounded-md hover:text-blue-800`}
          >
            GO TO MASTERLIST
          </button>
        )}
      </div>
      <div className={"p-3"}>
        <div className="flex">
          <img src="/img/tip.svg" alt="tip image" />
          <div className="ml-3 text-gray-500 border-b-2 border-gray-300 text-xl">
            Tip
          </div>
        </div>
        <br />
        <div className={"text-gray-500 text-lg w-3/5"}>
          The accepted file formats are .csv, .txt and .zip. In order to get a
          better understanding of how the file should be structured, download
          our templates.
        </div>
        <br />
        <div
          onClick={() => {
            let starting_link =
              process.env.NODE_ENV !== "production"
                ? "http://localhost:3000"
                : "https://portal.dataneuron.ai";
            saveAs(starting_link + "/doc/Template_csv_file.csv");
            saveAs(starting_link + "/doc/Template_For_Zip_File.zip");
            saveAs(starting_link + "/doc/Template_Text_File.txt");
          }}
          className={
            "px-3 cursor-pointer hover:bg-blue-800 hover:text-white duration-300 py-2 border-blue-800 border-2 w-56 text-center rounded-md text-blue-800"
          }
        >
          DOWNLOAD TEMPLATES
        </div>
      </div>
    </div>
  );
};

export default UploadPage;
