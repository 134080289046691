import React from "react";
import Modal from "react-modal";
import "./modal.css";

interface PricingModalProps {
  show: boolean;
  setShow: any;
}

const PricingModal: React.FC<PricingModalProps> = ({ show, setShow }) => {
  const customStyles = {
    content: {
      top: "100px",
      height: "88vh",
      zIndex: 10,
      margin: 0,
    },
  };
  return (
    <Modal
      isOpen={show}
      onRequestClose={() => setShow(false)}
      overlayClassName="Overlay Show"
      style={customStyles}
    >
      <div className="w-full bg-white" style={{ fontFamily: "Sk-Modernist" }}>
        <div className="flex justify-center my-5">
          <p className="my-5" style={{ fontSize: "24px", fontWeight: 500 }}>
            Choose the plan that suits you best!
          </p>
        </div>
        <div className="flex justify-around w-full my-5">
          <div
            className="border-2 rounded-lg p-5"
            style={{
              width: "400px",
              height: "630px",
              border: "2px solid #11998E",
            }}
          >
            <p
              className="mt-2 mb-5 w-full text-center"
              style={{ fontSize: "22px", lineHeight: "26.4px" }}
            >
              On Demand
            </p>
            <div className="w-full text-center">
              <p
                className="my-5"
                style={{
                  fontSize: "16px",
                  lineHeight: "19px",
                  color: "#9A9A9A",
                  width: "320px",
                }}
              >
                Best suited for smaller projects with the flexibility to pay as
                you go.
              </p>
            </div>
            <p
              className="mt-7 mb-5 w-full text-center"
              style={{ fontSize: "16px", lineHeight: "19px", fontWeight: 700 }}
            >
              Flexible
            </p>
            <div
              className="flex justify-center  w-full"
              style={{ fontSize: "16px", lineHeight: "19px", color: "#11256D" }}
            >
              <div
                style={{ height: "1px", background: "#C4C4C4", width: "90%" }}
              ></div>
            </div>
            <div
              className="flex justify-center  w-full"
              style={{ fontSize: "16px", lineHeight: "19px", color: "#11256D" }}
            >
              <div className="flex my-3" style={{ width: "90%" }}>
                <img src="/img/messageIcon.svg" alt=""></img>
                <p className="ml-5">
                  $0.1 Per Annotation for every correct validation
                </p>
              </div>
            </div>
            <div
              className="flex justify-center  w-full"
              style={{ fontSize: "16px", lineHeight: "19px", color: "#11256D" }}
            >
              <div className="flex my-3" style={{ width: "90%" }}>
                <img src="/img/barIcon.svg" alt=""></img>
                <p className="ml-5">$0.05 Per Paragraph Prediction</p>
              </div>
            </div>
            <div
              className="flex justify-center  w-full"
              style={{ fontSize: "16px", lineHeight: "19px", color: "#11256D" }}
            >
              <div className="flex my-3" style={{ width: "90%" }}>
                <img src="/img/forkIcon.svg" alt=""></img>
                <p className="ml-5">$150 Per Month, Per Master List</p>
              </div>
            </div>
            <div
              className="flex justify-center  w-full"
              style={{ fontSize: "16px", lineHeight: "19px", color: "#11256D" }}
            >
              <div
                style={{ height: "1px", background: "#C4C4C4", width: "90%" }}
              ></div>
            </div>
            <p
              className="w-full text-center my-5"
              style={{ fontSize: "16px", fontWeight: 700 }}
            >
              Add Ons
            </p>
            <div
              className="w-full flex justify-center"
              style={{ marginTop: "-20px" }}
            >
              <div
                style={{
                  fontSize: "16px",
                  lineHeight: "19px",
                  color: "#11256D",
                  width: "90%",
                }}
              >
                <div className="flex my-3 items-center">
                  <div
                    className="bg-black"
                    style={{ width: "5px", height: "5px", borderRadius: "5px" }}
                  ></div>
                  <p className="ml-5">
                    0.1$ for every Batch API Call (upto 5 Data Points)
                  </p>
                </div>
                <div className="flex my-3 items-center">
                  <div
                    className="bg-black"
                    style={{ width: "5px", height: "5px", borderRadius: "5px" }}
                  ></div>
                  <p className="ml-5">
                    For every single data-point API call: $0.03
                  </p>
                </div>
              </div>
            </div>
            <div
              className=" flex justify-center text-center cursor-pointer"
              style={{ marginTop: "85px" }}
            >
              <div
                className="px-7 py-3 hover:shadow-md hover:bg-gray-50"
                style={{
                  border: "1px solid #11256D",
                  fontSize: "16px",
                  lineHeight: "19px",
                  width: "210px",
                }}
              >
                Choose Plan
              </div>
            </div>
          </div>
          <div
            className="border-2 rounded-lg p-5"
            style={{
              width: "400px",
              height: "630px",
              border: "2px solid #EE0979",
            }}
          >
            <p
              className="mt-2 mb-5 w-full text-center"
              style={{ fontSize: "22px", lineHeight: "26.4px" }}
            >
              Enterprise
            </p>
            <div className="w-full text-center">
              <p
                className="my-5"
                style={{
                  fontSize: "16px",
                  lineHeight: "19px",
                  color: "#9A9A9A",
                  width: "320px",
                }}
              >
                Best suited for enterprise level projects with scalability and
                performance in mind.
              </p>
            </div>
            <p
              className="mt-7 mb-5 w-full text-center"
              style={{ fontSize: "16px", lineHeight: "19px", fontWeight: 700 }}
            >
              $2500/month
            </p>
            <div
              className="flex justify-center  w-full"
              style={{ fontSize: "16px", lineHeight: "19px", color: "#11256D" }}
            >
              <div
                style={{ height: "1px", background: "#C4C4C4", width: "90%" }}
              ></div>
            </div>
            <div
              className="flex justify-center  w-full"
              style={{ fontSize: "16px", lineHeight: "19px" }}
            >
              <div className="flex my-3" style={{ width: "90%" }}>
                <img src="/img/forkIcon.svg" alt=""></img>
                <p className="ml-5">
                  10 master list preparation (10, 100 leaf nodes max in a single
                  master list)
                </p>
              </div>
            </div>
            <div
              className="flex justify-center  w-full"
              style={{ fontSize: "16px", lineHeight: "19px" }}
            >
              <div className="flex my-3" style={{ width: "90%" }}>
                <img src="/img/dbIcon.svg" alt=""></img>
                <p className="ml-5">
                  1GB Text Data Upload (Annotation & Prediction upto 1GB of data
                  uploaded is free)
                </p>
              </div>
            </div>
            <div
              className="flex justify-center  w-full"
              style={{ fontSize: "16px", lineHeight: "19px" }}
            >
              <div className="flex my-3" style={{ width: "90%" }}>
                <img src="/img/dbUploadIcon.svg" alt=""></img>
                <p className="ml-5">
                  Export Datasets, Model through API (chargeable) and Masterlist
                </p>
              </div>
            </div>
            <div
              className="flex justify-center  w-full"
              style={{ fontSize: "16px", lineHeight: "19px", color: "#11256D" }}
            >
              <div
                style={{ height: "1px", background: "#C4C4C4", width: "90%" }}
              ></div>
            </div>
            <p
              className="w-full text-center my-5"
              style={{ fontSize: "16px", fontWeight: 700 }}
            >
              For additional $200 a month:
            </p>
            <div
              className="w-full flex justify-center"
              style={{ marginTop: "-20px" }}
            >
              <div
                style={{ fontSize: "16px", lineHeight: "19px", width: "90%" }}
              >
                <div className="flex my-3 items-center">
                  <div
                    className="bg-black"
                    style={{ width: "5px", height: "5px", borderRadius: "5px" }}
                  ></div>
                  <p className="ml-5">1 Additional Master List</p>
                </div>
                <div className="flex my-3 items-center">
                  <div
                    className="bg-black"
                    style={{ width: "5px", height: "5px", borderRadius: "5px" }}
                  ></div>
                  <p className="ml-5">100MB Additional Data Upload</p>
                </div>
              </div>
            </div>
            <div className="mt-14 flex justify-center text-center cursor-pointer">
              <div
                className="px-7 py-3 text-center hover:shadow-md hover:bg-gray-50"
                style={{
                  border: "1px solid #11256D",
                  fontSize: "16px",
                  lineHeight: "19px",
                  width: "210px",
                }}
              >
                Choose Plan
              </div>
            </div>
          </div>
          <div
            className="border-2 rounded-lg p-5"
            style={{
              width: "400px",
              height: "630px",
              border: "2px solid #2575FC",
            }}
          >
            <p
              className="mt-2 mb-5 w-full text-center"
              style={{ fontSize: "22px", lineHeight: "26.4px" }}
            >
              Natural Language Prediction
            </p>
            <div className="w-full text-center">
              <p
                className="my-5"
                style={{
                  fontSize: "16px",
                  lineHeight: "19px",
                  color: "#9A9A9A",
                  width: "320px",
                }}
              >
                Best suited for large projects with NLP as a main offering to
                users.
              </p>
            </div>
            <p
              className="mt-7 mb-5 w-full text-center"
              style={{ fontSize: "16px", lineHeight: "19px", fontWeight: 700 }}
            >
              Custom Plan
            </p>
            <div
              className="flex justify-center  w-full"
              style={{ fontSize: "16px", lineHeight: "19px", color: "#11256D" }}
            >
              <div
                style={{ height: "1px", background: "#C4C4C4", width: "90%" }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PricingModal;
