import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import usePracticeArea from "../../store/zustand/usePracticeArea";
import { nodesAtom } from "../masterlist/atoms";

interface MasterListCardProps {}

const MasterListCard: React.FC<MasterListCardProps> = () => {
  let { currentPracticeArea } = usePracticeArea();
  let history = useHistory();

  const [nodesStats, setNodesStats] = useState({
    modelNodes: 0,
    leafNode: 0,
    avgNoOfKeywordsPerNode: 0,
    totalNodes: 0,
  });

  let nodes = useRecoilValue(nodesAtom);

  //calculation of the stats of masterlist card
  useEffect(() => {
    let model = 0;
    let leaf = 0;
    let avgKeyWrd = 0;
    let noOfKey = 0;
    nodes.forEach((node, i) => {
      if (node.is_leaf_node === true) {
        leaf += 1;
      }
      if (node.is_model_class === true) {
        model += 1;
        noOfKey += node.keywords[0] !== "" ? node.keywords.length : 0;
      }
    });
    avgKeyWrd = noOfKey / model;
    setNodesStats({
      modelNodes: model,
      leafNode: leaf,
      avgNoOfKeywordsPerNode: avgKeyWrd,
      totalNodes: nodes.length,
    });

    return () => {};
  }, [nodes]);

  return (
    <div
      onClick={() => {
        currentPracticeArea?.stages[0].active === true
          ? history.push("/app/pa/ws/masterlist")
          : toast("No uploaded files found!", { type: "warning" });
      }}
      className="p-3 bg-white w-full cursor-pointer"
      style={{ height: "200px" }}
    >
      <div className="flex space-x-2">
        <img
          width={17}
          height={22}
          src="/img/masterListBlue.svg"
          alt="validate"
        ></img>
        <p style={{ fontWeight: 500, fontSize: "15px" }}>MasterList</p>
      </div>
      {!nodes.length ? (
        <div className="w-full flex justify-center cursor-pointer my-14">
          <div
            className={`flex justify-center items-center text-center cursor-${
              currentPracticeArea?.stages[0].active === true
                ? "pointer"
                : "not-allowed"
            } ${
              currentPracticeArea?.stages[0].active === true
                ? "hover:bg-primarycolor hover:text-white text-primarycolor"
                : "bg-gray-300 text-white"
            }`}
            onClick={() =>
              currentPracticeArea?.stages[0].active === true
                ? history.push("/app/pa/ws/masterlist")
                : toast("Upload some files before creating a masterlist!", {
                    type: "warning",
                  })
            }
            style={{
              border: `1px solid ${
                currentPracticeArea?.stages[0].active === true
                  ? "#2C2C2C"
                  : "white"
              }`,
              fontSize: "16px",
              width: "250px",
              height: "30px",
            }}
          >
            Create Masterlist
          </div>
        </div>
      ) : (
        <div className="my-4" style={{ color: "#7A7A7A", fontSize: "13px" }}>
          <div className="flex justify-between">
            <p>Model Nodes </p>
            <p>{nodesStats.modelNodes}</p>
          </div>
          <Divider className="my-2" />
          <div className="flex justify-between">
            <p>Leaf Nodes </p>
            <p>{nodesStats.leafNode}</p>
          </div>
          <Divider className="my-2" />
          <div className="flex justify-between">
            <p>Total Nodes </p>
            <p>{nodesStats.totalNodes}</p>
          </div>
          <Divider className="my-2" />
          <div className="flex justify-between">
            <p>Average Keyword Per Node </p>
            <p>{nodesStats.avgNoOfKeywordsPerNode.toFixed()} (approx.)</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default MasterListCard;
