import { Divider, Dropdown, Menu } from "antd";
import * as React from "react";
import { Link, Route, Switch } from "react-router-dom";
import Deploy1 from "./deploy1";
import Deploy2 from "./deploy2";
import Deploy3 from "./deploy3";
import APIDocumentation from "./deploy4";
import { DownOutlined } from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import usePracticeArea from "../../../../../../store/zustand/usePracticeArea";
import { PARA_COUNT_URL } from "../../../../../../utils/server-urls";
import { apiNetworkCall } from "../../../../../../utils/api";
import { useRecoilValue } from "recoil";
import { nodesAtom } from "../../../../../../components/masterlist/atoms";
import { toast } from "react-toastify";
import ReactGA from "react-ga";

export interface DeploymentScreenProps {}
const menu = (
  <Menu>
    <Menu.Item key="1">Version 1</Menu.Item>
    <Menu.Item key="2">Version 2</Menu.Item>
    <Menu.Item key="3">Version 3</Menu.Item>
    <Menu.Item key="3">Version 4</Menu.Item>
  </Menu>
);

const DeploymentScreen: React.SFC<DeploymentScreenProps> = () => {
  const [active, setActive] = React.useState<Number>(0);
  let prediction = "/img/prediction.svg";
  let prediction_grey = "/img/prediction_grey.svg";
  let prediction_service = "/img/prediction_service.svg";
  let prediction_service_grey = "/img/prediction_service_grey.svg";
  let export_dataset = "/img/export_dataset.svg";
  let export_dataset_grey = "/img/export_dataset_grey.svg";
  let file_grey = "/img/file_grey.svg";
  let file_white = "/img/file_white.svg";

  let { currentPracticeArea } = usePracticeArea();

  let history = useHistory();

  let params = useLocation();

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname);

    if (params.pathname.slice(27, 45) === "dep") {
      setActive(0);
    } else if (params.pathname.slice(27, 45) === "dep/export") {
      setActive(1);
    } else if (params.pathname.slice(27, 45) === "dep/prediction") {
      setActive(2);
    }
    if (
      currentPracticeArea?.stages.filter(
        (stage) => stage.name === "MODEL TRAINING"
      )[0].active === false
    ) {
      history.replace("/app/pa/");
    }
  }, []);

  let nodes = useRecoilValue(nodesAtom);

  let [info, setInfo] = React.useState({
    totalParas: 0,
    totalTrainingParas: 0,
    totalNoClasses: 0,
    totalDocs: 0,
  });

  // for calculating the infos
  React.useEffect(() => {
    apiNetworkCall(PARA_COUNT_URL, "post", {
      practiceAreaId: currentPracticeArea?.practiceAreaId,
    })
      .then((data: any) => {
        let totalDocs = data.data.fileUploaded as number;
        let totalParas = data.data.totalPara as number;

        let totalClasses = nodes.filter(
          (node) => node.is_model_class === true
        ).length;
        let totalTrainingParas =
          currentPracticeArea?.alpdsInfo?.trainInfo?.trainingParas || 0;

        setInfo({
          totalParas: totalParas,
          totalTrainingParas: totalTrainingParas,
          totalNoClasses: totalClasses,
          totalDocs: totalDocs,
        });
      })
      .catch((err) => {
        toast("There was some error during fetching total number of paras", {
          type: "error",
        });
      });
  }, []);

  return (
    <div className="w-full mt-6" style={{ minHeight: "65vh" }}>
      <div className="w-full flex justify-around">
        <div className="h-20 w-1/4">
          <div className="uppercase font-light">Total Paragraphs</div>
          <div className="font-bold">{info.totalParas}</div>
        </div>
        <div className="h-20 w-1/4">
          <div className="uppercase font-light">
            {" "}
            Number of training paragraphs
          </div>
          <div className="font-bold">{info.totalTrainingParas}</div>
        </div>
        <div className="h-20 w-1/4">
          <div className="uppercase font-light">Number of nodes</div>
          <div className="font-bold">{info.totalNoClasses}</div>
        </div>
        <div className="h-20 w-1/4">
          <div className="uppercase font-light">Total number of documents</div>
          <div className="font-bold">{info.totalDocs}</div>
        </div>
      </div>

      <Divider className={"mt-0 pt-0"} />

      <div className="flex justify-between">
        <p className="w-full font-sans text-lg text-skin-darkGrey pb-5">
          Deployment Options
        </p>

        <Dropdown overlay={<></>}>
          <div
            className="px-5 flex justify-between items-center rounded-md"
            style={{
              width: "200px",
              height: "38px",
              background: "#EAEAEA",
              fontSize: "15px",
              fontWeight: 400,
            }}
          >
            <p>Version 1</p>
            <DownOutlined />
          </div>
        </Dropdown>
      </div>
      <Divider style={{ margin: "0" }} />

      <div className="flex my-5 w-full">
        <div className="w-1/4 mr-5">
          <Link to={"/app/pa/ws/model-training2/dep"}>
            <div
              className="flex cursor-pointer p-3 rounded-md my-3 shadow-md hover:shadow-lg duration-150"
              style={
                active === 0
                  ? { background: "#11256D", width: "320px" }
                  : {
                      background: "#FFF",
                      width: "280px",
                    }
              }
              onClick={() => setActive(0)}
            >
              <img
                src={active === 0 ? prediction : prediction_grey}
                alt="prediction"
                className="mx-2"
              ></img>
              <p
                className={`${
                  active === 0 ? "text-white" : "text-skin-darkGrey"
                } mx-2 text-base`}
              >
                Prediction on Ingested Data
              </p>
            </div>
          </Link>
          <Link to={"/app/pa/ws/model-training2/dep/export"}>
            <div
              className="flex cursor-pointer p-3 rounded-md my-3 shadow-md hover:shadow-lg duration-150"
              style={
                active === 1
                  ? { background: "#11256D", width: "320px" }
                  : {
                      background: "#FFF",
                      width: "280px",
                    }
              }
              onClick={() => setActive(1)}
            >
              <img
                src={active === 1 ? export_dataset : export_dataset_grey}
                alt="prediction"
                className="mx-2"
              ></img>
              <p
                className={`${
                  active === 1 ? "text-white" : "text-skin-darkGrey"
                } mx-2 text-base`}
              >
                Export Dataset
              </p>
            </div>
          </Link>

          <Link to={"/app/pa/ws/model-training2/dep/prediction"}>
            <div
              className="flex cursor-pointer p-3 rounded-md my-3 shadow-md hover:shadow-lg duration-150"
              style={
                active === 2
                  ? { background: "#11256D", width: "320px" }
                  : {
                      background: "#FFF",
                      width: "280px",
                    }
              }
              onClick={() => setActive(2)}
            >
              <img
                src={
                  active === 2 ? prediction_service : prediction_service_grey
                }
                alt="prediction"
                className="mx-2"
              ></img>
              <p
                className={`${
                  active === 2 ? "text-white" : "text-skin-darkGrey"
                } mx-2 text-base`}
              >
                Model API
              </p>
            </div>
          </Link>

          <Link to={"/app/pa/ws/model-training2/dep/doc"}>
            <div className="flex w-full ml-8 " onClick={() => setActive(3)}>
              <img
                src="/img/arrow_deployment.svg"
                alt="Api documentation"
              ></img>
              <div
                className={`bg-white flex cursor-pointer p-3 rounded-md ml-1 shadow-md hover:shadow-lg duration-150`}
                style={
                  {
                    background: `${active === 3 ? "#11256D" : "#FFF"}`,
                    boxShadow: `${
                      active === 3
                        ? "0px 0px 8px rgba(122, 122, 122, 0.25)"
                        : ""
                    }`,
                    width: 220,
                  }
                  // active === 2
                  // 	? { background: "#FFF", width: "260px" }
                  // 	: { background: "#FFF", width: "220px", boxShadow: "0px 0px 8px rgba(122, 122, 122, 0.25)" }
                }
              >
                <img
                  src={active === 3 ? file_white : file_grey}
                  alt="prediction"
                  className="mx-2"
                ></img>
                <p
                  className={`${
                    active === 3 ? "text-white" : "text-skin-darkGrey"
                  } mx-2 text-base`}
                >
                  API Documentation
                </p>
              </div>
            </div>
          </Link>
        </div>
        <div className="flex-grow mx-5 w-3/4">
          {/* main context go here */}
          {/* <Deploy3/> */}
          <Switch>
            <Route
              path={"/app/pa/ws/model-training2/dep"}
              exact
              component={Deploy1}
            />
            <Route
              path={"/app/pa/ws/model-training2/dep/export"}
              exact
              component={Deploy2}
            />
            <Route
              path={"/app/pa/ws/model-training2/dep/prediction"}
              exact
              component={Deploy3}
            />
            <Route
              path={"/app/pa/ws/model-training2/dep/doc"}
              exact
              component={APIDocumentation}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default DeploymentScreen;
