import * as React from "react";
export interface APIDocumentationProps {}

const APIDocumentation: React.SFC<APIDocumentationProps> = () => {
  return (
    <div style={{ width: "95%" }}>
      <div>
        <h1 className="font-medium text-3xl mb-5">DataNeuron Prediction API</h1>
        <p className="text-lg mb-5">
          Welcome to the DataNeuron Prediction API Documentation! This page will
          guide you through some general information about the terminology and
          about formatting and authenticating requests. You can use the menu on
          the left to jump to the documentation of the endpoints directly.
        </p>
      </div>
      <div>
        <h2
          className="font-medium text-2xl mb-5 border-b border-black"
          style={{ width: "110px" }}
        >
          Overview
        </h2>
        <p className="text-lg mb-5">
          To authenticate requests, you need to generate an API key on the
          DataNeuron website. Requests are authenticated using the
          'x-access-token' header.
        </p>
      </div>
      <div>
        <h2
          className="font-medium text-2xl mb-5 border-b border-black "
          style={{ width: "180px" }}
        >
          Authentication
        </h2>
        <p className="text-lg mb-5">
          We provide APIs as well as PyPI package support for performing
          predictions on new data using your model trained on the DataNeuron
          website. This document will walk you through the steps needed to make
          use of this feature.
        </p>
      </div>
      <div>
        <h2
          className="font-medium text-2xl mb-5 border-b border-black"
          style={{ width: "165px" }}
        >
          PyPI Support
        </h2>
        <p className="text-lg mb-5">
          We provide a PyPI package for using your trained model for inference
          on new data in order to simplify the process and make it easier for
          the users to integrate.
        </p>

        <p className="text-lg mb-5">
          First, let's install the package using pip:
        </p>
        <p
          className="p-3 text-lg border-2 mb-5"
          style={{
            background: "#EFEFEF",
            width: "360px",
            fontWeight: 100,
            fontFamily: "Roboto mono",
          }}
        >
          pip install alpdataneuron
        </p>
        <p className="text-lg mb-5">
          Now we will configure our package with your API token:
        </p>
        <p
          className="p-3 text-lg border-2 mb-5"
          style={{
            background: "#EFEFEF",
            width: "650px",
            fontWeight: 100,
            fontFamily: "Roboto mono",
          }}
        >
          authentication_response = alpdataneuron.config(API_Token)
        </p>
        <div className="text-lg mb-5">
          Here,
          <ul className="my-5">
            <div></div>
            <li className="mb-3 flex items-center">
              <div
                className="mx-3"
                style={{
                  width: "5px",
                  height: "5px",
                  backgroundColor: "#000",
                  borderRadius: "50%",
                }}
              ></div>
              <p>
                API_Token is a valid API token generated on the DataNeuron
                website.
              </p>
            </li>
            <li className="flex items-center">
              <div
                className="mx-3"
                style={{
                  width: "5px",
                  height: "5px",
                  backgroundColor: "#000",
                  borderRadius: "50%",
                }}
              ></div>
              <p>
                authentication_response is of the form <span>&#123;</span>
                'status': &lt;SUCCESS/FAILURE&gt;, 'response':
                &lt;"Authorization complete/failed"&gt;<span>&#125;</span>.
              </p>
            </li>
          </ul>
        </div>
        <p className="text-lg mb-5">
          We make predictions using an object of the PredictIssue class:
        </p>
        <p
          className="p-3 text-lg border-2 mb-5"
          style={{
            background: "#EFEFEF",
            width: "450px",
            fontWeight: 100,
            fontFamily: "Roboto mono",
          }}
        >
          clf = alpdataneuron.PredictIssue()
        </p>
        <p className="text-lg mb-5">
          Finally, let's start prediction on your data:
        </p>
        <p
          className="p-3 text-lg border-2 mb-5"
          style={{
            background: "#EFEFEF",
            width: "550px",
            fontWeight: 100,
            fontFamily: "Roboto mono",
          }}
        >
          task_response = clf.predict(practiceArea, data)
        </p>
        <div className="text-lg mb-5">
          Here,
          <ul className="my-5">
            <li className="mb-3 flex items-center">
              <div
                className="mx-3"
                style={{
                  width: "5px",
                  height: "5px",
                  backgroundColor: "#000",
                  borderRadius: "50%",
                }}
              ></div>
              <p>
                practiceArea is a valid practice area name, corresponding to the
                model to be used for prediction.
              </p>
            </li>
            <li className="flex items-center mb-3">
              <div
                className="mx-3"
                style={{
                  width: "5px",
                  height: "5px",
                  backgroundColor: "#000",
                  borderRadius: "50%",
                }}
              ></div>
              <p>
                data is the input data on which prediction is to be performed in
                the form of a dictionary (<span>&#123;</span>id: paraText
                <span>&#125;</span>).
              </p>
            </li>
            <li className="flex items-center">
              <div
                className="mx-3"
                style={{
                  width: "5px",
                  height: "5px",
                  backgroundColor: "#000",
                  borderRadius: "50%",
                }}
              ></div>
              <p>
                task_response is of the form <span>&#123;</span>'status':
                &lt;SUCCESS/FAILURE&gt;, 'response': , 'task_id':{" "}
                <span>&#125;</span>. The task_id is null if status is FAILURE.
              </p>
            </li>
          </ul>
        </div>
        <p className="text-lg mb-5">
          We can also view all our active task's task ids:
        </p>
        <p
          className="p-3 text-lg border-2 mb-5"
          style={{
            background: "#EFEFEF",
            width: "450px",
            fontWeight: 100,
            fontFamily: "Roboto mono",
          }}
        >
          active_tasks = clf.get_active_tasks()
        </p>
        <ul className="my-5 text-lg">
          <li className="mb-3 flex items-center">
            <div
              className="mx-3"
              style={{
                width: "5px",
                height: "5px",
                backgroundColor: "#000",
                borderRadius: "50%",
              }}
            ></div>
            <p>
              This gives you a list of task ids corresponding to all your active
              tasks.
            </p>
          </li>
        </ul>
        <p className="mb-5 text-lg font-medium">
          Wait for the task to finish and fetch the prediction results.
        </p>
        <p
          className="p-3 text-lg border-2 mb-5"
          style={{
            background: "#EFEFEF",
            width: "500px",
            fontWeight: 100,
            fontFamily: "Roboto mono",
          }}
        >
          prediction_result = clf.task_wait(task_id)
        </p>

        <div className="text-lg mb-5">
          Here,
          <ul className="my-5">
            <li className="mb-3 flex items-center">
              <div
                className="mx-3"
                style={{
                  width: "5px",
                  height: "5px",
                  backgroundColor: "#000",
                  borderRadius: "50%",
                }}
              ></div>
              <p>
                task_id is task id of the initiated prediction process, returned
                by the predict function when initiating prediction.
              </p>
            </li>
            <li className="flex mb-3">
              <div
                className="mx-3 my-3"
                style={{
                  width: "7px",
                  height: "5px",
                  backgroundColor: "#000",
                  borderRadius: "50%",
                }}
              ></div>
              <p>
                prediction_result is of the form <span>&#123;</span>'status':
                &lt;SUCCESS/FAILURE&gt;, 'response': , 'predicted_issues':
                &lt;predicted results in the form of a dictionary (id:
                predicted_issue<span>&#125;</span>)&gt;<span>&#125;</span>. The
                value of predicted_issue is null in case of FAILURE state.
              </p>
            </li>
          </ul>
        </div>
        <p className="mb-5 text-lg font-medium">
          Come back at any later point in time to check the status of the task
          at that point of time and fetch the prediction results if the task has
          completed successfully.
        </p>
        <p
          className="p-3 text-lg border-2 mb-5"
          style={{
            background: "#EFEFEF",
            width: "515px",
            fontWeight: 100,
            fontFamily: "Roboto mono",
          }}
        >
          prediction_result = clf.get_results(task_id)
        </p>
        <div className="text-lg mb-5">
          Here,
          <ul className="my-5">
            <li className="mb-3 flex items-center">
              <div
                className="mx-3"
                style={{
                  width: "5px",
                  height: "5px",
                  backgroundColor: "#000",
                  borderRadius: "50%",
                }}
              ></div>
              <p>
                task_id is task id of the initiated prediction process, returned
                by the predict function when initiating prediction.
              </p>
            </li>
            <li className="flex mb-3">
              <div
                className="mx-3 my-3"
                style={{
                  width: "7px",
                  height: "5px",
                  backgroundColor: "#000",
                  borderRadius: "50%",
                }}
              ></div>
              <p>
                prediction_result is of the form <span>&#123;</span>'status':
                &lt;PENDING/SUCCESS/FAILURE&gt;, 'response': ,
                'predicted_issues': &lt;predicted results in the form of a
                dictionary (<span>&#123;</span>id: predicted_issue
                <span>&#125;</span>)&gt;<span>&#125;</span>. The value of
                predicted_issue is null in case of PENDING and FAILURE state.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="text-lg">
        <h2
          className="text-2xl font-medium mb-5 border-black border-b"
          style={{ width: "165px" }}
        >
          API Endpoints
        </h2>
        <p className="font-medium mb-5">
          <span style={{ color: "#FBBB24" }} className="mr-3">
            POST
          </span>
          Inititaing a Prediction Task
        </p>
        <p
          className="p-3 text-lg border-2 mb-5"
          style={{
            background: "#EFEFEF",
            width: "400px",
            fontWeight: 100,
            fontFamily: "Roboto mono",
          }}
        >
          http://20.43.152.82/prediction_API
        </p>
        <p className="mb-5">
          This API endpoint lets you initiate a prediction task on your input
          data.
        </p>
        <div>
          <h3 className="font-medium mb-5">Headers:</h3>
          <ul>
            <li className="flex mb-3 items-center">
              <div
                className="mx-3 my-3"
                style={{
                  width: "5px",
                  height: "5px",
                  backgroundColor: "#000",
                  borderRadius: "50%",
                }}
              ></div>
              <p>x-access-token: valid API token</p>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="font-medium mb-5">Inputs:</h3>
          <ul>
            <li className="flex mb-3 items-center">
              <div
                className="mx-3 my-3"
                style={{
                  width: "5px",
                  height: "5px",
                  backgroundColor: "#000",
                  borderRadius: "50%",
                }}
              ></div>
              <p>
                practiceArea: valid practice area name, corresponding to the
                model to be used for prediction.
              </p>
            </li>
            <li className="flex mb-3 items-center">
              <div
                className="mx-3 my-3"
                style={{
                  width: "5px",
                  height: "5px",
                  backgroundColor: "#000",
                  borderRadius: "50%",
                }}
              ></div>
              <p>
                data: input data on which prediction is to be performed in the
                form of a dictionary (<span>&#123;</span>id: paraText
                <span>&#125;</span>).
              </p>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="font-medium mb-5">Returns:</h3>
          <ul>
            <li className="flex mb-3 items-center">
              <div
                className="mx-3 my-3"
                style={{
                  width: "5px",
                  height: "5px",
                  backgroundColor: "#000",
                  borderRadius: "50%",
                }}
              ></div>
              <p>
                Task id for your initiated task, to be saved and later used for
                checking the status and extracting results.
              </p>
            </li>
          </ul>
        </div>
        <p className="text-md mb-5">
          <span className="font-medium text-xl mr-2">BODY</span>raw
        </p>
        <div
          className="p-3 text-lg border-2 mb-5"
          style={{
            background: "#EFEFEF",
            width: "100%",
            fontWeight: 100,
            fontFamily: "Roboto mono",
          }}
        >
          <p>
            <span>&#123;</span>
          </p>
          <p className="ml-10" style={{ color: "#38A700" }}>
            "practiceArea": "Corporate Law",
          </p>
          <p className="ml-10" style={{ color: "#38A700" }}>
            "data": <span>&#123;</span>"id_1": "paraText_1", "id_2":
            "paraText_2", "id_3":"paraText_3"<span>&#125;</span>
          </p>
          <p>
            <span>&#125;</span>
          </p>
        </div>
        <p className="font-medium">
          Example Request- Initiating a Prediction Task
        </p>
        <div
          className="p-3 border-2 mb-5 "
          style={{
            background: "#000",
            width: "100%",
            fontWeight: 100,
            color: "#EEEADE",
            fontFamily: "Roboto mono",
          }}
        >
          <p>
            <p>
              curl --location --request{" "}
              <span style={{ color: "#FBBB24" }}>POST</span>{" "}
              <span style={{ color: "#38A700" }}>
                'http://0.0.0.0:4000/prediction_API'
              </span>{" "}
              \
            </p>
            <p>
              --header{" "}
              <span style={{ color: "#38A700" }}>
                'x-access-token: API token'
              </span>
              \
            </p>
            <p>
              --data-raw '<span className="text-white">&#123;</span>
            </p>
            <p>
              <span style={{ color: "#38A700" }}>
                "practiceArea": "Corporate Law",
              </span>
            </p>
            <p>
              <span style={{ color: "#38A700" }}>
                "data": <span className="text-white">&#123;</span>"id_1":
                "paraText_1", "id_2": "paraText_2", "id_3":"paraText_3"
                <span className="text-white">&#125;</span>
              </span>
            </p>
            <span className="text-white">&#125;</span>'
          </p>
        </div>
        <p className="font-medium">Example Response - Body (200 OK)</p>
        <div
          className="p-3 border-2 mb-5 "
          style={{
            background: "#000",
            width: "500px",
            fontWeight: 100,
            color: "#EEEADE",
            fontFamily: "Roboto mono",
          }}
        >
          <p>
            Content-Type: text/html;{" "}
            <span style={{ color: "#FBBB24" }}>charset=utf-8</span>
          </p>
          <p>
            Content-Length: <span style={{ color: "#FBBB24" }}>36 </span>
          </p>
          <p>
            Server:{" "}
            <span style={{ color: "#FBBB24" }}>
              Werkzeug/1.0.1 Python/3.6.9
            </span>
          </p>
          <p>
            Date:{" "}
            <span style={{ color: "#FBBB24" }}>
              Wed, 30 Jun 2021 08:52:51 GMT
            </span>
          </p>
        </div>
        <p className="font-medium">Example Response - Header (200 OK)</p>
        <div
          className="p-3 border-2 mb-5 "
          style={{
            background: "#000",
            width: "500px",
            fontWeight: 100,
            color: "#EEEADE",
            fontFamily: "Roboto mono",
          }}
        >
          <p>04f243f0-350a-42d5-bb3c-9c7fb5a2584c</p>
        </div>
        <p className="font-medium">
          <span className="mr-3" style={{ color: "#38A700" }}>
            GET
          </span>
          Check Status and Fetch Results
        </p>
        <p
          className="p-3 text-lg border-2 mb-5"
          style={{
            background: "#EFEFEF",
            width: "570px",
            fontWeight: 100,
            fontFamily: "Roboto mono",
          }}
        >
          http://0.0.0.0:4000/prediction_results/&lg;task_id&gt;
        </p>
        <p className="mb-5">
          This API endpoint enables you to check the status of your prediction
          task and extract the predicted results.
        </p>
        <div>
          <h3 className="font-medium mb-5">Headers:</h3>
          <ul>
            <li className="flex mb-3 items-center">
              <div
                className="mx-3 my-3"
                style={{
                  width: "5px",
                  height: "5px",
                  backgroundColor: "#000",
                  borderRadius: "50%",
                }}
              ></div>
              <p>x-access-token: valid API token</p>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="font-medium mb-5">Inputs:</h3>
          <ul>
            <li className="flex mb-3">
              <div
                className="mx-3 my-3"
                style={{
                  width: "5px",
                  height: "5px",
                  backgroundColor: "#000",
                  borderRadius: "50%",
                }}
              ></div>
              <p>
                task_id: The task id of the initiated prediction process,
                returned by the API endpoint when initiating prediction.
              </p>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="font-medium mb-5">Returns:</h3>
          <ul>
            <li className="flex mb-3">
              <div
                className="mx-3 my-3"
                style={{
                  width: "5px",
                  height: "5px",
                  backgroundColor: "#000",
                  borderRadius: "50%",
                }}
              ></div>
              <p>
                state: The current status of the prediction task
                (PENDING/SUCCESS/FAILURE). Ensure that the correct task id is
                passed as input.
              </p>
            </li>
            <li className="flex mb-3">
              <div
                className="mx-3 my-3"
                style={{
                  width: "5px",
                  height: "5px",
                  backgroundColor: "#000",
                  borderRadius: "50%",
                }}
              ></div>
              <p>
                result: A short description of the current state, in case of
                FAILURE it describes the reason for failure.
              </p>
            </li>
            <li className="flex mb-3">
              <div
                className="mx-3 my-3"
                style={{
                  width: "5px",
                  height: "5px",
                  backgroundColor: "#000",
                  borderRadius: "50%",
                }}
              ></div>
              <p>
                predicted_issues: The predicted results in the form of a
                dictionary (<span>&#123;</span>id: predicted_issue
                <span>&#125;</span>). Its value is null in case of PENDING or
                FAILURE state.
              </p>
            </li>
          </ul>
        </div>
        <p className="font-medium">
          Example Request - Check Status and Fetch Results
        </p>
        <div
          className="p-3 border-2 mb-5 "
          style={{
            background: "#000",
            width: "100%",
            fontWeight: 100,
            color: "#EEEADE",
            fontFamily: "Roboto mono",
          }}
        >
          <p>
            curl --location --request{" "}
            <span style={{ color: "#FBBB24" }}>GET</span>{" "}
            <span style={{ color: "#38A700" }}>
              'http://0.0.0.0:4000/prediction_results/&lt;task_id&gt;'{" "}
            </span>{" "}
            \
          </p>
          <p>
            --header{" "}
            <span style={{ color: "#38A700" }}>
              'x-access-token: API token'
            </span>
            \
          </p>
          <p>--data-raw </p>
        </div>
        <p className="font-medium">Example Response - Body (200 OK)</p>
        <div
          className="p-3 border-2 mb-5 "
          style={{
            background: "#000",
            width: "100%",
            fontWeight: 100,
            color: "#EEEADE",
            fontFamily: "Roboto mono",
          }}
        >
          <p>
            <span>&#123;</span>
          </p>
          <p className="ml-8">
            <span style={{ color: "#38A700" }}>"predicted_issues":</span>{" "}
            <span>&#123;</span>
          </p>
          <p className="ml-14" style={{ color: "#38A700" }}>
            {" "}
            "id_1": "Issue_1",
          </p>
          <p className="ml-14" style={{ color: "#38A700" }}>
            {" "}
            "id_2": "Issue_2",
          </p>
          <p className="ml-14" style={{ color: "#38A700" }}>
            {" "}
            "id_3": "Issue_3"
          </p>
          <p className="ml-8">
            <span>&#125;</span>,
          </p>
          <p className="ml-8" style={{ color: "#38A700" }}>
            "result": "Prediction success",
          </p>
          <p className="ml-8" style={{ color: "#38A700" }}>
            "state": "SUCCESS"
          </p>
          <span>&#125;</span>
        </div>
        <p className="font-medium">Example Response - Header (200 OK)</p>
        <div
          className="p-3 border-2 mb-5 "
          style={{
            background: "#000",
            width: "100%",
            fontWeight: 100,
            color: "#EEEADE",
            fontFamily: "Roboto mono",
          }}
        >
          <p>
            Content-Type:{" "}
            <span style={{ color: "#FBBB24" }}> application/json</span>
          </p>
          <p>
            Content-Length: <span style={{ color: "#FBBB24" }}>206 </span>
          </p>
          <p>
            Server:{" "}
            <span style={{ color: "#FBBB24" }}>
              Werkzeug/1.0.1 Python/3.6.9
            </span>
          </p>
          <p>
            Date:{" "}
            <span style={{ color: "#FBBB24" }}>
              Wed, 30 Jun 2021 09:27:11 GMT
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default APIDocumentation;
