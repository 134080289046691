import React, { useState } from "react";
import { toast } from "react-toastify";

export interface AddPracticeAreaProps {
  setShowModal: any;
  createPaHandler: any;
}

const AddPracticeArea: React.SFC<AddPracticeAreaProps> = (props) => {
  const [creatingPracArea, setCreatingPracArea] = useState(false);

  const [paDetails, setPaDetails] = React.useState<any>({
    name: "",
    acronym: "",
  });
  const [nameErr, setNameErr] = React.useState("");
  const [acErr, setAcErr] = React.useState("");
  const handleChange = (e: any) => {
    if (e.target.name === "name") {
      setNameErr("");
    } else {
      setAcErr("");
    }
    setPaDetails({ ...paDetails, [e.target.name]: e.target.value });
  };
  const handleSubmit = () => {
    setCreatingPracArea(true);
    if (paDetails.name === "") {
      // setNameErr("Practice Area Name Cannot Be Empty");
      toast("Practice Area Name Cannot Be Empty!", { type: "warning" });
    } else if (paDetails.acronym === "") {
      // setAcErr("Abbreviation Cannot Be Empty");
      toast("Abbreviation Cannot Be Empty", { type: "warning" });
    } else {
      props.createPaHandler(paDetails, () => {
        setCreatingPracArea(false);
        props.setShowModal();
      });
    }
  };
  return (
    <div
      id="add"
      className="shadow-md flex flex-col bg-white outline-none focus:outline-none mb-2"
      style={{ width: "99%", height: 150 }}
    >
      {/*body*/}
      <div className="relative px-5 flex mt-8 w-full">
        <div style={{ width: "45%" }}>
          <input
            type="text"
            className="text-base px-5 py-2 border rounded-md w-full"
            placeholder="Enter Scope Name"
            style={{ background: "#FAFAFA" }}
            name="name"
            value={paDetails.name}
            onChange={(e: any) => handleChange(e)}
            autoFocus
          ></input>
          {nameErr !== "" ? (
            <p className="my-2 text-red-500">{nameErr}</p>
          ) : null}
        </div>
        <div style={{ width: "45%" }}>
          <input
            type="text"
            className="text-base px-5 py-2 border rounded-md ml-5 w-full"
            placeholder="Enter Scope Abbreviation"
            style={{ background: "#FAFAFA" }}
            name="acronym"
            value={paDetails.acronym}
            onChange={(e: any) => handleChange(e)}
          ></input>
          {acErr !== "" ? <p className="text-red-500 my-2">{acErr}</p> : null}
        </div>
      </div>
      {/* <div className="flex mx-5 mt-4">
                <Avatar src="/img/avatar_temp.svg"/>
                <Avatar src="/img/avatar_temp.svg"/>
            </div> */}
      {/*footer*/}
      <div className="flex items-center justify-end p-6">
        <button
          className="text-white text-base px-14 py-2 outline-none mx-5 rounded-md"
          type="button"
          onClick={() => props.setShowModal()}
          style={{ background: "#E63946" }}
        >
          CANCEL
        </button>
        <button
          disabled={creatingPracArea}
          className="text-white text-base px-14 py-2 outline-none rounded-md"
          type="button"
          onClick={() => handleSubmit()}
          style={{ background: "#11256D" }}
        >
          {creatingPracArea ? (
            <img
              alt="loading white"
              width={30}
              height={30}
              src="/img/loading_white.svg"
            />
          ) : (
            "CREATE SCOPE"
          )}
        </button>
      </div>
    </div>
  );
};

export default AddPracticeArea;
