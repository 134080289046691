import { Divider } from "antd";
import * as React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { StageContext } from "..";
import RejectedPara from "../../../../../components/data-validation/RejectedPara";
import Stage2Node from "../../../../../components/data-validation/Stage2Node";
import StagePara from "../../../../../components/data-validation/Stage2Para";
import { setActive } from "../../../../../components/workspace-nav/stage-helper";
import { GlobalState } from "../../../../../store/reducers";
import {
  ParaDataType,
  StageNodeType,
} from "../../../../../store/reducers/stage1Reducer";

import LoadingScreen from "../../../../../components/loading-screen";

import {
  INIT_REJECT_PARA,
  REJECT_PARA,
  SET_STAGE2_BATCH2_INIT,
  SET_STAGE2_BATCH3_INIT,
  STAGE2_ADD_PARAS,
  STAGE2_NODES_REQUEST,
  STAGE2_PRE_PARAS,
  STAGE2_PROGRESS_UPDATE,
} from "../../../../../store/types/stage-2-types";
import style from "./stage2.module.css";
import "slick-carousel/slick/slick-theme.css";
import SeedPara from "../../../../../components/data-validation/SeedPara";
import { apiNetworkCall } from "../../../../../utils/api";
import {
  AUTOML_INTEGRATION,
  GET_NODES,
  GET_NODE_PARAS,
  SEED_PARA,
  STAGE_2_INTEGRATE,
} from "../../../../../utils/server-urls";
import StageSummary from "../../../../../components/stage-summary";
import { useRecoilState, useRecoilValue } from "recoil";
import { nodesAtom } from "../../../../../components/masterlist/atoms";
import { NotificationAtom } from "../../../../../components/topbar/notificationAtom";
import { toast } from "react-toastify";
import usePracticeArea from "../../../../../store/zustand/usePracticeArea";
import { getNodeInfo } from "../../../../../components/masterlist/util/helper";
import NoPara from "./NoPara";
import useNerStore from "../../../../../store/zustand/useNerStore";
import ReactGA from "react-ga";

export interface AnnotationCount {
  node_id: string;
  count: number;
}

export interface NodeReport {
  node_name: string;
  validatedParas: number;
  color: string;
  targetParas: number;
  nodeId: string;
  _id: string;
}

export interface ModelClasses {
  modeClassName: string;
  modelClassId: string;
  nodes: Array<NodeReport>;
}

export interface Stage2Props {}

const Stage2: React.FC = () => {
  let stage2 = useSelector((state: GlobalState) => state.stage2);
  let currentPrac = usePracticeArea();
  let [active_node, setActiveNode] = React.useState<
    StageNodeType | undefined
  >();
  let dispatch = useDispatch();
  let [loading, setLoading] = useState(false);
  let { stages, setStages } = useContext(StageContext);
  let [seedPara, setSeedPara] = useState<boolean>(false);
  let [batch, setBatch] = useState<number>(1);
  let [current_paras, setCurrent_paras] = useState<Array<ParaDataType> | null>(
    null
  );
  let [notifications, setNotifications] = useRecoilState(NotificationAtom);
  let history = useHistory();
  const reactSlickRef = React.createRef<any>();
  let [paraEmpty, setParaEmpty] = useState<boolean>(false);

  let [annotationCount, setAnnotationCount] = useState<Array<AnnotationCount>>(
    []
  );
  let [fetching, setFetching] = useState(false);

  let b2 =
    currentPrac.currentPracticeArea?.alpdsInfo?.percentageStatus?.stage2Progress
      ?.batch2Progress;
  let b3 =
    currentPrac.currentPracticeArea?.alpdsInfo?.percentageStatus?.stage2Progress
      ?.batch3Progress;

  useEffect(() => {
    let temp: Array<AnnotationCount> = [];

    stage2.paras.forEach((node: any) => {
      let count = 0;
      node.data.forEach((para: any) => {
        if (para.batchId === batch && para.accepted === "accepted") {
          count += 1;
        }
      });
      temp.push({
        node_id: node.node_id,
        count: count,
      });
    });

    setAnnotationCount(temp);
  }, [stage2.paras, stage2.rejectedParas]);

  let nodesComplete: any = [];
  let comp: any = [];

  useEffect(() => {
    try {
      if (b3 !== undefined) {
        setBatch(3);
      } else if (b2 !== undefined) {
        setBatch(2);
      } else {
        setBatch(1);
      }
    } catch (err) {
      console.error(err);
    }
  }, [currentPrac.currentPracticeArea?.alpdsInfo?.stage2ClassificationReports]);

  let updateComplete = () => {
    let completed = 0;
    for (let i = 0; i < nodesComplete.length; i++) {
      let id = nodesComplete[i].node_id;
      completed = nodesComplete[i].completed_percentage;
      for (let j = 0; j < comp.length; j++) {
        if (comp[j].node_id === id) {
          let c = comp[j].completed;
          comp[j].completed += completed;
          break;
        }
      }
    }
    for (let i = 0; i < comp.length; i++) {
      dispatch({
        type: STAGE2_PROGRESS_UPDATE,
        payload: {
          node_id: comp[i].node_id,
          data: comp[i].completed,
        },
      });
    }
  };

  let [summaryReport, setSummaryReport] = useState<Array<ModelClasses>>([]);
  let nodesInfo = useRecoilValue(nodesAtom);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  let backToTop = () => {
    let ele = document.getElementById("stage_2_paras");
    ele?.scrollTo({ top: 0 });
  };

  useEffect(() => {
    backToTop();
  }, [active_node, batch]);

  let fetchSummary = (batch: number) => {
    try {
      let data = currentPrac.currentPracticeArea;

      let nodes = data?.alpdsInfo.stage2ClassificationReports[`batch${batch}`];

      let groupedNodes: Array<ModelClasses> = [];
      for (const [key, value] of Object.entries(nodes)) {
        let keyInfo = nodesInfo.find((node) => node.node_id === key);

        let dsInfo: any = value;

        if (keyInfo !== undefined) {
          let isPresent =
            groupedNodes.find(
              (n) =>
                n.modelClassId === keyInfo?.parent_id ||
                (n.modeClassName === "root" && keyInfo?.parent_id == null)
            ) !== undefined;

          if (isPresent) {
            groupedNodes = groupedNodes.map((g_nodes) => {
              if (
                (keyInfo !== undefined &&
                  g_nodes.modelClassId == keyInfo.parent_id) ||
                (g_nodes.modelClassId == "root" && keyInfo?.parent_id === null)
              ) {
                let node_report: NodeReport = {
                  _id: keyInfo._id,
                  node_name: keyInfo.node_name,
                  color: dsInfo.color,
                  nodeId: keyInfo.node_id,
                  targetParas: dsInfo.targetParas,
                  validatedParas: dsInfo.validatedParas,
                };
                g_nodes.nodes.push(node_report);

                return g_nodes;
              } else {
                return g_nodes;
              }
            });
          } else {
            let parentInfo = nodesInfo.find(
              (node) => node._id === keyInfo?.parent_id
            );

            if (keyInfo.parent_id && parentInfo !== undefined) {
              let new_modal_group: ModelClasses = {
                modeClassName: parentInfo.node_name,
                modelClassId: parentInfo._id,
                nodes: [
                  {
                    _id: keyInfo._id,
                    node_name: keyInfo.node_name,
                    color: dsInfo.color,
                    nodeId: keyInfo.node_id,
                    targetParas: dsInfo.targetParas,
                    validatedParas: dsInfo.validatedParas,
                  },
                ],
              };

              groupedNodes.push(new_modal_group);
            } else {
              let new_modal_group: ModelClasses = {
                modeClassName: "root",
                modelClassId: "root",
                nodes: [
                  {
                    _id: keyInfo._id,
                    node_name: keyInfo.node_name,
                    color: dsInfo.color,
                    nodeId: keyInfo.node_id,
                    targetParas: dsInfo.targetParas,
                    validatedParas: dsInfo.validatedParas,
                  },
                ],
              };
              groupedNodes.push(new_modal_group);
            }
          }
        }
      }

      setSummaryReport(groupedNodes);
    } catch (err) {}
  };

  let [isAllowed, setIsAllowed] = useState<boolean>(false);

  useEffect(() => {
    setIsAllowed(checkConstarinsts());
  }, [stage2.paras, stage2.rejectedParas, stage2.nodes]);

  let [disable, setDisable] = useState(false);

  let nodes = useRecoilValue(nodesAtom);

  useEffect(() => {
    if (nodes) {
      fetchStage();
    }
  }, [nodes]);

  let addTotalAccepted = (nodeId: string, before: number): number => {
    let total = before;
    let count = 0;

    annotationCount.forEach((ano) => {
      if (ano.node_id === nodeId) {
        count = ano.count;
      }
    });

    return total + count;
  };

  let [isAutoMlClicked, setAutoMlClicked] = useState(false);

  let checkConstarinsts = (): boolean => {
    let count = 0;

    summaryReport.forEach((report) => {
      report.nodes.forEach((nodeInfo) => {
        let total = addTotalAccepted(nodeInfo.nodeId, nodeInfo.validatedParas);
        if (total >= 21) {
          count += 1;
        }
      });
    });

    return count > 1;
  };

  const slideRight = () => {
    reactSlickRef.current.scrollLeft += 100;
  };
  const slideLeft = () => {
    reactSlickRef.current.scrollLeft -= 100;
  };

  let compare = (a: any, b: any) => {
    let paraSecId1 = a.para_info.paraSeqId;
    let paraSecId2 = b.para_info.paraSeqId;
    if (paraSecId1 < paraSecId2) return -1;
    else if (paraSecId1 > paraSecId2) return 1;
    return 0;
  };

  let fetchRejectedParaTag = (para: any, curNodeName: string, issueId: any) => {
    let nodeId = para.alpdsInfo?.rankMetric.stage1.value[1][0];
    let node_info = nodes.filter((nodeInfo) => nodeInfo.node_id === nodeId)[0];
    if (node_info !== undefined) {
      let node_name = node_info.node_name;
      let parentInfo = nodes.filter(
        (nodeInfo) => nodeInfo.node_id === node_info.parent_id
      )[0];

      let parent_name = "No Parent Name";

      if (parentInfo !== undefined) {
        parent_name = parentInfo.node_name;
      }

      let selected = null;
      if (issueId === "No Tag") {
        selected = 0;
      } else if (issueId !== null) {
        selected = 1;
        for (let i = 0; i < stage2.nodes.length; i++) {
          if (nodesComplete[i].node_id === issueId) {
            nodesComplete[i].completed_percentage += 1;
          }
        }
      }
      let nextPredicted = {
        node_name,
        parent_name,
        node_id: nodeId,
        issueId,
        selected,
      };

      dispatch({
        type: REJECT_PARA,
        payload: {
          data: {
            paraData: para.paraData,
            createdAt: para.createdAt,
            paraId: para.paraId,
          },
          nextPredicted,
          curNode: {
            node_name: curNodeName,
          },
        },
      });
    }
  };

  useEffect(() => {
    stage2.paras.map((para) => {
      let { node_id, data } = para;
      if (active_node?.node_id === node_id) {
        let paraNo = data.filter((para: any) => para.batchId === batch);

        if (paraNo.length === 0) {
          setParaEmpty(true);
        } else {
          setParaEmpty(false);
        }
      }
    });
  }, [batch, active_node]);

  let nerStore = useNerStore();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    nerStore.setStage(2);
  }, []);

  let fetchParas = async (node_id: string, node_name: string) => {
    let completed = 0;
    let feedback = 0;

    setFetching(true);

    apiNetworkCall(GET_NODE_PARAS, "post", {
      practiceAreaId: currentPrac.currentPracticeArea?.practiceAreaId,
      predictedIssueId: node_id,
      stage: 2,
    })
      .then((res: any) => {
        let result = res.result.data.paras;
        result.sort(compare);

        //
        let batch2 = result.filter((para: any) => para.batchId === 2);
        let batch3 = result.filter((para: any) => para.batchId === 3);

        if (batch === 2) {
          if (batch2.length === 0) {
          }
        } else if (batch === 3) {
          if (batch3.length === 0) {
          }
        }

        if (batch2.length === 0 && stage2.batch2_init && batch === 1) {
          toast("Target accuracy is achieved no more batches to load", {
            type: "success",
          });

          setNotifications([
            ...notifications,
            "Target accuracy is achieved no more batches to load",
          ]);
          setDisable(true);
        }
        if (batch2.length > 0 && !stage2.batch2_init) {
          dispatch({
            type: SET_STAGE2_BATCH2_INIT,
            payload: { action: true },
          });
        }
        if (batch3.length === 0 && stage2.batch3_init && batch === 2) {
          setDisable(true);
          toast("Target accuracy is achieved no more batches to load", {
            type: "success",
          });
          setNotifications([
            ...notifications,
            "Target accuracy is achieved no more batches to load",
          ]);
        }

        if (batch3.length > 0 && !stage2.batch3_init) {
          dispatch({
            type: SET_STAGE2_BATCH3_INIT,
            payload: { action: true },
          });
        }
        result.map((para: any) => {
          if (para.accepted === "accepted") {
            completed += 1;
          }
          if (para.accepted !== null) {
            feedback += 1;
          }
        });
        let rejected_p = result.filter(
          (para: any) => para.accepted === "rejected"
        );
        comp.push({
          node_id,
          completed,
        });
        dispatch({
          type: STAGE2_ADD_PARAS,
          payload: {
            node_id,
            data: result,
          },
        });
        dispatch({
          type: STAGE2_PROGRESS_UPDATE,
          payload: {
            node_id,
            data: completed,
            goal: result.length,
            // feedback:feedback
          },
        });
        for (let i = 0; i < rejected_p.length; i++) {
          fetchRejectedParaTag(
            rejected_p[i].para_info,
            node_name,
            rejected_p[i].issueId
          );
        }
        setFetching(false);
      })
      .catch((err) => {
        setFetching(false);
      });
  };
  let fetchStage = async () => {
    dispatch({
      type: INIT_REJECT_PARA,
      payload: {},
    });
    try {
      // let data: any = await apiNetworkCall(GET_NODES, "post", {
      //   practiceAreaId: currentPrac.currentPracticeArea?.practiceAreaId,
      //   stage: 2,
      // });
      // stage1.rejected_paras=[];
      //if (data.status === 200) {

      let final_nodes = nodes
        .filter((node) => node.is_model_class)
        .map((fn: any) => {
          // fn.feedback=0;
          return {
            ...fn,
            completed_percentage: 0,
            goal: 0,
          };
        });
      dispatch({
        type: STAGE2_NODES_REQUEST,
        payload: final_nodes,
      });
      setActiveNode(final_nodes[0]);
      // setCurParas();
      let node_populate: Array<any> = [];

      final_nodes.forEach((nd) => {
        nodesComplete.push({
          node_id: nd.node_id,
          completed_percentage: 0,
        });
        node_populate.push({
          node_id: nd.node_id,
          data: [],
        });
      });
      dispatch({
        type: STAGE2_PRE_PARAS,
        payload: node_populate,
      });
      for (let i = 0; i < final_nodes.length; i++) {
        fetchParas(final_nodes[i].node_id, final_nodes[i].node_name);
      }

      updateComplete();
      // } else {
      //   toast("Internal Server Error : Fetching the node", {
      //     type: "error",
      //   });
      //   setNotifications([
      //     ...notifications,
      //     "Internal Server Error : Fetching the node",
      //   ]);
      // }
    } catch (err) {
      console.log(err);

      toast("Internal Server Error : Fetching the node", {
        type: "error",
      });
      // setNotifications([
      //   ...notifications,
      //   "There was some error during fetching the nodes",
      // ]);
    }
  };

  let getText = () => {
    if (batch === 1 && !stage2.batch2_init) {
      return "INIT BATCH2";
    }
    if (batch === 1) {
      return "Load More";
    }
    if (batch === 2 && !stage2.batch3_init) {
      return "INIT BATCH3";
    }
    return "Load More";
  };

  let validateLoadMore = () => {
    if (batch === 3) {
      toast("No more batches available", { type: "success" });
      setNotifications([...notifications, "No more batches available"]);
    } else {
      if (getText() === "INIT BATCH2" || getText() === "INIT BATCH3") {
        if (batch === 1) {
          setBatch((b) => b + 1);
          setLoading(true);

          callBatchPopulate(2);
        } else if (batch === 2) {
          setBatch((b) => b + 1);
          setLoading(true);

          callBatchPopulate(3);
        }
      }
    }
  };

  let setCurParas = () => {
    let temp = [];
    if (stage2 && stage2.paras) {
      //@ts-ignore
      for (let i = 0; i < stage2.paras?.length; i++) {
        //@ts-ignore
        if (stage2.paras[i].node_id === active_node?.node_id) {
          //@ts-ignore
          temp = stage2.paras[i].data;
          break;
        }
      }
      temp = temp.filter((para: any) => para.batchId === batch);
      //
      setCurrent_paras(temp);
    }
  };

  useEffect(() => {
    setCurParas();
  }, [active_node, batch, stage2.paras]);

  let callBatchPopulate = (b: number) => {
    toast(`Batch ${b} initialisation is done.`, { type: "success" });
    setNotifications([...notifications, `Batch${b} initialisation is done.`]);
    apiNetworkCall(STAGE_2_INTEGRATE, "post", {
      practiceAreaId: currentPrac.currentPracticeArea?.practiceAreaId,
      batchNumber: b,
    }).catch((err) => {
      toast(`There was problem with batch${b} paras populations!`, {
        type: "error",
      });
      setNotifications([
        ...notifications,
        `There was problem with batch${b} paras populations!`,
      ]);
    });
  };

  useEffect(() => {
    if (b2 === 100 && b3 === undefined) {
      setLoading(false);

      dispatch({
        type: SET_STAGE2_BATCH2_INIT,
        payload: { action: true },
      });

      fetchStage();
    }

    if ((b2 = 100 && b3 === 100)) {
      setLoading(false);

      dispatch({
        type: SET_STAGE2_BATCH3_INIT,
        payload: { action: true },
      });

      fetchStage();
    }
  }, [b2, b3]);

  useEffect(() => {
    if (nodes.length !== 0) {
      fetchSummary(batch);
    } else {
    }
  }, [b2, b3, batch]);

  if (
    (b2 !== undefined && b2 !== 100) ||
    (b3 !== undefined && b3 !== 100) ||
    loading
  ) {
    return (
      <LoadingScreen
        msg={"Please wait while we generate the next batch for you."}
        compleated={batch === 2 ? b2 || 0 : b3 || 0}
      />
    );
  }

  let handleAddNewPara = (data: any) => {
    const selectedIssueId = active_node?.node_id; // from store
    if (!selectedIssueId) return console.error("Selected Issue id null");
    if (data.newParaTitle.length !== 0 && data.newParaData.length !== 0) {
      const newParaParams = {
        practiceAreaId: currentPrac.currentPracticeArea?.practiceAreaId,
        paraTitle: data.newParaTitle,
        paraData: data.newParaData,
        issueId: selectedIssueId,
      };
      setSeedPara(false);
      try {
        let res = apiNetworkCall(SEED_PARA, "post", newParaParams);
        toast("New para data was successfully added", { type: "success" });
        setNotifications([
          ...notifications,
          "New para data was successfully added",
        ]);
      } catch (err) {
        toast(`Internal Server Error : Seed para`, { type: "error" });
        setNotifications([
          ...notifications,
          "Internal Server Error : Seed para",
        ]);
      }
    }
  };

  return (
    <div style={{ paddingBottom: 100 }}>
      <div className="flex justify-between">
        <div style={{ color: "#7A7A7A", fontSize: 18, marginBottom: 5 }}>
          Tags
        </div>

        <div className={"flex"}>
          <img
            src="/img/tags_arrow_left_selected_grey.svg"
            alt="Arrow Left"
            onClick={slideLeft}
            className="cursor-pointer"
          />
          <img
            src="/img/tags_arrow_right_selected.svg"
            alt="Arrow Right"
            onClick={slideRight}
            className="cursor-pointer"
          />
        </div>
      </div>
      <Divider className={"mt-2 mb-6"} />
      <div className={`${style.cards_container}`}>
        <div
          ref={reactSlickRef}
          className="custom_scroll-x w-full overflow-x-scroll flex space-x-3"
          id="carousel"
        >
          {stage2.nodes?.map((node: any) => {
            return (
              <Stage2Node
                active={node._id === active_node?._id}
                completedPercentage={node.completed_percentage}
                key={node._id}
                goal={node.goal}
                node_name={node.node_name}
                parent_info={
                  getNodeInfo(node.parent_id, nodes)?.node_name || "root"
                }
                onSelect={() => {
                  setActiveNode(node);
                  //setBatch(1);
                }}
              />
            );
          })}
        </div>
      </div>

      <br />
      <div className={"flex border-t border-gray-200"}>
        <div
          style={{ width: "23%" }}
          className={"px-2 border-r border-gray-200"}
        >
          {/* <NerSidebar /> */}

          <StageSummary
            annotationCount={annotationCount}
            allowed={isAllowed}
            openSeed={() => {
              setSeedPara(true);
            }}
            loadMore={() => {
              validateLoadMore();
            }}
            summaryReport={summaryReport}
            batch={batch}
          />

          <div
            onClick={() => {
              history.push("/app/help_and_guideline");
            }}
            className="flex mt-72 items-center cursor-pointer space-x-3 bg-white m-3 p-3 rounded-md"
          >
            <img
              width={20}
              src="/img/help_and_guideline.svg"
              alt="help and guideline pic"
            />
            <div style={{ fontSize: 15, color: "#7A7A7A" }}>
              Help & Guidelines
            </div>
          </div>
        </div>

        <div className={"flex-grow"} style={{ width: "70%" }}>
          <div
            style={{
              color: "#7A7A7A",
              fontSize: 18,
              marginLeft: 10,
              marginTop: 10,
            }}
          >
            Suggested paras
          </div>
          <div>
            <Divider style={{ padding: "0", margin: "10px 0" }} />
          </div>
          <div
            className="overflow-y-scroll custom_scroll"
            style={{ height: "120vh" }}
            id={"stage_2_paras"}
          >
            <div>
              {current_paras && current_paras.length > 0 ? (
                current_paras?.map((paraMain, index) => {
                  //@ts-ignore
                  let para = paraMain.para_info;
                  if (paraMain.accepted === "rejected") return <div></div>;
                  return (
                    <StagePara
                      key={index}
                      active_node_id={active_node?.node_id}
                      accepted={paraMain.accepted}
                      node_name={
                        active_node === undefined
                          ? "loading ..."
                          : active_node?.node_name
                      }
                      para_data={para.paraData}
                      time_str={para.createdAt.toString()}
                      paraId={para.paraId}
                      stage={2}
                      predictedIssueId={para.alpdsInfo.predictedIssueId.stage2}
                      nextPredictedIssueId={
                        para.alpdsInfo?.rankMetric.stage2.value[1][0]
                      }
                    />
                  );
                })
              ) : (
                <div className={"mt-40"}>
                  {fetching ? (
                    <div className={"flex justify-center"}>
                      <img
                        src="/img/loading.svg"
                        alt="loading photo"
                        width={30}
                      />
                    </div>
                  ) : (
                    <NoPara
                      isFeedback={false}
                      isAllowed={isAllowed && isAutoMlClicked === false}
                      paraEmpty={paraEmpty || !fetching}
                    />
                  )}
                </div>
              )}
            </div>
          </div>

          {seedPara && <SeedPara handleAddNewPara={handleAddNewPara} />}
          <div className={"w-4/5 flex justify-between"}>
            <button
              className={`flex text-white ${
                !seedPara ? "px-3 py-2" : "justify-center py-2"
              } ml-3 mt-3 rounded-md transform hover:scale-105 duration-200`}
              style={
                seedPara
                  ? {
                      backgroundColor: "#E63946",
                      fontSize: 15,
                      width: "220px",
                    }
                  : {
                      backgroundColor: "#11256D",
                      fontSize: 15,
                      width: "220px",
                    }
              }
              onClick={() => setSeedPara(!seedPara)}
            >
              {!seedPara && (
                <img
                  src="/img/seed_icon.svg"
                  alt="seed para icon"
                  className={"mr-3"}
                />
              )}
              {!seedPara ? "SEED NEW PARAGRAPH" : "CANCEL"}
            </button>
            <button
              onClick={() => {
                validateLoadMore();
              }}
              className={`flex duration-200  text-white px-3 py-2 ml-3 mt-3 rounded-md border-2 border-transparent   ${
                disable
                  ? "bg-gray-400"
                  : "bg-blueprimary hover:text-blueprimary hover:border-blueprimary hover:bg-white"
              }`}
              disabled={disable}
            >
              LOAD MORE
            </button>
          </div>
          <br />
          <div
            style={{ color: "#7A7A7A", fontSize: 18 }}
            className="py-3 border-t border-b pl-3"
          >
            Suggest tags
          </div>
          <br />
          <div>
            {stage2.rejectedParas &&
              stage2.rejectedParas.length > 0 &&
              stage2.rejectedParas.map((para: any, index: number) => {
                if (para.curNode) {
                  return (
                    <RejectedPara
                      node_name={para.curNode?.node_name}
                      para_data={para.data?.paraData}
                      suggestedNodeName={para.nextPredicted?.node_name}
                      suggestedNodeParentName={para.nextPredicted?.parent_name}
                      time_str={para.data?.createdAt?.toString()}
                      model_node_list={"sdfjh"}
                      paraId={para.data?.paraId}
                      stage={2}
                      nodeId={para.nextPredicted?.node_id}
                      key={index}
                      selected={para.nextPredicted.selected}
                    />
                  );
                } else {
                  return <></>;
                }
              })}
          </div>
        </div>
      </div>
      <div className="flex justify-end w-full">
        <button
          disabled={!isAllowed}
          title={
            isAllowed
              ? ""
              : "Validate more than 21 paras for at least 2 classes."
          }
          onClick={async () => {
            if (isAutoMlClicked === false) {
              setAutoMlClicked(true);
              await apiNetworkCall(AUTOML_INTEGRATION, "post", {
                practiceAreaId: currentPrac.currentPracticeArea?.practiceAreaId,
              })
                .then((res) => {})
                .catch((err) => {
                  toast("Internal Server Error : Auto ML integration", {
                    type: "success",
                  });
                  setNotifications([
                    ...notifications,
                    "Internal Server Error : Auto ML integration",
                  ]);
                });
              setStages(setActive(stages, 4));
              history.push("/app/pa/ws/model-training1");
            }
          }}
          className={`flex duration-200  text-white px-3 py-2 ml-3 mt-3 rounded-md border-2 border-transparent   ${
            !isAllowed
              ? "bg-gray-400"
              : "bg-blueprimary hover:text-blueprimary hover:border-blueprimary hover:bg-white"
          }`}
        >
          {isAutoMlClicked ? (
            <img width={30} height={30} src={"/img/loading_white.svg"} />
          ) : (
            "TRAIN PLATFORM"
          )}
        </button>
      </div>
    </div>
  );
};

export default Stage2;
