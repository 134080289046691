import { Input, Select } from "antd";
import axios from "axios";
import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { validate } from "validate.js";
import {
  SIGN_UP_URL,
  VERIFY_EMAIL_ID,
  VERIFY_OTP,
} from "../../utils/server-urls";
import { SIGN_UP_CONSTRAINTS } from "../../utils/validation-contrainsts";
import { SignUpAtom } from "./singup_atom";
import { toast } from "react-toastify";
import useDevLog from "../../store/zustand/useDevLog";

interface SignUpFormData {
  firstName: string;
  lastName: string;
  orgEmail: string;
  password: string;
  rePassword: string;
  orgName: string;
  paymentType: string;
}

const SignUp: React.FC = () => {
  let history = useHistory();

  let setSignUpData = useSetRecoilState(SignUpAtom);
  let [trackingId, setTrackingId] = React.useState("");
  let [otp, setOtp] = React.useState("");

  let [signUpInfo, setSignUpInfo] = React.useState<SignUpFormData>({
    firstName: "",
    lastName: "",
    orgEmail: "",
    password: "",
    rePassword: "",
    orgName: "",
    paymentType: "",
  });

  let verifyEmail = () => {
    axios
      .post(VERIFY_EMAIL_ID, {
        email: signUpInfo.orgEmail,
      })
      .then((res: any) => {
        let data = res.data;

        if (data.result === true) {
          setTrackingId(data.data.trackingId);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let checkOtp = () => {
    if (trackingId !== "" && otp !== "") {
      axios
        .post(VERIFY_OTP, {
          trackingId,
          otp,
        })
        .then((res) => {})
        .catch((err) => {
          console.error(err);
        });
    } else {
      window.alert("no tracking id or otp given");
    }
  };

  let handleChange = (prop: string, val: any) => {
    setSignUpInfo({
      ...signUpInfo,
      [prop]: val,
    });
  };

  let logStore = useDevLog();

  let signUp = async () => {
    let problems = validate(signUpInfo, SIGN_UP_CONSTRAINTS);
    if (problems === undefined) {
      if (signUpInfo.password === signUpInfo.rePassword) {
        try {
          let res = await axios.post(SIGN_UP_URL, {
            firstName: signUpInfo.firstName,
            lastName: signUpInfo.lastName,
            email: signUpInfo.orgEmail,
            designation: "manager",
            password: signUpInfo.password,
            company: signUpInfo.orgName,
          });

          if (res.status === 200) {
            setSignUpData({
              firstName: signUpInfo.firstName,
              lastName: signUpInfo.lastName,
              email: signUpInfo.orgEmail,
            });
            history.push(`/auth/thank_you`);
          }
        } catch (e) {
          logStore.addLog({
            type: "error",
            log: "There was issue during creating your account. Please check your email and if that if already registered please signin",
            data: e,
            inFile: "src/pages/app/dashboard/dashboard.tsx",
          });
          toast(
            "There was issue during creating your account. Please check your email and if that if already registered please signin",
            { type: "success" }
          );
        }
      } else {
        toast("Your passwords does not match", { type: "success" });
      }
    } else {
      toast("Please check your form", { type: "success" });
    }
  };

  return (
    <div
      className={
        "w-full h-screen flex flex-col items-center justify-center px-5"
      }
    >
      <div className={"w-4/5"}>
        <div
          style={{
            padding: "0 12px",
            marginTop: "7px",
            marginBottom: "5px",
          }}
          className={"text-4xl font-medium"}
        >
          Get Started
        </div>
        <div
          style={{
            marginBottom: "30px",
            padding: "0 12px",
          }}
          className="font-thin text-lg"
        >
          Already have an account?{" "}
          <Link
            style={{
              color: "#0000FF",
            }}
            to="/auth/signin"
            className="underline hover:underline"
          >
            Sign In
          </Link>
          .
        </div>
        <div className="flex flex-col gap-3 ">
          <div className="flex w-full px-3 justify-between gap-3">
            <div className={"w-full"}>
              <Input
                placeholder="First Name"
                onChange={(e) => {
                  handleChange(e.target.name, e.target.value);
                }}
                name={"firstName"}
                type={"text"}
                className={"px-3 py-2 rounded-md w-full"}
                style={{
                  border: "1px solid #C4C4C4",
                  padding: "14px",
                  fontSize: "14px",
                }}
              />
            </div>
            <div className={"w-full"}>
              <Input
                onChange={(e) => {
                  handleChange(e.target.name, e.target.value);
                }}
                name={"lastName"}
                placeholder="Last Name"
                type={"text"}
                className={"px-3 py-2 rounded-md w-full"}
                style={{
                  border: "1px solid #C4C4C4",
                  padding: "14px",
                  fontSize: "14px",
                }}
              />
            </div>
          </div>
          <div className="px-3 w-full">
            <div className={"w-full"}>
              <Input
                placeholder="Organisational Email Address"
                onChange={(e) => {
                  handleChange(e.target.name, e.target.value);
                }}
                type={"email"}
                name={"orgEmail"}
                className={"px-3 py-2 rounded-md w-full"}
                style={{
                  border: "1px solid #C4C4C4",
                  padding: "14px",
                  fontSize: "14px",
                }}
              />
              {/* <button
            onClick={() => verifyEmail()}
            className={"px-3 py-2 rounded-md bg-black text-white"}
          >
            send otp
          </button>
          <button
            onClick={() => checkOtp()}
            className={"px-3 py-2 rounded-md bg-black text-white"}
          >
            verify
          </button>{" "}
          <input
            onChange={(e) => setOtp(e.target.value)}
            className={"px-3 py-2 rounded-md }
            style={{
              border: "1px solid #C4C4C4",
              padding: "14px",
              fontSize: "14px",
            }}
            type="text"
            placeholder={"wirte your otp"}
          /> */}
            </div>
          </div>
          <div className="px-3 w-full flex justify-between gap-3">
            <div className={"w-full"}>
              <Input.Password
                placeholder="Password"
                onChange={(e) => {
                  handleChange(e.target.name, e.target.value);
                }}
                name={"password"}
                visibilityToggle
                className={"px-3 py-2 rounded-md w-full"}
                style={{
                  border: "1px solid #C4C4C4",
                  padding: "14px",
                  fontSize: "14px",
                }}
              />
            </div>
            <div className={"w-full"}>
              <Input.Password
                placeholder="Re-Enter password"
                onChange={(e) => {
                  handleChange(e.target.name, e.target.value);
                }}
                name={"rePassword"}
                visibilityToggle
                className={"px-3 py-2 rounded-md w-full"}
                style={{
                  border: "1px solid #C4C4C4",
                  padding: "14px",
                  fontSize: "14px",
                }}
              />
            </div>
          </div>
          <div className="px-3 w-full">
            <div className={"w-full"}>
              <Input
                placeholder="Organization Name"
                onChange={(e) => {
                  handleChange(e.target.name, e.target.value);
                }}
                name={"orgName"}
                type={"text"}
                className={"px-3 py-2 rounded-md w-full"}
                style={{
                  border: "1px solid #C4C4C4",
                  padding: "14px",
                  fontSize: "14px",
                }}
              />
            </div>
          </div>
          <div className="md:flex w-full px-3 items-center gap-3">
            {/* <div>
            <Select
              placeholder="Subscription Type"
              onChange={(e) => {
                handleChange("paymentType", e);
              }}
              className={"w-56"}
              style={{
                border: "1px solid #C4C4C4",
                fontSize: "14px",
                color: "#7A7A7A",
              }}
              // defaultValue={"one_time"}
            >
              <Select.Option value={"as_you_go"}>Pay as you go</Select.Option>
              <Select.Option value={"one_time"}>Select Plan</Select.Option>
            </Select>
          </div> */}
            <div className="flex items-center">
              <Link
                className={
                  "text-bold text-black underline hover:text-black hover:underline"
                }
                to={"/"}
              >
                More information about the Subscription
              </Link>
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: "20px",
          }}
          className="w-100 flex px-3"
        >
          <button
            style={{
              backgroundColor: "#32373B",
              width: "231px",
              height: "50px",
              padding: "12px 80px",
              fontSize: "19px",
            }}
            onClick={() => signUp()}
            className="btn text-white rounded-full my-3 font-thin"
          >
            Sign Up
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
