import { Collapse, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  AnnotationCount,
  ModelClasses,
} from "../../pages/app/practice-area/pa/stage-2";
import { apiNetworkCall } from "../../utils/api";
import { NODE_UPDATE_BULK } from "../../utils/server-urls";
import { NotificationAtom } from "../topbar/notificationAtom";
import { toast } from "react-toastify";
import {
  getNodeInfoNodeId,
  markModelClassAsFalse,
} from "../masterlist/util/helper";
import { nodesAtom } from "../masterlist/atoms";

const { Panel } = Collapse;

interface StageSummayProps {
  summaryReport: Array<ModelClasses>;
  batch: number;
  openSeed: Function;
  loadMore: Function;
  allowed: boolean;
  annotationCount: AnnotationCount[];
}

let StageSummary: React.SFC<StageSummayProps> = ({
  summaryReport,
  annotationCount,
}) => {
  let [selected, setSelected] = useState<Array<string>>([]);

  let [notifications, setNotifications] = useRecoilState(NotificationAtom);

  let [canDeselect, setCanDeselect] = useState(false);
  let [nodes, setNodes] = useRecoilState(nodesAtom);

  let deselectForClassification = () => {
    let temp: Array<string> = [];

    let new_nodes = markModelClassAsFalse(selected, nodes);
    setNodes(new_nodes);

    selected.forEach((node_id) => {
      let node = nodes.find((n) => n.node_id === node_id);

      if (node) {
        temp.push(node._id);
      }
    });

    apiNetworkCall(NODE_UPDATE_BULK, "POST", {
      nodes: temp,
      props: {
        is_model_class: false,
      },
    })
      .then((res: any) => {
        toast("All the selected nodes will not be classified", {
          type: "success",
        });

        setNotifications([
          ...notifications,
          "All the selected nodes will not be classified",
        ]);
      })
      .catch((err) => {
        console.error(err);
      });

    canDeselectForClassification();
  };

  let addTotalAccepted = (nodeId: string, before: number): number => {
    let total = before;
    let count = 0;

    annotationCount.forEach((ano) => {
      if (ano.node_id === nodeId) {
        count = ano.count;
      }
    });

    return count + total;
  };

  let canDeselectForClassification = (): boolean => {
    if (selected.length === 0) return false;

    let totalNodes = 0;

    summaryReport.forEach((report) => {
      report.nodes.forEach((node) => {
        if (
          selected.indexOf(node.nodeId) === -1 &&
          getNodeInfoNodeId(node.nodeId, nodes)?.is_model_class === true
        ) {
          totalNodes++;
        }
      });
    });
    return totalNodes >= 2;
  };

  useEffect(() => {
    setCanDeselect(canDeselectForClassification());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <div style={{ width: "100%" }}>
      {/* main content here */}
      <Collapse ghost>
        <Panel
          header={
            <div className={"text-lg text-skin-lightGrey"}>
              Validation Summary
            </div>
          }
          key={1}
        >
          {summaryReport.map((report) => {
            return (
              <div key={report.modelClassId}>
                <Collapse ghost>
                  <Panel
                    header={
                      <div className="w-full flex">
                        <div className="flex ">
                          <p className="font-sans text-bold text-sm">
                            {report.modeClassName}
                          </p>
                          <img
                            className="mx-5"
                            src="/img/collapse_up.svg"
                            alt="expand"
                          ></img>
                        </div>
                        {/* <p>{}</p> */}
                      </div>
                    }
                    key="1"
                    showArrow={false}
                  >
                    <div className="w-full flex justify-between">
                      <ul>
                        {report.nodes.map((node) => {
                          return (
                            <div
                              key={node._id}
                              className={"border-l border-black"}
                            >
                              <li className="flex items-center py-2">
                                <svg
                                  width={20}
                                  style={{ transform: "translateY(8px)" }}
                                  height={20}
                                >
                                  <path
                                    d={"M 0 2 H 20 2"}
                                    fill={"transparent"}
                                    strokeWidth={1}
                                    stroke={
                                      getNodeInfoNodeId(node.nodeId, nodes)
                                        ?.is_model_class === false
                                        ? "#a1a1a1"
                                        : node.color === "yellow"
                                        ? "#F59F00"
                                        : node.color
                                    }
                                  />
                                </svg>
                                <input
                                  disabled={
                                    getNodeInfoNodeId(node.nodeId, nodes)
                                      ?.is_model_class === false
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked === true) {
                                      setSelected([...selected, node.nodeId]);
                                    } else {
                                      setSelected(
                                        selected.filter(
                                          (data) => data !== node.nodeId
                                        )
                                      );
                                    }
                                  }}
                                  type="checkbox"
                                  className="mx-2"
                                />
                                <p
                                  className="font-sans text-bold text-base mx-2"
                                  style={{
                                    color:
                                      getNodeInfoNodeId(node.nodeId, nodes)
                                        ?.is_model_class === false
                                        ? "#a1a1a1"
                                        : "#000",
                                  }}
                                >
                                  {node.node_name}
                                </p>
                              </li>
                            </div>
                          );
                        })}
                      </ul>
                      <ul className="font-sans text-skin-darkGrey">
                        {report.nodes.map((node) => {
                          return (
                            <li
                              key={node._id}
                              className="flex items-center py-2 cursor-pointer"
                            >
                              <Tooltip
                                title={
                                  addTotalAccepted(
                                    node.nodeId,
                                    node.validatedParas
                                  ) < 21
                                    ? "Low Confidence"
                                    : ""
                                }
                              >
                                <p
                                  style={{
                                    color:
                                      getNodeInfoNodeId(node.nodeId, nodes)
                                        ?.is_model_class === false
                                        ? "#a1a1a1"
                                        : node.color === "yellow"
                                        ? "#F59F00"
                                        : node.color,
                                  }}
                                  className="font-sans text-bold text-base mx-2"
                                >
                                  {addTotalAccepted(
                                    node.nodeId,
                                    node.validatedParas
                                  )}
                                </p>
                              </Tooltip>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </Panel>
                </Collapse>
              </div>
            );
          })}
          <div className={"flex justify-end p-3"}>
            <Tooltip
              title={
                canDeselect
                  ? "Click to deselect for classification"
                  : "You should have atleast 2 model classes for model training."
              }
            >
              <div
                style={{
                  backgroundColor: !canDeselect ? "#c5c5c5" : "#E63946",
                }}
                onClick={() => {
                  if (canDeselect) {
                    deselectForClassification();
                  }
                }}
                className={
                  " py-2 px-3 text-white text-xs cursor-pointer rounded-md ml-3"
                }
              >
                EXCLUDE FROM TRAINING
              </div>
            </Tooltip>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default StageSummary;
