import { Steps } from "antd";
import * as React from "react";
import { formatDate } from "../../utils/helper";
import styles from "./datacard.module.css";
import { useHistory } from "react-router-dom";
import usePracticeArea from "../../store/zustand/usePracticeArea";
import { PracticeArea } from "../../store/reducers/practice-area-reducer";
import { sendPracticeAreaInfo } from "../../realtime";
import useCurrentUser from "../../store/zustand/useCurrentUser";
import { doubleclick } from "../masterlist/util/helper";

export interface NewDataCardProps {
  active: boolean;
  index: Number;
  cardSelectedHandler: any;
  data: PracticeArea;
}

const { Step } = Steps;
// const menu = (
//   <Menu>
//     <Menu.Item>
//       <a
//         target="_blank"
//         rel="noopener noreferrer"
//         href="https://www.antgroup.com"
//       >
//         1st menu item
//       </a>
//     </Menu.Item>
//     <Menu.Item>
//       <a
//         target="_blank"
//         rel="noopener noreferrer"
//         href="https://www.aliyun.com"
//       >
//         2nd menu item
//       </a>
//     </Menu.Item>
//     <Menu.Item>
//       <a
//         target="_blank"
//         rel="noopener noreferrer"
//         href="https://www.luohanacademy.com"
//       >
//         3rd menu item
//       </a>
//     </Menu.Item>
//   </Menu>
// );

const NewDataCard: React.SFC<NewDataCardProps> = (props) => {
  const history = useHistory();

  let { user } = useCurrentUser();

  let pracStore = usePracticeArea();

  const { active, data } = props;

  // @ts-ignore
  let { name, acronym, updatedAt } = data;
  let handleNameClick = () => {
    pracStore.setCurrentPracticeArea(data);
    //@ts-ignore
    sendPracticeAreaInfo(user?.userId || "", data?._id || "");
    history.push(`/app/pa/ws/flow`);
  };
  // let validateAccuracy = () => {
  //   if (accuracy !== undefined && !isNaN(accuracy)) {
  //     return accuracy;
  //   }
  //   return 0;
  // };
  const calcSteps = (stages: any) => {
    let count = 0;
    stages.forEach((stage: any) => {
      if (stage.active) {
        count += 1;
      } else {
        return count;
      }
    });

    return count - 1;
  };
  const currentStage = (stages: any) => {
    let cur = "Completed";
    for (let i = 0; i < stages.length; i++) {
      if (stages[i].active === false) {
        return (cur = stages[i].name);
      }
    }
    return cur;
  };
  return (
    <div
      className={styles.card_container_wrapper}
      style={
        active
          ? {
              boxShadow: "0px 0px 8px rgba(122, 122, 122, 0.25)",
              backgroundColor: "#11256D",
            }
          : {}
      }
      onClick={(e) =>
        doubleclick(
          e,
          () => {},
          () => {}
        )
      }
    >
      <div
        className={`${styles.cards_container} hover:shadow-md duration-150`}
        style={
          active
            ? {
                marginLeft: "6px",
              }
            : {}
        }
      >
        <div
          className={`${styles.project_card}`}
          onClick={() => handleNameClick()}
          style={{ height: 130 }}
        >
          <div className={styles.left_part}>
            <div className={`project-name ${styles.project_name}`}>
              {/* {name} */}
              {name}
            </div>
            <div style={{ paddingTop: 5 }} className={styles.company_name}>
              {/* {type} */}
              {acronym}
            </div>
          </div>
          <div className={styles.right_part}>
            {/* <div className={styles.dropdown_container}>Hello</div> */}
            <div className={`dashboard_v2_steps ${styles.steps}`}>
              <Steps
                progressDot
                // current={this.countSteps(tasks)}
                //TODO make a correct current method
                //@ts-ignore
                current={calcSteps(data.stages)}
                size="small"
              >
                <Step title="" description="" />
                <Step title="" description="" />
                <Step title="" description="" />
                <Step title="" description="" />
                <Step title="" description="" />
              </Steps>
            </div>
            <div className={styles.date_container}>
              <div className={styles.due_date}>
                {/* {`DUE IN  ${moment(endDate).diff(
                  moment(startDate),
                  'days'
                )}  DAYS`} */}
                {/*@ts-ignore */}
                {currentStage(data.stages)}
              </div>
              <div className={styles.last_update_date}>
                LAST UPDATE - {formatDate(updatedAt).date}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewDataCard;
