import { useEffect, useState } from "react";
import usePracticeArea from "../../../store/zustand/usePracticeArea";
import { apiNetworkCall } from "../../../utils/api";
import { GET_MASTERLIST } from "../../../utils/server-urls";
import { Masterlist } from "../util/interface";

export interface MasterlistFetchType {
  loading: boolean;
  data: Masterlist | undefined;
  error: Error | undefined;
}

let useMasterlist = (): MasterlistFetchType => {
  let [loading, setLoading] = useState<boolean>(true);
  let [data, setData] = useState<Masterlist>();
  let [error, setError] = useState<any>();
  const { currentPracticeArea } = usePracticeArea();

  let fetchMasterlist = async () => {
    if (currentPracticeArea !== null) {
      setLoading(true);
      try {
        let masterlist: any = await apiNetworkCall(GET_MASTERLIST, "POST", {
          practiceAreaId: currentPracticeArea.practiceAreaId,
        });
        setData(masterlist.data);
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMasterlist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loading, data, error };
};

export default useMasterlist;
