import create from "zustand";
import { NerStoreType } from "./interface";

let useNerStore = create<NerStoreType>((set) => ({
  stage: 1,
  setStage: (stage: number) => set({ stage }),
  //entities start
  entitesLoading: false,
  setEntitesLoading: (loading) => set({ entitesLoading: loading }),
  entitesError: false,
  setEntitesError: (error) => set({ entitesError: error }),
  entities: [],
  setEntities: (entities) => set({ entities }),
  //entities end
  //words start
  words: [],
  setWords: (words) => set({ words }),
  wordsLoading: false,
  wordsError: false,
  setWordsError: (error) =>
    set({
      wordsError: error,
    }),
  setWordsLoading: (loading) =>
    set({
      wordsLoading: loading,
    }),
}));

export default useNerStore;
