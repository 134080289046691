import React from "react";
import { RiAddLine } from "react-icons/ri";
import Node from "./node";
import { addNewNode, calculateWires } from "./util/helper";
import { Layer as LayerType, Wire } from "./util/interface";
import { useRecoilState, useRecoilValue } from "recoil";
import { layersAtom, masterlistIdAtom, nodesAtom } from "./atoms";
import WireContainer from "./wirecontainer";
import { useState } from "react";
import { useEffect } from "react";
import { NotificationAtom } from "../topbar/notificationAtom";
import { toast } from "react-toastify";
import usePracticeArea from "../../store/zustand/usePracticeArea";
interface LayerProps {
  layerInfo: LayerType;
}

let Layer: React.SFC<LayerProps> = ({ layerInfo }) => {
  let [layers, setLayers] = useRecoilState(layersAtom);
  let [nodes, setNodes] = useRecoilState(nodesAtom);
  let masterlistId = useRecoilValue(masterlistIdAtom);
  let [wires, setWires] = useState<Array<Wire>>([]);
  let [notifications, setNotifications] = useRecoilState(NotificationAtom);

  let { currentPracticeArea } = usePracticeArea();

  useEffect(() => {
    if (layerInfo.parent !== undefined) {
      let _wires = calculateWires(layerInfo.nodes, layerInfo.parent);
      setWires(_wires);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layerInfo.nodes]);

  let [addAllowed, setAddAllowed] = useState(true);

  let addNode = (e: any) => {
    if (addAllowed) {
      setAddAllowed(false);
      addNewNode(
        layers,
        nodes,
        "",
        layerInfo.parent?._id,
        masterlistId?._id || "",
        layerInfo.level
      )
        .then((res) => {
          setNodes(res.nodes);
          setLayers(res.layers);
          setAddAllowed(true);
        })
        .catch((err) => {
          console.error(err);
          toast("Internal Server Error : Add Node", { type: "error" });
          setNotifications([
            ...notifications,
            "Internal Server Error : Add Node",
          ]);
        });
    }
  };

  return (
    <div>
      <div
        style={{
          paddingLeft: layerInfo.level === 0 ? 0 : 100,
          fontSize: 12,
          fontWeight: 700,
          color: "#2C2C2C",
        }}
        className={"w-full border-b mb-2 py-2 mb-200"}
      >
        LEVEL {layerInfo.level + 1}
      </div>
      <div className={"flex"}>
        {layerInfo.level !== 0 && <WireContainer wires={wires} />}
        <div className="space-y-3">
          {layerInfo.nodes.map((node) => {
            return <Node nodeInfo={node} key={node._id} />;
          })}
          {currentPracticeArea &&
            currentPracticeArea.masterlistLocked === false && (
              <div
                onClick={addNode}
                style={{ width: 320, borderRadius: 4, fontSize: 15 }}
                className={
                  "border-2 border-dashed border-gray-400 text-gray-400 flex p-3 items-center cursor-pointer"
                }
              >
                Add <RiAddLine />
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default Layer;
