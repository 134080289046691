import React from "react";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";

interface AccessManagementProps {}

const menu = (
  <Menu>
    <Menu.Item key="1">
      <div className="flex justify-between">
        <p>Project 1</p>
        <input type="checkbox" />
      </div>
    </Menu.Item>
    <Menu.Item key="2">
      <div className="flex justify-between">
        <p>Project 2</p>
        <input type="checkbox" />
      </div>
    </Menu.Item>
    <Menu.Item key="3">
      <div className="flex justify-between">
        <p>Project 3</p>
        <input type="checkbox" />
      </div>
    </Menu.Item>
    <Menu.Item key="3">
      <div className="flex justify-between">
        <p>Project 4</p>
        <input type="checkbox" />
      </div>
    </Menu.Item>
  </Menu>
);
const AccessManagement: React.FC<AccessManagementProps> = () => {
  return (
    <div className="w-full my-8 flex flex-col space-y-8">
      <div className="border border-black py-2 w-36  text-center">
        Add new user
      </div>
      <div
        style={{
          width: "65%",
          boxShadow: "0px 0px 8px rgba(122, 122, 122, 0.15)",
        }}
        className="bg-white p-5"
      >
        <p
          style={{
            fontSize: "15px",
            fontWeight: 500,
            color: "#11256D",
            lineHeight: "18px",
          }}
        >
          New User
        </p>
        <div className="flex my-3 w-4/5 justify-between">
          <div>
            <p className="mb-3" style={{ fontWeight: 500 }}>
              First Name
            </p>
            <input
              className="px-5 py-2 bg-gray-100 rounded-md outline-none"
              type="text"
              style={{ width: "325px", border: "1px solid #C4C4C4" }}
            ></input>
          </div>
          <div style={{ width: "355px" }}>
            <p className="mb-3" style={{ fontWeight: 500 }}>
              Projects
            </p>
            <Dropdown overlay={menu}>
              <div
                className="px-5 flex justify-between items-center border-2"
                style={{
                  width: "200px",
                  height: "38px",
                  fontSize: "15px",
                  fontWeight: 400,
                }}
              >
                <p>Not Assigned</p>
                <DownOutlined />
              </div>
            </Dropdown>
          </div>
        </div>
        <div className="flex my-5 w-4/5 justify-between">
          <div>
            <p className="mb-3" style={{ fontWeight: 500 }}>
              Email Address
            </p>
            <div className="flex">
              <input
                className="px-5 py-2 bg-gray-100 rounded-md outline-none"
                type="text"
                style={{ width: "325px", border: "1px solid #C4C4C4" }}
              ></input>
              <p className="underline text-blueprimary text-center mx-3 flex items-center">
                Edit
              </p>
            </div>
          </div>
          <div className="mx-8">
            <p className="mb-3" style={{ fontWeight: 500 }}>
              Notes
            </p>
            <input
              className="px-5 py-2 bg-gray-100 rounded-md outline-none"
              type="text"
              style={{ width: "325px", border: "1px solid #C4C4C4" }}
            ></input>
          </div>
        </div>
        <div className="flex w-full justify-end mr-5">
          <button
            className="py-1 px-8 text-white rounded-md"
            style={{ background: "#11256D" }}
          >
            Add User
          </button>
        </div>
      </div>
      <div
        style={{
          width: "65%",
          boxShadow: "0px 0px 8px rgba(122, 122, 122, 0.15)",
        }}
        className="bg-white p-5"
      >
        <div className="flex my-3 w-4/5 justify-between">
          <div>
            <p className="mb-3" style={{ fontWeight: 500 }}>
              First Name
            </p>
            <input
              className="px-5 py-2 bg-gray-100 rounded-md outline-none"
              type="text"
              style={{ width: "325px", border: "1px solid #C4C4C4" }}
            ></input>
          </div>
          <div style={{ width: "355px" }}>
            <p className="mb-3" style={{ fontWeight: 500 }}>
              Projects
            </p>
            <Dropdown overlay={menu}>
              <div
                className="px-5 flex justify-between items-center border-2"
                style={{
                  width: "200px",
                  height: "38px",
                  fontSize: "15px",
                  fontWeight: 400,
                }}
              >
                <p>Not Assigned</p>
                <DownOutlined />
              </div>
            </Dropdown>
          </div>
        </div>
        <div className="flex my-5 w-4/5 justify-between">
          <div>
            <p className="mb-3" style={{ fontWeight: 500 }}>
              Email Address
            </p>
            <div className="flex">
              <input
                className="px-5 py-2 bg-gray-100 rounded-md outline-none"
                type="text"
                style={{ width: "325px", border: "1px solid #C4C4C4" }}
              ></input>
              <p className="underline text-blueprimary text-center mx-3 flex items-center">
                Edit
              </p>
            </div>
          </div>
          <div className="mx-8">
            <p className="mb-3" style={{ fontWeight: 500 }}>
              Notes
            </p>
            <input
              className="px-5 py-2 bg-gray-100 rounded-md outline-none"
              type="text"
              style={{ width: "325px", border: "1px solid #C4C4C4" }}
            ></input>
          </div>
        </div>
        <div className="flex space-x-5 w-full justify-end mr-5">
          <button
            className="py-1 px-8 text-white rounded-md"
            style={{ background: "#E63946" }}
          >
            Delete User
          </button>
          <button
            className="py-1 px-8 text-white rounded-md"
            style={{ background: "#11256D" }}
          >
            Add User
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccessManagement;
