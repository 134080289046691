import React from "react";
import { useSelector } from "react-redux";
import { GlobalState } from "../../../../../store/reducers";
import usePracticeArea from "../../../../../store/zustand/usePracticeArea";
import styles from "./reviewInfo.module.css";

export interface ReviewInfoProps {}

const ReviewInfo: React.SFC<ReviewInfoProps> = () => {
  let { currentPracticeArea } = usePracticeArea();
  let node = useSelector((state: GlobalState) => state.feedback.node_name);

  return (
    <div className={styles.root}>
      <div className={styles.main}>
        <div className={styles.practicearea}>
          <img src="/img/tab_detail_green.svg" alt="Tab" />
          <div className="uppercase">
            <p
              style={{
                color: "#C4C4C4",
                fontSize: "15px",
                lineHeight: "20px",
              }}
            >
              Iterate / {currentPracticeArea?.name}
            </p>
            <p
              className="font-medium"
              style={{
                color: "#11256D",
                fontSize: "18px",
                lineHeight: "21px",
              }}
            >
              {node}
            </p>
          </div>
        </div>
        <div className="flex flex-1 justify-end mr-4 items-center">
          <div
            style={{
              fontSize: "18px",
            }}
            className="text-gray-400"
          >
            Current Accuracy &nbsp;&nbsp;&nbsp;&nbsp;{" "}
            <text className="text-green-500 font-bold">
              {currentPracticeArea?.alpdsInfo.trainInfo.MicroF1 * 100}%
            </text>
          </div>
        </div>
      </div>
      <p className={styles.feedbacktext}>Feedback & Review</p>
    </div>
  );
};

export default ReviewInfo;
