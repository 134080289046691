import * as React from "react";

export interface StageNodeProps {
  parent_name: string;
  node_name: string;
  active: boolean;
  node_id: string;
  completed_percentage: number;
  onSelect: React.MouseEventHandler<HTMLDivElement>;
}

const StageNode: React.SFC<StageNodeProps> = (props) => {
  // let handleCalculate=()=>{

  // 	let val:any=stage1.nodes_progress.filter((node:any)=>node.node_id===props.node_id);
  // 	setCompleted(val.data);
  // }
  // React.useEffect(()=>{
  // 	handleCalculate();
  // }, [stage1.nodes_progress])
  return (
    <div
      onClick={props.onSelect}
      className="cursor-pointer mb-4 duration-200"
      style={{
        padding: "12px 15px",
        backgroundColor: props.active ? "#ffffff" : "#EAEAEA",
        borderRadius: 4,
        border: props.active ? "1px solid #11256D" : "",
      }}
      onMouseEnter={(e) => {
        if (props.active === false) {
          //@ts-ignore
          e.target.style.opacity = 0.7;
        }
      }}
      onMouseLeave={(e) => {
        //@ts-ignore
        e.target.style.opacity = 1;
      }}
    >
      <div style={{ color: "#9A9A9A", fontSize: 12 }}>{props.parent_name}</div>
      <div className="font-medium" style={{ fontSize: 15 }}>
        {props.node_name}
      </div>
      <div style={{ width: "100%", backgroundColor: "#ffffff", height: 3 }}>
        <div
          style={{
            width: `${
              (props.completed_percentage * 100) / 15 > 100
                ? 100
                : (props.completed_percentage * 100) / 15
            }%`,
            backgroundColor: "#11256D",
            height: 2,
          }}
        />
      </div>
    </div>
  );
};

export default StageNode;
