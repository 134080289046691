import { Input } from "antd";
import axios from "axios";
import * as React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BASE_URL } from "../../utils/server-urls";

const ForgotPass: React.SFC = () => {
  let history = useHistory();
  const [error, setError] = React.useState({
    emailErr: "",
    passErr: "",
    captchaErr: "",
  });
  const [captchaDone, setCaptchaDone] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState("");

  React.useEffect(() => {
    //For updating the title of the page according to sign up or sign in page
    if (history.location.pathname === "/auth/forgot-password") {
      document.title = "Reset password | Dataneuron";
    }
  }, [history.location.pathname]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const verifyCaptcha = () => {
    setError({ ...error, captchaErr: "" });
    setCaptchaDone(true);
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (email.length && error.emailErr === "") {
      if (captchaDone === true) {
        axios
          .post(BASE_URL + "/user/exists", { email })
          .then((res) => {
            if (res.data.status === 200) {
              window.location.replace(`/auth/set-new-password/${email}`);
              // history.push("/auth/set-new-password");
            } else {
              toast("Email doesn't exist", {
                type: "error",
              });
            }
          })
          .catch(() => toast("Invalid email!", { type: "error" }));
      } else {
        toast("Please complete the captcha and try again", { type: "warning" });
      }
    } else {
      toast("Check your email and try again!", { type: "warning" });
    }
  };

  return (
    <div className="w-100 h-screen flex justify-center items-center">
      <form
        style={{
          marginBottom: "120px",
        }}
        className="lg:w-3/5 md:w-4/5 xs:w-9/10"
        onSubmit={handleSubmit}
      >
        <div
          style={{
            marginBottom: "15px",
          }}
          className={"text-4xl font-medium w-100"}
        >
          Forgot your password?
        </div>
        <div
          style={{
            marginBottom: "50px",
          }}
          className="font-thin text-lg"
        >
          Remember your password?{" "}
          <Link
            style={{
              color: "#0000FF",
            }}
            to="/auth/signin"
            className="underline hover:underline"
          >
            Sign In
          </Link>
          .
        </div>
        <div
          style={{
            marginBottom: "30px",
          }}
          className="w-9/10"
        >
          <Input
            className={"rounded-md w-full"}
            style={{
              border: "1px solid #C4C4C4",
              padding: "14px",
              fontSize: "14px",
            }}
            name="email"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e)
            }
            placeholder="Please enter your email address"
          />
          {error.emailErr !== "" ? (
            <p className="text-red-500">{error.emailErr}</p>
          ) : (
            ""
          )}
        </div>
        <div
          style={{
            marginBottom: "15px",
          }}
          className="w-100 flex"
        >
          <div>
            <ReCAPTCHA
              sitekey="6Ld3LAMcAAAAAEMFc3k4QUu8D5CdeDS9eRfCDcnr"
              onChange={verifyCaptcha}
            />
            {error.captchaErr !== "" ? (
              <p className="text-red-500 text-left">{error.captchaErr}</p>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="w-100 flex">
          <button
            style={{
              backgroundColor: "#32373B",
              fontSize: "19px",
              width: "231px",
              height: "50px",
              padding: "12px 86px",
            }}
            type="submit"
            className="btn text-white rounded-full my-5 font-thin"
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default ForgotPass;
