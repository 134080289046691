import * as React from 'react';

const Loader: React.FC = () => {
    return ( 
        <div className={"w-full h-96 flex justify-center items-center"}>
            <img src="/img/loading.svg" alt="loading icon" width={30} />
        </div>
     );
}
 
export default Loader;