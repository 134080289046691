let testing = false;

let config = {
  server_url:
    process.env.NODE_ENV === "production"
      ? testing
        ? "https://engalpservertest.azurewebsites.net/api"
        : "https://engalpserverofficial.azurewebsites.net/api"
      : "http://localhost:8000/api",
  socket_server_url:
    process.env.NODE_ENV === "production"
      ? testing
        ? "https://engalpservertest.azurewebsites.net/"
        : "https://engalpserverofficial.azurewebsites.net/"
      : "http://localhost:8000/",
};

export default config;
