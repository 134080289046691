import React, { useEffect, useState } from "react";
import { PracticeArea } from "../../store/reducers/practice-area-reducer";
import { useHistory } from "react-router";
import usePracticeArea from "../../store/zustand/usePracticeArea";

export interface SearchBarDropDownProps {
  handleSearch: any;
}

const SearchBarDropDown: React.SFC<SearchBarDropDownProps> = (props) => {
  let { allPracticeAreas, setCurrentPracticeArea } = usePracticeArea();
  let [searchResult, setSearchResult] = useState<Array<PracticeArea>>([]);
  let [searchTerm, setSearchTerm] = useState<string>("");
  let history = useHistory();

  useEffect(() => {
    if (searchTerm !== "") {
      let temp: Array<PracticeArea> = [];
      allPracticeAreas.forEach((pracArea) => {
        if (
          pracArea.acronym.startsWith(searchTerm) ||
          pracArea.name.startsWith(searchTerm) ||
          pracArea.practiceAreaId.startsWith(searchTerm) ||
          pracArea.createdBy.startsWith(searchTerm)
        ) {
          temp.push(pracArea);
        }
      });

      setSearchResult(temp);
    } else {
      setSearchResult([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <div>
      <div className={"flex"}>
        <input
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={"Search for a practice area"}
          type="text"
          className={
            "w-full flex-grow p-3 bg-white rounded-l-full outline-none"
          }
        />
        <div
          className={
            "bg-gray-100 w-24 flex justify-center items-center rounded-tr-full cursor-pointer rounded-br-full hover:bg-gray-200 duration-300"
          }
        >
          <img src="/img/search.svg" alt="search icon" />
        </div>
      </div>
      <div className="absolute w-10/12 max-h-96 overflow-y-scroll shadow-md bg-white transform mt-3 z-50">
        {searchResult.map((pracArea) => {
          return (
            <div
              onClick={() => {
                setCurrentPracticeArea(pracArea);
                history.push(`/app/pa/ws/flow`);
              }}
              key={pracArea.practiceAreaId}
              className={
                "w-full px-3 py-2 flex justify-between hover:bg-gray-100 cursor-pointer"
              }
            >
              <div>
                <div className={"text-lg"}>{pracArea.name}</div>
                <div className="text-gray-300">{pracArea.acronym}</div>
              </div>

              <div className="flex items-center">
                {pracArea.stages.slice(0, 5).map((stage, i: number) => {
                  return (
                    <>
                      {i !== 0 && (
                        <div
                          style={{
                            width: 15,
                            height: 3,
                            backgroundColor: stage.active
                              ? "#11256D"
                              : "lightgray",
                          }}
                        ></div>
                      )}
                      <div
                        style={{
                          width: 10,
                          height: 10,
                          backgroundColor: stage.active
                            ? "#11256D"
                            : "lightgray",
                          borderRadius: "50%",
                        }}
                      ></div>
                    </>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SearchBarDropDown;
