import { notification } from "antd";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../../store/reducers";
import { apiNetworkCall } from "../../../utils/api";
import { SEED_PARA } from "../../../utils/server-urls";
import styles from "./feedback.module.css";
import { toast } from "react-toastify";
import { FEEDBACK_REJECT_PARA_INIT } from "../../../store/types/feedback.types";
import usePracticeArea from "../../../store/zustand/usePracticeArea";

export interface SeedParaProps {}

const SeedPara: React.SFC<SeedParaProps> = (props) => {
  let dispatch = useDispatch();
  React.useEffect(() => {
    dispatch({
      type: FEEDBACK_REJECT_PARA_INIT,
    });
  }, []);
  const [newPara, setNewPara] = React.useState({
    newParaTitle: "",
    newParaData: "",
  });
  let feedback = useSelector((state: GlobalState) => state.feedback);
  let [active_node_Id, setActiveNode] = React.useState(feedback.node_id);
  let { currentPracticeArea } = usePracticeArea();

  const handleChange = (e: any) => {
    setNewPara({ ...newPara, [e.target.name]: e.target.value });
  };
  const handleAddNewPara = () => {
    const selectedIssueId = active_node_Id; // from store
    if (!selectedIssueId) return console.error("Selected Issue id null");

    if (newPara.newParaTitle.length !== 0 && newPara.newParaData.length !== 0) {
      const newParaParams = {
        practiceAreaId: currentPracticeArea?.practiceAreaId,
        paraTitle: newPara.newParaTitle,
        paraData: newPara.newParaData,
        issueId: selectedIssueId,
      };

      try {
        let res = apiNetworkCall(SEED_PARA, "post", newParaParams);
        toast("New paragraph data was successfully added", { type: "success" });
      } catch (err) {
        toast("Seeding new paragraph data failed", { type: "error" });
      }
    }
    setNewPara({
      newParaTitle: "",
      newParaData: "",
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <div className={styles.right_section_container} style={{ width: "100%" }}>
        <div className={styles.right_section_container_heading}>
          <input
            className={styles.edit_title}
            placeholder=" Edit Title"
            onChange={handleChange}
            value={newPara.newParaTitle}
            name="newParaTitle"
          />
          <button
            className={styles.submit_btn}
            style={
              newPara.newParaTitle.length > 0 && newPara.newParaData.length > 0
                ? { backgroundColor: "#11256d", color: "#fff" }
                : {}
            }
            onClick={(e) => handleAddNewPara()}
            disabled={
              !(
                newPara.newParaTitle.length > 0 &&
                newPara.newParaData.length > 0
              )
            }
          >
            SUBMIT
          </button>
        </div>
        <textarea
          placeholder="Type your paragraph here ..."
          className={styles.para_text_area}
          onChange={handleChange}
          value={newPara.newParaData}
          name="newParaData"
        />
      </div>
      {/* <div className="flex justify-end w-full">
            <button
				className={`flex text-white justify-center py-2 ml-3 mt-3 rounded-md`}
				style={{backgroundColor: "#11256D", fontSize: 15,width:'240px' }}
				onClick={() => handleAddNewPara()}
			>
				<img src="/img/seed_icon.svg" alt="seed para icon" className={"mr-3"} />
				SEED NEW PARAGRAPH
			</button>
            </div> */}
    </div>
  );
};

export default SeedPara;
