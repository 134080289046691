import React from "react";
import { useHistory } from "react-router";
import usePracticeArea from "../../store/zustand/usePracticeArea";

interface UploadCardProps {
  practiceArea: string | undefined;
}
export let token =
  JSON.parse(localStorage.getItem("userInfo") as string) !== null
    ? JSON.parse(localStorage.getItem("userInfo") as string).token
    : "";

const UploadCard: React.FC<UploadCardProps> = () => {
  let history = useHistory();
  let { currentPracticeArea } = usePracticeArea();

  return (
    <div
      style={{
        width: "300px",
        height: "180px",
      }}
      className="p-3 bg-white"
    >
      <div className="flex space-x-2">
        <img src="/img/uploadBlue.svg" alt="upload" />
        <p style={{ fontSize: "15px", fontWeight: 500 }}>Upload</p>
      </div>
      <div className="flex w-full h-full justify-center items-center">
        <div className="flex w-full h-full justify-center items-center">
          <div
            className={`flex transform -translate-y-3 justify-center items-center text-center cursor-${
              !currentPracticeArea?.masterlistLocked ? "pointer" : "not-allowed"
            } ${
              !currentPracticeArea?.masterlistLocked
                ? "hover:bg-primarycolor hover:text-white text-primarycolor"
                : "bg-gray-300 text-white"
            }`}
            onClick={() => {
              if (currentPracticeArea?.masterlistLocked === false) {
                history.push("/app/pa/ws/upload");
              }
            }}
            style={{
              border: `1px solid ${
                !currentPracticeArea?.masterlistLocked ? "#2C2C2C" : "white"
              }`,
              fontSize: "16px",
              width: "250px",
              height: "30px",
            }}
          >
            Upload
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadCard;
