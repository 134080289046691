import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { StageContext } from "../../pages/app/practice-area/pa";
import usePracticeArea from "../../store/zustand/usePracticeArea";
import { setActive } from "./stage-helper";

interface SourseProp {
  sourceNumber: number;
  active: boolean;
  description: string;
  onClick: Function;
  showTitle: boolean;
}

let Source: React.SFC<SourseProp> = ({
  sourceNumber,
  active,
  description,
  onClick,
  showTitle,
}) => {
  let [isClickable, setIsClickable] = useState<boolean>(false);

  useEffect(() => {
    if (sourceNumber !== 1 && sourceNumber !== 2 && sourceNumber !== 3) {
      setIsClickable(true);
    } else {
      setIsClickable(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return sourceNumber === 2 || sourceNumber === 3 ? (
    <div
      onClick={() => {
        if (isClickable) {
          onClick();
        }
      }}
      className={"flex items-center cursor-pointer"}
      style={{
        width: showTitle ? "100%" : "fit-content",
        paddingRight: active ? "0px" : "10px",
      }}
    >
      {active === true && sourceNumber !== 2 && (
        <svg width={20} height={10}>
          <path d={"M 0 5 L 30 5"} stroke={"#11256D"} strokeWidth={2} />
        </svg>
      )}
      <div
        style={{
          backgroundColor: active ? "#11256D" : "#ffffff",
          color: active ? "white" : "black",
          borderRadius: "50%",
          width: 45,
          height: 45,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 15,
          cursor: isClickable ? "pointer" : "no-drop",
        }}
      >
        {sourceNumber - 1}
      </div>
      {showTitle && (
        <div style={{ fontSize: 12 }} className={"ml-3 font-semibold"}>
          {description.toUpperCase()}
        </div>
      )}
    </div>
  ) : null;
};

const WorkspaceNav: React.FC = () => {
  let history = useHistory();

  let params = useLocation();

  let { stages, setStages } = useContext(StageContext);

  let { currentPracticeArea } = usePracticeArea();

  return (
    <>
      <div className="w-full mt-5 mb-2 pb-5 flex justify-between">
        <div className={"w-1/2 flex ml-8"}>
          <img
            src={(() => {
              if (params.pathname.slice(11, 15) === "flow") {
                return "/img/pa-dashboard.svg";
              } else if (params.pathname.slice(11, 25) === "masterlist") {
                return "/img/masterlistWhite.svg";
              } else if (params.pathname.slice(11, 25) === "upload") {
                return "/img/upload_white.svg";
              } else if (
                params.pathname.slice(11, 30) === "model-training2" ||
                params.pathname.slice(11, 30) === "model-training1"
              ) {
                return "/img/trainCardIconWhite.svg";
              } else if (params.pathname.slice(27, 30) === "dep") {
                return "/img/deployWhite.svg";
              } else if (
                params.pathname.slice(11, 18) === "stage1" ||
                params.pathname.slice(11, 18) === "stage2"
              ) {
                return "/img/circle_tick_white.svg";
              } else {
                return "/img/masterlistwhite.svg";
              }
            })()}
            alt={"tab details"}
            className="bg-primarycolor rounded-md w-10 h-12 p-2"
          ></img>
          <div className="px-2">
            <p
              style={{
                color: "#C4C4C4",
                fontSize: "15px",
                lineHeight: "18px",
              }}
            >
              {/* params.pathname.slice(11, 15) === "flow" ? "WORKFLOW" : "MASTERLIST" */}
              {(() => {
                if (params.pathname.slice(11, 15) === "flow") {
                  return "WORKFLOW";
                } else if (params.pathname.slice(11, 25) === "masterlist") {
                  return "MASTERLIST";
                } else if (params.pathname.slice(11, 25) === "upload") {
                  return "UPLOAD";
                } else if (
                  params.pathname.slice(11, 30) === "model-training2" ||
                  params.pathname.slice(11, 30) === "model-training1"
                ) {
                  return "MODEL TRAINING";
                } else if (params.pathname.slice(27, 30) === "dep") {
                  return "MODEL DEPLOYMENT";
                } else if (
                  params.pathname.slice(11, 18) === "stage1" ||
                  params.pathname.slice(11, 18) === "stage2"
                ) {
                  return "VALIDATION";
                } else {
                  return "MASTERLIST";
                }
              })()}
              /
            </p>
            <p
              className="font-medium"
              style={{
                color: "#11256D",
                fontSize: "18px",
                lineHeight: "21px",
              }}
            >
              {currentPracticeArea?.name}
            </p>
          </div>
        </div>
        <div className={"w-1/2 flex flex-end"}>
          {params.pathname.slice(16, 17) === "1" ||
          params.pathname.slice(16, 17) === "2"
            ? stages.map((stage) => {
                return (
                  <Source
                    showTitle={stage.showTitle}
                    key={stage.stageNo}
                    onClick={() => {
                      setStages(setActive(stages, stage.stageNo));
                      history.push(stage.redirLink);
                    }}
                    active={stage.active}
                    description={stage.des}
                    sourceNumber={stage.stageNo}
                  />
                );
              })
            : null}
          {params.pathname.slice(27, 30) === "dep" ? (
            <div className="flex flex-1 justify-end mr-4 items-center">
              <div
                style={{
                  fontSize: "18px",
                }}
                className="text-gray-400"
              >
                Current Accuracy &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                <text className="text-green-500 font-bold">
                  {currentPracticeArea?.alpdsInfo.trainInfo.MicroF1 * 100}%
                </text>
              </div>
            </div>
          ) : null}
          {params.pathname.slice(11, 30) === "model-training2" ? (
            <section className="flex-1">
              <div className="w-full h-full flex items-center gap-3 justify-end">
                <img
                  src="/img/training_complete_green.svg"
                  alt="Training Icon"
                />
                <div className="text-gray-400 mr-10">Training complete</div>
                <div
                  style={{
                    color: "#11256D",
                  }}
                  className="mr-3 font-bold"
                >
                  100%
                </div>
              </div>
            </section>
          ) : null}
          <div />
        </div>
      </div>
      <div className="flex justify-center">
        <div
          style={{
            height: 0.5,
            backgroundColor: "rgba(0, 0, 0, 0.07)",
            width: "98%",
          }}
        ></div>
      </div>
    </>
  );
};
export default WorkspaceNav;
