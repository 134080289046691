import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { toast, ToastContentProps } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import usePracticeArea from "../../store/zustand/usePracticeArea";
import { apiNetworkCall } from "../../utils/api";
import {
  MARK_MASTERLIST_LOCKED,
  STAGE_1_INTEGRATE,
  STAGE_INFO,
} from "../../utils/server-urls";
import { nodesAtom } from "../masterlist/atoms";
import { NotificationAtom } from "../topbar/notificationAtom";

interface StageCardProps {
  practiceAreaId: string | undefined;
  activeStage: number;
}

const StageCard: React.FC<StageCardProps> = ({ activeStage }) => {
  let history = useHistory();
  let nodes = useRecoilValue(nodesAtom);
  let [totalValidated, setTotalValidated] = useState(0);
  let { currentPracticeArea } = usePracticeArea();
  const [active, setActive] = useState(-1);
  let [notifications, setNotifications] = useRecoilState(NotificationAtom);

  useEffect(() => {
    let temp = -1;
    for (let i = 0; i < 5; i++) {
      if (
        currentPracticeArea?.stages[i] &&
        currentPracticeArea?.stages[i].active === true
      ) {
        temp = i;
        setActive(temp);
      }
    }
    if (
      temp === 3 &&
      currentPracticeArea?.alpdsInfo.currentPracticeArea?.alpdsInfo
        ?.percentageStatus?.stage2Progress?.batch1Progress !== 100
    ) {
      temp = 2;
      setActive(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let [targetClassReached, setTragetClassReached] = useState(0);

  let loadStage = () => {
    apiNetworkCall(STAGE_INFO, "POST", {
      practiceAreaId: currentPracticeArea?.practiceAreaId,
      stage:
        currentPracticeArea?.alpdsInfo?.percentageStatus?.stage2Progress
          ?.batch1Progress === 100
          ? 2
          : 1,
    }).then((res: any) => {
      let data = res.data;

      setTotalValidated(data.filter((d: any) => d.accepted !== null).length);

      let r = 0;

      nodes.forEach((node) => {
        if (
          data.filter(
            (d: any) =>
              d.accepted !== null && d.predictedIssueId === node.node_id
          ).length > 15
        ) {
          r += 1;
        }
      });

      setTragetClassReached(r);
    });
  };

  useEffect(() => {
    loadStage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPracticeArea?.stages]);

  let allowToStartStage1 = () => {
    let allowed = true;
    let messages = [];

    let modelNodes = nodes.filter(
      (nodeInfo) => nodeInfo.is_model_class === true
    );
    let leafNodes = nodes.filter((nodeInfo) => nodeInfo.is_leaf_node === true);

    if (modelNodes.length > 100 || modelNodes.length < 2) {
      allowed = false;
      messages.push(
        "The number of model nodes should be more 1 and should be less than 101"
      );
    }

    if (leafNodes.length > 100) {
      allowed = false;
      messages.push("The number of leaf nodes should not be greater than 100");
    }

    //TODO : The number of paras should ne n ^ 2 times the number of modal nodes (n)

    return { allowed, messages };
  };

  let lockMasterlist = async () => {
    try {
      let lockMasterlist: any = await apiNetworkCall(
        MARK_MASTERLIST_LOCKED,
        "post",
        {
          practiceAreaId: currentPracticeArea?.practiceAreaId,
          markAs: true,
        }
      );
      if (lockMasterlist.result) {
        let check = allowToStartStage1();

        if (check.allowed === true) {
          apiNetworkCall(STAGE_1_INTEGRATE, "post", {
            practiceAreaId: currentPracticeArea?.practiceAreaId,
          })
            .then((res) => {
              toast("Stage 1 Auto Annotation started successfully", {
                type: "success",
              });
              setNotifications([
                ...notifications,
                "Stage 1 Auto Annotation started successfully",
              ]);
            })
            .catch((err) => {
              toast("Internal Server Error : Stage 1 Auto Annotation", {
                type: "error",
              });
              setNotifications([
                ...notifications,
                "Internal Server Error : Stage 1 Auto Annotation",
              ]);
            });

          history.push("/app/pa/ws/stage1");
        } else {
          check.messages.forEach(
            (
              msg:
                | boolean
                | React.ReactChild
                | React.ReactFragment
                | React.ReactPortal
                | ((props: ToastContentProps<{}>) => React.ReactNode)
                | null
                | undefined
            ) => {
              toast(msg, { type: "success" });
            }
          );
        }
      } else {
        console.error(lockMasterlist);
      }
    } catch (err) {
      console.error(err);
    }
  };
  let handleClick = () => {
    if (currentPracticeArea && currentPracticeArea.masterlistLocked === false) {
      lockMasterlist();
    } else {
      toast(
        "Masterlist is locked. Please create a new practice area to define new masterlist",
        {
          type: "info",
        }
      );
    }
  };

  return (
    <div
      className="flex items-center w-full cursor-pointer"
      style={{ height: "180px", width: "300px" }}
      onClick={() => {
        if (currentPracticeArea?.stages[4].active === false) {
          if (
            currentPracticeArea?.stages[3].active === true &&
            currentPracticeArea?.alpdsInfo?.percentageStatus?.stage2Progress
              ?.batch1Progress === 100
          ) {
            history.push("/app/pa/ws/stage2");
          } else if (currentPracticeArea?.stages[2].active === true) {
            history.push("/app/pa/ws/stage1");
          }
        } else {
          toast("To validate more go to Iterate", { type: "info" });
        }
      }}
    >
      <div
        className="p-3 bg-white w-full"
        style={{ height: "180px", width: "300px" }}
      >
        <div className="flex space-x-2">
          <img
            width={17}
            height={22}
            src="/img/circle_tick.svg"
            alt="validate"
          ></img>
          <p style={{ fontWeight: 500, fontSize: "15px" }}>Validate</p>
          <p style={{ color: "#7A7A7A" }}>
            {active <= 3 ? "Stage 1" : "Stage 2"}
          </p>
        </div>
        {active < 2 ? (
          <div className="w-full h-full flex justify-center items-center">
            <div
              className={`flex justify-center items-center text-center cursor-${
                nodes.length > 0 ? "pointer" : "not-allowed"
              } ${
                nodes.length > 0
                  ? "hover:bg-primarycolor hover:text-white text-primarycolor"
                  : "bg-gray-300 text-white"
              }`}
              onClick={() =>
                nodes.length > 0
                  ? handleClick()
                  : toast("Create a valid masterlist first!", {
                      type: "warning",
                    })
              }
              style={{
                border: `1px solid ${nodes.length > 0 ? "#2C2C2C" : "white"}`,
                fontSize: "16px",
                width: "250px",
                height: "30px",
              }}
            >
              Start Validating
            </div>
          </div>
        ) : (
          <div className="my-5" style={{ color: "#7A7A7A" }}>
            <div className="flex justify-between">
              <p>Classes</p>
              <p>{nodes.length}</p>
            </div>
            <Divider className="my-2" />
            <div className="flex justify-between">
              <p>Paragraphs Validated</p>
              <p>{totalValidated}</p>
            </div>
            <Divider className="my-2" />
            {activeStage === 1 && (
              <>
                <div className="flex justify-between">
                  <p>Target Classes Reached </p>
                  <p>{targetClassReached}</p>
                </div>
                <Divider className="my-2" />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default StageCard;
