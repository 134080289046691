import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import usePracticeArea from "../../store/zustand/usePracticeArea";
import { nodesAtom } from "../masterlist/atoms";

export interface NavbarProps {}

const Navbar: React.SFC<NavbarProps> = () => {
  const history = useHistory();
  let isDashboard = history.location.pathname === "/app/dashboard";
  let { currentPracticeArea } = usePracticeArea();
  let nodes = useRecoilValue(nodesAtom);
  let params = useLocation();
  // let handleNameClick = (data: any) => {
  //   dispatch({
  //     type: CURRENT_PRACTICE_AREA,
  //     payload: data,
  //   });
  //   history.push(`/app/pa`);
  // };

  return (
    <div className={"fixed flex flex-col left-0 top-0 bottom-0 w-20 "}>
      <div className={"h-36  w-full flex flex-col justify-end items-center"}>
        <img
          src="/img/dashboard.svg"
          className="cursor-pointer py-2 border-b-2"
          alt="dashboard"
          onClick={() => history.push("/app/dashboard")}
        ></img>
      </div>
      <div
        className={"w-full flex-grow flex justify-center flex-col items-center"}
        style={{
          backgroundImage: `url(${"/img/sidebarPolygon.svg"})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "150% 100%",
        }}
      >
        {!isDashboard && (
          <div
            className={
              "flex flex-col space-y-4 border-r border-gray-200 p-2 items-center"
            }
          >
            <div
              onClick={() => history.push("/app/pa/ws/flow")}
              className="rounded-full flex p-3 cursor-pointer"
              style={{
                background:
                  params.pathname.slice(11, 18) === "flow"
                    ? "#11256D"
                    : "#EAEAEA",
              }}
            >
              <img
                className="w-6 h-6"
                src={
                  params.pathname.slice(11, 18) === "flow"
                    ? "/img/pa-dashboard.svg"
                    : "/img/pa-dash-gray.svg"
                }
                alt="workflow"
              ></img>
            </div>
            <div
              onClick={() =>
                !currentPracticeArea?.masterlistLocked
                  ? history.push("/app/pa/ws/upload")
                  : toast(
                      "Masterlist is locked, Try creating a new practice area!",
                      { type: "warning" }
                    )
              }
              className={`rounded-full px-3 py-3 cursor-${
                currentPracticeArea?.masterlistLocked
                  ? "not-allowed"
                  : "pointer"
              }`}
              style={{
                background:
                  params.pathname.slice(11, 22) === "upload"
                    ? "#11256D"
                    : "#EAEAEA",
              }}
            >
              <img
                width={20}
                height={20}
                src={
                  params.pathname.slice(11, 22) === "upload"
                    ? "/img/upload_white.svg"
                    : "/img/uploadBlue.svg"
                }
                alt="workflow"
              ></img>
            </div>
            <div
              onClick={() =>
                currentPracticeArea?.stages[0].active
                  ? history.push("/app/pa/ws/masterlist")
                  : toast("Try after uploading a file", { type: "warning" })
              }
              className={`rounded-full px-3 py-3 cursor-${
                currentPracticeArea?.stages[0].active
                  ? "pointer"
                  : "not-allowed"
              }`}
              style={{
                background:
                  params.pathname.slice(11, 22) === "masterlist"
                    ? "#11256D"
                    : "#EAEAEA",
              }}
            >
              <img
                width={20}
                height={20}
                src={
                  params.pathname.slice(11, 22) === "masterlist"
                    ? "/img/masterlistWhite.svg"
                    : "/img/masterListBlue.svg"
                }
                alt="masterlistlogo"
              ></img>
            </div>
            <div
              onClick={() => {
                if (currentPracticeArea?.stages[4].active === false) {
                  if (
                    currentPracticeArea?.stages[3].active === true &&
                    currentPracticeArea?.alpdsInfo?.percentageStatus
                      ?.stage2Progress?.batch1Progress === 100
                  ) {
                    history.push("/app/pa/ws/stage2");
                  } else if (currentPracticeArea?.stages[2].active === true) {
                    history.push("/app/pa/ws/stage1");
                  }
                } else {
                  toast("To validate more go to Iterate", { type: "info" });
                }
              }}
              className={`rounded-full px-3 py-3 cursor-${
                nodes.length && !currentPracticeArea?.stages[4].active
                  ? "pointer"
                  : "not-allowed"
              }`}
              style={{
                background:
                  params.pathname.slice(11, 18) === "stage1" ||
                  params.pathname.slice(11, 18) === "stage2"
                    ? "#11256D"
                    : "#EAEAEA",
              }}
            >
              <img
                width={20}
                height={20}
                src={
                  params.pathname.slice(11, 18) === "stage1" ||
                  params.pathname.slice(11, 18) === "stage2"
                    ? "/img/circle_tick_white.svg"
                    : "/img/circle_tick.svg"
                }
                alt="masterlist"
              ></img>
            </div>
            <div
              onClick={() => {
                currentPracticeArea?.stages[4].active
                  ? history.push("/app/pa/ws/model-training1")
                  : toast("You haven't completed the validation yet!", {
                      type: "warning",
                    });
              }}
              className={`rounded-full px-3 py-3 cursor-${
                currentPracticeArea?.stages[4].active
                  ? "pointer"
                  : "not-allowed"
              }`}
              style={{
                background:
                  params.pathname.slice(11, 30) === "model-training1" ||
                  params.pathname.slice(11, 30) === "model-training2"
                    ? "#11256D"
                    : "#EAEAEA",
              }}
            >
              <img
                src={
                  params.pathname.slice(11, 30) === "model-training1" ||
                  params.pathname.slice(11, 30) === "model-training2"
                    ? "/img/trainCardIconWhite.svg"
                    : "/img/trainCardIcon.svg"
                }
                className="w-5 h-5"
                alt="train"
              ></img>
            </div>
            <div
              onClick={() => {
                currentPracticeArea?.stages[5].active
                  ? history.push("/app/pa/ws/masterlist")
                  : toast("You haven't completed the training yet!", {
                      type: "warning",
                    });
              }}
              className={`rounded-full px-3 py-3 cursor-${
                currentPracticeArea?.stages[5].active
                  ? "pointer"
                  : "not-allowed"
              }`}
              style={{
                background:
                  params.pathname.slice(8, 17) === "update"
                    ? "#11256D"
                    : "#EAEAEA",
              }}
            >
              <img
                src={
                  params.pathname.slice(8, 17) === "update"
                    ? "/img/iterationWhite.svg"
                    : "/img/iterationBlue.svg"
                }
                alt="iteration"
                className="w-5 h-5"
              ></img>
            </div>
            <div
              onClick={() => {
                currentPracticeArea?.stages[5].active
                  ? history.push("/app/pa/ws/model-training2/dep")
                  : toast("You haven't completed the training yet!", {
                      type: "warning",
                    });
              }}
              className={`rounded-full px-3 py-3 cursor-${
                currentPracticeArea?.stages[4].active
                  ? "pointer"
                  : "not-allowed"
              }`}
              style={{
                background:
                  params.pathname.slice(27, 30) === "dep"
                    ? "#11256D"
                    : "#EAEAEA",
              }}
            >
              <img
                width={20}
                height={20}
                src={
                  params.pathname.slice(27, 30) === "dep"
                    ? "/img/deployWhite.svg"
                    : "/img/deployBlue.svg"
                }
                alt="workflow"
              ></img>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
