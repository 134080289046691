import ReactApexChart from "react-apexcharts";
import { useState } from "react";
import { useEffect } from "react";

export interface ApexChartProps {
  stage1: number;
  stage2: number;
  stage3: number;
  height: number;
}
const ApexChart: React.SFC<ApexChartProps> = (props) => {
  const { stage1, stage2, stage3, height } = props;

  const [options, setOptions] = useState<any>({
    chart: {
      height: 390,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 360,
        hollow: {
          margin: 5,
          size: "30%",
          background: "transparent",
          image: undefined,
          color: "#FFF",
        },
        track: {
          background: "#FFF",
          margin: 5,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
      },
    },
    colors: ["#1BA94C", "#1BA94CB3", "#1BA94C66"],
    labels: ["Stage1", "Stage1", "Stage3"],
    legend: {
      show: false,
      floating: false,
      fontSize: "16px",
      position: "left",
      offsetX: 160,
      offsetY: 15,
      labels: {
        useSeriesColors: true,
      },
      markers: {
        size: 0,
      },
      formatter: function (seriesName: any, opts: any) {
        return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
      },
      itemMargin: {
        vertical: 3,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            show: false,
          },
        },
      },
    ],
  });

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={[stage1, stage2, stage3]}
        type="radialBar"
        height={height ? height : 400}
      />
    </div>
  );
};
export default ApexChart;
